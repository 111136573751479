import { createAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import {
  startFetching,
  setUserProfile,
  setError,
} from "../../reducers/ProfileReducer";
import { setUserSubscription } from "../../reducers/SubscriptionReducer";
import {
  validateSubscriptionPeriod,
  validateTrialPeriod,
} from "../../../utils/validation";
import {
  setIsSubscriptionExpired,
  setIsTrialExpired,
} from "../../reducers/AuthReducer";

export const fetchProfile = createAction("profile/fetchProfile");

function* fetchData() {
  yield put(startFetching(true));
  try {
    let res = yield call(ApiService.getProfile);
    console.log("fetchProfile res.data: ", res.data);
    if (res.data.results) {
      const user = res.data.results[0];
      let isSubscriptionValid = true;
      if (!user.is_supervisor && user.is_paid) {
        try {
          let res = yield call(ApiService.getUserSubscription);
          console.log("userSubscription res.data: ", res.data);
          if (res.data.results && res.data.results.length) {
            const subscription = res.data.results[0];
            yield put(setUserSubscription(subscription));
            isSubscriptionValid = validateSubscriptionPeriod(subscription);
          }
        } catch (error) {
          console.log({ error });
        }
      }

      const isTrialValid =
        user.is_paid === false
          ? validateTrialPeriod(new Date(user.date_joined))
          : true;
      yield put(setIsSubscriptionExpired(!isSubscriptionValid));
      yield put(setIsTrialExpired(!isTrialValid));
      yield put(setUserProfile(user));
    } else if (res.data.detail) {
      yield put(setError(res.data.detail));
      toast.error(res.data.detail);
      localStorage.clear();
      window.location.reload();
    }
  } catch (error) {
    console.log({ error });
  }
}

export function* fetchProfileSaga() {
  yield takeLatest(fetchProfile, fetchData);
}
