import { createAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { startFetching, setUserBadges, setError } from "../../reducers/ProfileReducer";

export const fetchBadges = createAction("profile/fetchBadges");

function* fetchData() {
  try {
    let res = yield call(ApiService.getBadges);
    console.log('fetchBadges res.data: ', res.data);
    if(res.data)
      yield put(setUserBadges(res.data));
  } catch (error) {
    console.log({ error });
  }
}

export function* fetchBadgesSaga() {
  yield takeLatest(fetchBadges, fetchData);
}
