import { createAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { setUserPayment, startFetching, setError } from "../../reducers/SubscriptionReducer";

export const fetchUserPaymentDetails = createAction("subscription/fetchUserPaymentDetails");

function* fetchUserPayment() {
  yield put(startFetching(true))
  try {
    let res = yield call(ApiService.getUserPaymentDetails);
    console.log(res);
    console.log(res.data);
    if(res.data)
      yield put(setUserPayment(res.data));
  } catch (error) {
    console.log({ error });
  }
}

export function* fetchUserPaymentDetailsSaga() {
  yield takeLatest(fetchUserPaymentDetails, fetchUserPayment);
}
