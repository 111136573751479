import React from "react";
import LoadingSpinner from "./LoadingSpinner";

export default function LoadingButton({
  children,
  className,
  loading,
  disabled,
}) {
  if (loading) return <LoadingSpinner style={{ height: "50px" }} />;

  return (
    <button className={className} type="submit" disabled={disabled}>
      {children}
    </button>
  );
}
