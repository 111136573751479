// Reducer that changes the state based on the action

import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

export const slice = createSlice({
  name: "recordFile",
  initialState: {
    isLoading: false,
    recordFiles: [],
    success: false,
    deleteSuccess: false,
    error: null,
    recordFile: {},
  },
  reducers: {
    startFetching: (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
      state.updateSuccess = false;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
      state.isLoading = false;
    },
    setDeleteSuccess: (state, action) => {
      state.deleteSuccess = action.payload;
      state.isLoading = false;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    setRecordFiles: (state, action) => {
      state.recordFiles = action.payload;
      state.success = true;
      state.isLoading = false;
    },
    setRecordFile: (state, action) => {
      state.recordFile = action.payload;
      state.success = true;
      state.isLoading = false;
    },
  },
});

export const {
  startFetching,
  setSuccess,
  setDeleteSuccess,
  setError,
  setRecordFiles,
  setRecordFile,
} = slice.actions;

export const useRecordFiles = () => useSelector((state) => state.RecordFile.recordFiles);
export const useRecordFile = () => useSelector((state)=>state.RecordFile.recordFile);
export const useIsLoading = () => useSelector((state) => state.RecordFile.isLoading);
export const useSuccess = () => useSelector((state) => state.RecordFile.success);
export const useDeleteSuccess = () => useSelector((state) => state.RecordFile.deleteSuccess);
export const useError = () => useSelector((state) => state.RecordFile.error);

export default slice.reducer;
