import { createAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { startFetching, setError, setSkills } from "../../reducers/MonthlyDocReducer";

export const fetchSkills = createAction("monthlyDoc/fetchSkills");

function* fetchData() {
  yield put(startFetching(true));
  try {
    let res = yield call(ApiService.getTasks);
    if(res.data)
        yield put(setSkills(res.data.results));
  } catch (error) {
    console.log({ error });
    yield put(setError(error))
  }
}

export function* fetchSkillsSaga() {
  yield takeLatest(fetchSkills, fetchData);
}

