import { createAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { startFetching, setRecordFiles, setError, setSuccess } from "../../reducers/RecordFileReducer";
import { toast } from "react-toastify";

export const deleteFile = createAction("recordFile/deleteFile");

function* deleteRecordFile({ payload }) {
  const { id, refreshFiles } = payload
  try {
    let res = yield call(ApiService.deleteRecordFile, id);
    if (res.status >= 200 && res.status < 300) {
      yield put(setSuccess(true))
      toast.success('Successfully deleted!');
      refreshFiles()
    }
  } catch (error) {
    console.log({ error });
    yield put(setError(error))
  }
}

export function* deleteRecordFileSaga() {
  yield takeLatest(deleteFile, deleteRecordFile);
}
