import { all } from 'redux-saga/effects';
import { loginSaga, logoutSaga, resendemailSaga, signupSaga, verifyEmailSaga } from './auth/AuthSagas';
import { changePassSaga } from './auth/changePassSaga';
import { forgetPassSaga, resetPassSaga } from './auth/forgetPassSaga';
import { fetchChartSaga } from './dasboard/fetchChartSaga';
import { fetchTableSaga } from './dasboard/fetchTableSaga';
import { editFormSaga, deleteFormSaga } from './experienceLog/editFormSaga';
import { fetchAllSaga } from './experienceLog/fetchAllSaga';
import { fetchExpLogByIdSaga } from "./experienceLog/fetchExpLogByIdSaga";
import { fetchCsvSaga } from './experienceLog/fetchCsvSaga';
import { fetchFlaggedByDateSaga } from './experienceLog/fetchFlaggedByDateSaga';
import { fetchFlaggedSaga, fetchPastFlaggedSaga } from './experienceLog/fetchFlaggedSaga';
import { fetchMonthlyCheckSaga } from './experienceLog/fetchMonthlyCheckSaga';
import { fetchPendingByDateSaga } from './experienceLog/fetchPendingByDateSaga';
import { fetchPendingDatesSaga } from './experienceLog/fetchPendingDatesSaga';
import { formDataSaga } from './experienceLog/formDataSaga';
import { submitFlagSaga } from './experienceLog/submitFlagSaga';
import { submitFormSaga } from './experienceLog/submitFormSaga';
import { fetchAccruedByDateSaga, fetchAccruedSaga } from './monthlyDocument/fetchAccruedSaga';
import { fetchDocSaga } from './monthlyDocument/fetchDocSaga';
import { fetchSkillsSaga } from './monthlyDocument/fetchSkillsSaga';
import { fetchTasksSaga } from './monthlyDocument/fetchTasksSaga';
import { submitDocSaga } from './monthlyDocument/submitDocSaga';
import { fetchBadgesSaga } from './profile/fetchBadgesSaga';
import { fetchProfileSaga } from './profile/fetchSaga';
import { updateProfileSaga, updateSupervisorSaga } from './profile/updateSaga';
import { fetchSubscriptionSaga, fetchUserSubscriptionSaga } from './subscription/fetchSaga';
import { addSubscritionSaga, updateSubscritionSaga, deleteSubscriptionSaga, updatePaymentSuccessSaga, updateCouponDetailsSaga } from './subscription/paymentSaga';
import { deleteSaga } from './trainees/deleteSaga';
import { fetchAllTraineesSaga } from './trainees/fetchAllSaga';
import { submitRecordFileSaga } from './recordFile/submitRecordFileSaga'
import { fetchRecordFilesSaga } from './recordFile/fetchRecordFilesSaga'
import { deleteRecordFileSaga } from "./recordFile/deleteRecordFileSaga";
import { deleteTraineeSupervisorSaga, fetchSupervisorsSaga } from "./profile/supervisorSaga";
import { submitSupervisorSaga } from "./profile/supervisorSaga";
import { updateTraineeSupervisorSaga } from "./profile/supervisorSaga";
import { sendInvitationSaga } from "./profile/sendInvitationSaga";
import { fetchRecordIdFileSaga } from './recordFile/fetchIdRecordFileSaga';
import { updatePaymentMethodSaga } from "./subscription/updatePaymentMethodSaga";
import { fetchUserPaymentDetailsSaga } from "./subscription/fetchUserPaymentDetailsSaga";
import { uploadImageSaga } from './profile/uploadSaga';

export default function* rootSaga() {
    yield all([
        signupSaga(),
        verifyEmailSaga(),
        loginSaga(),
        logoutSaga(),
        forgetPassSaga(),
        resetPassSaga(),
        changePassSaga(),
        resendemailSaga(),
        formDataSaga(),
        fetchAllSaga(),
        fetchExpLogByIdSaga(),
        fetchChartSaga(),
        fetchFlaggedSaga(),
        fetchPastFlaggedSaga(),
        fetchCsvSaga(),
        submitFormSaga(),
        editFormSaga(),
        deleteFormSaga(),
        fetchSubscriptionSaga(),
        fetchUserSubscriptionSaga(),
        addSubscritionSaga(),
        updateSubscritionSaga(),
        deleteSubscriptionSaga(),
        updatePaymentSuccessSaga(),
        updateCouponDetailsSaga(),
        fetchProfileSaga(),
        updateProfileSaga(),
        updateSupervisorSaga(),
        submitFlagSaga(),
        fetchAccruedSaga(),
        fetchAccruedByDateSaga(),
        fetchAllTraineesSaga(),
        deleteSaga(),
        fetchPendingByDateSaga(),
        fetchPendingDatesSaga(),
        fetchFlaggedByDateSaga(),
        fetchTasksSaga(),
        fetchSkillsSaga(),
        submitDocSaga(),
        fetchDocSaga(),
        fetchMonthlyCheckSaga(),
        fetchTableSaga(),
        fetchBadgesSaga(),
        fetchRecordFilesSaga(),
        fetchRecordIdFileSaga(),
        submitRecordFileSaga(),
        deleteRecordFileSaga(),
        fetchSupervisorsSaga(),
        submitSupervisorSaga(),
        updateTraineeSupervisorSaga(),
        deleteTraineeSupervisorSaga(),
        sendInvitationSaga(),
        updatePaymentMethodSaga(),
        fetchUserPaymentDetailsSaga(),
        uploadImageSaga()
    ])
}
