import { createAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import {
  setRecordFile,
  startFetching,
  setError, setSuccess
} from "../../reducers/RecordFileReducer";
import { toast } from "react-toastify";

export const fetchFile = createAction("recordFile/fetchFile");

function* fetchFileSaga({payload}) {
  yield put(startFetching(true));
  try {
    const { id, formData, refreshFiles } = payload;
    const response = yield call(ApiService.patchRecordFileId, id, formData);
    yield put(setSuccess(true))
    toast.success('Successfully updated!');
    refreshFiles();
  } catch (error) {
    yield put(setError(error));
  }
}

export function* fetchRecordIdFileSaga() {
  yield takeLatest(fetchFile, fetchFileSaga);
}
