import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import {
  useFlagged,
  useFormData,
  useIsLoading,
  useLogs, usePastFlagged
} from "../../../redux/reducers/ExpLogReducer";
import {
  setShowSidebar,
  useShowSidebar,
} from "../../../redux/reducers/SidebarReducer";
import { fetchAll } from "../../../redux/sagas/experienceLog/fetchAllSaga";
import { fetchDoc } from "../../../redux/sagas/monthlyDocument/fetchDocSaga";
import { calculateWeeks, useDispatchEffect } from "../../../utils/hooks";
import "./monthlyDocument.css";
import moment from "moment";
import { useDocs } from "../../../redux/reducers/MonthlyDocReducer";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";
import { useRef } from "react";

import { BiCalendar } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import { RiArrowRightSFill, RiArrowDownSFill } from "react-icons/ri";
import { FiChevronRight } from "react-icons/fi";
import { IconContext } from "react-icons";

import { Link, useLocation } from "react-router-dom";

import FlaggedTables from '../../../components/FlaggedLogsTables'
import { fetchFlagged, fetchPastFlagged } from "../../../redux/sagas/experienceLog/fetchFlaggedSaga";
import LocalStorage from "../../../services/LocalStorage";
import { LABELS, LOG_DATA, TASK_LIST_GRID_LEGEND_HELP } from "../../../utils/CONSTANTS";
import Tooltip from "../../../components/Tooltip";

const TASKS_TABLE = [
  [
    {
      id: "A-1",
      description: "Identify the goals of behavior analysis as a science (i.e., description, prediction, control).",
    },
    {
      id: "B-1",
      description: "Define and provide examples of behavior, response, and response class.",
    },
    {
      id: "B-8",
      description: "Define and provide examples of unconditioned, conditioned, and generalized reinforcers and punishers.",
    },
    {
      id: "C-1",
      description: "Establish operational definitions of behavior.",
    },
    {
      id: "D-1",
      description: "Distinguish between dependent and independent variables.",
    },
    {
      id: "E-1",
      description: "Introduction",
    },
    {
      id: "F-1",
      description: "Review records and available data (e.g., educational, medical, historical) at the outset of the case.",
    },
    {
      id: "G-1",
      description: "Use positive and negative reinforcement procedures to strengthen behavior.",
    },
    {
      id: "G-12",
      description: "Use equivalence-based instruction.",
    },
    {
      id: "H-1",
      description: "State intervention goals in observable and measurable terms.",
    },
    {
      id: "I-1",
      description: "State the reasons for using behavior-analytic supervision and the potential risks of ineffective supervision (e.g., poor client outcomes, poor supervisee performance).",
    },
  ],
  [
    {
      id: "A-2",
      description: "Explain the philosophical assumptions underlying the science of behavior analysis (e.g., selectionism, determinism, empiricism, parsimony, pragmatism)",
    },
    {
      id: "B-2",
      description: "Define and provide examples of stimulus and stimulus class.",
    },
    {
      id: "B-9",
      description: "Define and provide examples of operant extinction.",
    },
    {
      id: "C-2",
      description: "Distinguish among direct, indirect, and product measures of behavior.",
    },
    {
      id: "D-2",
      description: "Distinguish between internal and external validity.",
    },
    {
      id: "E-2",
      description: "Responsibility as a Professional",
    },
    {
      id: "F-2",
      description: "Determine the need for behavior-analytic services.",
    },
    {
      id: "G-2",
      description: "Use interventions based on motivating operations and discriminative stimuli.",
    },
    {
      id: "G-13",
      description: "Use the high-probability instructional sequence.",
    },
    {
      id: "H-2",
      description: "Identify potential interventions based on assessment results and the best available scientific evidence.",
    },
    {
      id: "I-2",
      description: "Establish clear performance expectations for the supervisor and supervisee.",
    },
  ],
  [
    {
      id: "A-3",
      description: "Describe and explain behavior from the perspective of radical behaviorism.",
    },
    {
      id: "B-3",
      description: "Define and provide examples of respondent and operant conditioning.",
    },
    {
      id: "B-10",
      description: "Define and provide examples of stimulus control.",
    },
    {
      id: "C-3",
      description: "Measure occurrence (e.g., count, frequency, rate, percentage).",
    },
    {
      id: "D-3",
      description: "Identify the defining features of single-subject experimental designs (e.g., individuals serve as their own controls, repeated measures, prediction, verification, replication).",
    },
    {
      id: "E-3",
      description: "Responsibility in Practice",
    },
    {
      id: "F-3",
      description: "Identify and prioritize socially significant behavior-change goals.",
    },
    {
      id: "G-3",
      description: "Establish and use conditioned reinforcers.",
    },
    {
      id: "G-14",
      description: "Use reinforcement procedures to weaken behavior (e.g., DRA, FCT, DRO, DRL, NCR).",
    },
    {
      id: "H-3",
      description: "Recommend intervention goals and strategies based on such factors as client preferences, supporting environments, risks, constraints, and social validity.",
    },
    {
      id: "I-3",
      description: "Select supervision goals based on an assessment of the supervisee’s skills.",
    },
  ],
  [
    {
      id: "A-4",
      description: "Distinguish among behaviorism, the experimental analysis of behavior, applied behavior analysis, and professional practice guided by the science of behavior analysis.",
    },
    {
      id: "B-4",
      description: "Define and provide examples of positive and negative reinforcement contingencies.",
    },
    {
      id: "B-11",
      description: "Define and provide examples of discrimination, generalization, and maintenance.",
    },
    {
      id: "C-4",
      description: "Measure temporal dimensions of behavior (e.g., duration, latency, interresponse time).",
    },
    {
      id: "D-4",
      description: "Describe the advantages of single-subject experimental designs compared to group designs.",
    },
    {
      id: "E-4",
      description: "Responsibility to Clients and Stakeholders",
    },
    {
      id: "F-4",
      description: "Conduct assessments of relevant skill strengths and deficits.",
    },
    {
      id: "G-4",
      description: "Use stimulus and response prompts and fading (e.g., errorless, most-to-least, least-to-most, prompt delay, stimulus fading).",
    },
    {
      id: "G-15",
      description: "Use extinction.",
    },
    {
      id: "H-4",
      description: "When a target behavior is to be decreased, select an acceptable alternative behavior to be established or increased.",
    },
    {
      id: "I-4",
      description: "Train personnel to competently perform assessment and intervention procedures.",
    },
  ],
  [
    {
      id: "A-5",
      description: "Describe and define the dimensions of applied behavior analysis (Baer, Wolf, & Risley, 1968).",
    },
    {
      id: "B-5",
      description: "Define and provide examples of schedules of reinforcement.",
    },
    {
      id: "B-12",
      description: "Define and provide examples of motivating operations.",
    },
    {
      id: "C-5",
      description: "Measure form and strength of behavior (e.g., topography, magnitude).",
    },
    {
      id: "D-5",
      description: "Use single-subject experimental designs (e.g., reversal, multiple baseline, multielement, changing criterion).",
    },
    {
      id: "E-5",
      description: "Responsibility to Supervisees and Trainees",
    },
    {
      id: "F-5",
      description: "Conduct preference assessments.",
    },
    {
      id: "G-5",
      description: "Use modeling and imitation training.",
    },
    {
      id: "G-16",
      description: "Use positive and negative punishment (e.g., time-out, response cost, overcorrection).",
    },
    {
      id: "H-5",
      description: "Plan for possible unwanted effects when using reinforcement, extinction, and punishment procedures.",
    },
    {
      id: "I-5",
      description: "Use performance monitoring, feedback, and reinforcement systems.",
    },
  ],
  [
    null,
    {
      id: "B-6",
      description: "Define and provide examples of positive and negative punishment contingencies.",
    },
    {
      id: "B-13",
      description: "Define and provide examples of rule-governed and contingency-shaped behavior.",
    },
    {
      id: "C-6",
      description: "Measure trials to criterion.",
    },
    {
      id: "D-6",
      description: "Describe rationales for conducting comparative, component, and parametric analyses.",
    },
    {
      id: "E-6",
      description: "Responsibility in Public Statements",
    },
    {
      id: "F-6",
      description: "Describe the common functions of problem behavior.",
    },
    {
      id: "G-6",
      description: "Use instructions and rules.",
    },
    {
      id: "G-17",
      description: "Use token economies.",
    },
    {
      id: "H-6",
      description: "Monitor client progress and treatment integrity.",
    },
    {
      id: "I-6",
      description: "Use a functional assessment approach (e.g., performance diagnostics) to identify variables affecting personnel performance.",
    },
  ],
  [
    null, 
    {
      id: "B-7",
      description: "Define and provide examples of automatic and socially mediated contingencies.",
    },
    {
      id: "B-14",
      description: "Define and provide examples of the verbal operants.",
    },
    {
      id: "C-7",
      description: "Design and implement sampling procedures (i.e., interval recording, time sampling).",
    },
    null, 
    {
      id: "E-7",
      description: "Responsibility in Research",
    },
    {
      id: "F-7",
      description: "Conduct a descriptive assessment of problem behavior.",
    },
    {
      id: "G-7",
      description: "Use shaping.",
    },
    {
      id: "G-18",
      description: "Use group contingencies.",
    },
    {
      id: "H-7",
      description: "Make data-based decisions about the effectiveness of the intervention and the need for treatment revision.",
    },
    {
      id: "I-7",
      description: "Use function-based strategies to improve personnel performance.",
    },
  ],
  [
    null, 
    null, 
    {
      id: "B-15",
      description: "Define and provide examples of derived stimulus relations.",
    },
    {
      id: "C-8",
      description: "Evaluate the validity and reliability of measurement procedures.",
    }, 
    null, 
    null, 
    {
      id: "F-8",
      description: "Conduct a functional analysis of problem behavior.",
    },
    {
      id: "G-8",
      description: "Use chaining.",
    },
    {
      id: "G-19",
      description: "Use contingency contracting.",
    },
    {
      id: "H-8",
      description: "Make data-based decisions about the need for ongoing services.",
    },
    {
      id: "I-8",
      description: "Evaluate the effects of supervision (e.g., on client outcomes, on supervisee repertoires).",
    },
  ],
  [
    null, 
    null, 
    null, 
    {
      id: "C-9",
      description: "Select a measurement system to obtain representative data given the dimensions of behavior and the logistics of observing and recording.",
    }, 
    null, 
    null, 
    {
      id: "F-9",
      description: "Interpret functional assessment data.",
    },
    {
      id: "G-9",
      description: "Use discrete-trial, free-operant, and naturalistic teaching arrangements.",
    },
    {
      id: "G-20",
      description: "Use self-management strategies.",
    },
    {
      id: "H-9",
      description: "Collaborate with others who support and/or provide services to clients.",
    },
    null
  ],
  [
    null, 
    null, 
    null, 
    {
      id: "C-10",
      description: "Graph data to communicate relevant quantitative relations (e.g., equal-interval graphs, bar graphs, cumulative records).",
    }, 
    null, 
    null, 
    null, 
    {
      id: "G-10",
      description: "Teach simple and conditional discriminations.",
    },
    {
      id: "G-21",
      description: "Use procedures to promote stimulus and response generalization.",
    },
    null, 
    null
  ],
  [
    null, 
    null, 
    null, 
    {
      id: "C-11",
      description: "Interpret graphed data.",
    }, 
    null, 
    null, 
    null, 
    {
      id: "G-11",
      description: "Use Skinner’s analysis to teach verbal behavior.",
    },
    {
      id: "G-22",
      description: "Use procedures to promote maintenance.",
    },
    null, 
    null
  ],
];
const NUMBER_TASKS = [
  {
    id: "0",
    value: 0,
    color: "#EEEEEE",
  },
  {
    id: "1",
    value: 1,
    color: "#FFEEAF",
  },
  {
    id: "2",
    value: 2,
    color: "#F4EEB6",
  },
  {
    id: "3",
    value: 3,
    color: "#E8EDBD",
  },
  {
    id: "4",
    value: 4,
    color: "#DDECC4",
  },
  {
    id: "5",
    value: 5,
    color: "#D1EBCA",
  },
  {
    id: "6",
    value: 6,
    color: "#C5EBD1",
  },
  {
    id: "7",
    value: 7,
    color: "#B9EAD7",
  },
  {
    id: "8",
    value: 8,
    color: "#AEE9DE",
  },
  {
    id: "9+",
    value: 9,
    color: "#A1E8E4",
  },
];

const NUMBER_TASKS2 = [
  {
    id: "0 - 3",
    value: 1,
    color: "#F9E18A",
  },
  {
    id: "3 - 6",
    value: 2,
    color: "#75D6D1",
  },
  {
    id: "6 - 10",
    value: 3,
    color: "#D37498",
  },
];
const MonthlyDocument = () => {
  const location = useLocation();
  const [date, setDate] = useState(new Date());
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  const logs = useLogs();
  const isLoading = useIsLoading();
  // Flagged logs tables logic
  const flaggedLogs = useFlagged();
  const pastLogs = usePastFlagged();

  // const refreshFlagged = useDispatchEffect(fetchFlagged, null, true);
  // const refreshPast = useDispatchEffect(fetchPastFlagged, null, true);

  const onRefreshData = useDispatchEffect(fetchAll, null, true);

  useEffect(() => {
    if (location?.state !== null)
      setDate(new Date(location?.state?.data?.date));
  }, [location]);

  // Triggers dispatch effect with date param ('MM-YYYY') or null for overall
  const refreshData = (isOverall, date = null) => {
    if (isOverall) {
      onRefreshData()
    } else {
      const params = moment(date).format('MM-YYYY')
      onRefreshData(params)
    }
  }
  const callbackFunction = (childData) => {
    setDate(childData);

    // Refresh experience log after date update
    if (isChecked) {
      refreshData(!isChecked, childData);
    }
  };


  // Refresh experience log when toggle overall to monthly view
  useEffect(() => {
    refreshData(!isChecked, date)
  }, [isChecked])

  const tasks = {};
  let total_restricted_hrs = 0;
  let total_unrestricted_hrs = 0;
  let total_supervisor_hrs = 0;

  for (const log of logs) {
    const taskCodes = log.task?.map((t) => t.name.split(" ")[0]);
    let check = moment(log.date_of_experience).isSame(date, "month");

    if (isChecked) {
      if (check) {
        for (const taskCode of taskCodes) {
          if (taskCode) {
            if (tasks[taskCode] && Array.isArray(tasks[taskCode]))
              tasks[taskCode].push(log?.date_of_experience);
            else tasks[taskCode] = [log?.date_of_experience];
          }
        }
        total_restricted_hrs += Number(log?.restricted_hours);
        total_unrestricted_hrs += Number(log?.unrestricted_hours);
      }
    } else {
      for (const taskCode of taskCodes) {
        if (taskCode) {
          if (tasks[taskCode] && Array.isArray(tasks[taskCode]))
            tasks[taskCode].push(log?.date_of_experience);
          else tasks[taskCode] = [log?.date_of_experience];
        }
      }
      total_restricted_hrs += Number(log?.restricted_hours);
      total_unrestricted_hrs += Number(log?.unrestricted_hours);
    }
  }
  total_supervisor_hrs = total_restricted_hrs + total_unrestricted_hrs;

  const renderTaskDates = (task) => {
    var taskDates = tasks[task];
    if (!taskDates) {
      return <li>
        <em>Task not completed</em>
      </li>
    }
    taskDates = taskDates.sort();
    return taskDates.map((i) => {
      var date = "mm/dd/yyyy";
      var splitDate = i.split("-");
      if (splitDate[1].startsWith("0")) {
        splitDate[1] = splitDate[1].substring(1);
      }
      if (splitDate[2].startsWith("0")) {
        splitDate[2] = splitDate[2].substring(1);
      }
      date = splitDate[1] + "/" + splitDate[2] + "/" + splitDate[0];
      const disabled = date === "mm/dd/yyyy";
      return (
        <li key={task + i}>
          {date}
        </li>
      );
    });
  };
  const renderTaskColor2 = (task) => {
    const taskDates = tasks[task];
    const taskLength = taskDates?.length || 0;
    if (taskLength >= 9) {
      return "#A1E8E4";
    } else {
      const matchingTask = NUMBER_TASKS.find(
        (item) => item.value === taskLength
      );
      return matchingTask ? matchingTask.color : "#EEEEEE";
    }
  };

  const renderTaskColor = (task) => {
    const taskDates = tasks[task];
    if (taskDates?.length > 0 && taskDates?.length <= 3) return "#F9E18A";
    else if (taskDates?.length > 3 && taskDates?.length <= 6) return "#75D6D1";
    else if (taskDates?.length > 6 && taskDates?.length <= 10) return "#D37498";
    else return "#FFFFFF";
  };

  const toogleButton = () => {
    if (isChecked) {
      return (
        <div onClick={handleOnChange}>
          <FaEye /> View Overall
        </div>
      );
    } else {
      return (
        <div onClick={handleOnChange}>
          <BiCalendar /> View Monthly
        </div>
      );
    }
  };

  const displayFlags = () => {
    return (
      <div>
        <img />
      </div>
    );
  };

  const showSidebar = useShowSidebar();
  const dispatch = useDispatch();

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="section">
      <div className="columns">
        <div className="column">
          <button
            className="Toggle_btn"
            onClick={() => dispatch(setShowSidebar(!showSidebar))}
          >
            {showSidebar ? <GiHamburgerMenu /> : <GiHamburgerMenu />}
          </button>
          {/* {TestElement()} */}
          <div className="grid-top">
            <p className="mb30 heading2nd">Task List Grid</p>
            <div>
              <div style={{float: "left", position: "relative", top: "-5px", left: "-5px" }}>
                <Tooltip content={TASK_LIST_GRID_LEGEND_HELP} />
              </div>
              <div className="color-scale">
                {NUMBER_TASKS2.map((i) => {
                  return (
                    <div className="pill" style={{ backgroundColor: i.color }} key={i.id}>
                      {i.id}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="grid-top mb-2" style={{ alignItems: "center" }}>
            <div>
              <div className="topping">{toogleButton()}</div>
            </div>
            <AgendaForm searchDate={callbackFunction} isChecked={isChecked} />
            {displayFlags()}
          </div>
          <div>
            <div className="columns">
              <div className="column is-four-fifths monthlyTableMove">
                <table style={{ border: "none" }}>
                  <tbody className="monthlyTable" style={{ border: "none" }}>
                  {TASKS_TABLE.map((row, i) => (
                    <tr key={"row-" + i}>
                      {row.map((cell, j) => (
                        <td
                          key={`cell-${i}${j}`}
                          className={j === 2 ? "customColumn" : ""}
                        >
                          {cell && (
                            <div
                              style={{
                                background: renderTaskColor(cell.id),
                                border: "none",
                              }}
                              className="selectBtn dropdownCustom mt is-normal"
                            >
                              {cell.id.replace("-", "")}
                              <div>
                                <p
                                style={{
                                  background: renderTaskColor(cell.id),
                                  textAlign: "left",
                                  paddingTop: 10,
                                  paddingLeft: 10,
                                }}>
                                  <span style={{display: "inline-block", marginBottom: "10px"}}>{cell.description}</span><br />
                                    <strong>
                                      Dates completed:
                                    </strong>
                                  </p>
                                <ul
                                  style={{
                                    background: renderTaskColor(cell.id),
                                    textAlign: "left",
                                    padding: 10,
                                  }}
                                >
                                  {renderTaskDates(cell.id)}
                                </ul>
                              </div>
                            </div>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <p className="instruct">Select a task above to view specific logs</p>
          {/*<RecentEntries data={"hola"} />
          <FlaggedEntries data={"j"} />*/}
          <div>
            <FlaggedTables
              flaggedLogs={flaggedLogs}
              pastLogs={pastLogs}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const AgendaForm = ({ searchDate, isChecked }) => {

  const { tasks: skills } = useFormData();
  const docs = useDocs();
  const location = useLocation();
  const [date, setDate] = useState(new Date());
  const [week, setWeek] = useState(0);
  const [close, setClose] = useState(true);
  const weeks = calculateWeeks(date);
  const weekDates = weeks[week];


  const initialValues = {
    supervisee: "",
    supervisor: "",
    startTime: "",
    endTime: "",
    date: "",
    format: "",
    fieldHoursCheck: false,
    accruedData: null,
    supervisedFieldwork: false,
    practicum: false,
    intensivePracticum: false,
    superviseeCheck: false,
    superviseeFeedback: "",
    observationChecK: "",
    observationDate: "",
    skills: 2,
    observationFeedback: "",
    clinicalPracticeCheck: false,
    type: "",
    completed: false,
    notCompleted: false,
    inPerson: false,
    online: false,
    clinicalPracticeFeedback: "",
    taskListReviewCheck: false,
    tasks: [],
  };
  const [values, setValues] = useState(initialValues);

  const onPrevMonth = () => {
    const newDate = moment(date).subtract(1, "month");
    searchDate(newDate.toDate());
    setDate(newDate.toDate());
  };
  const onNextMonth = () => {
    const newDate = moment(date).add(1, "month");
    searchDate(newDate.toDate());
    setDate(newDate.toDate());
  };
  const onWeekChange = (weekNo) => {
    setWeek(weekNo);
  };

  useEffect(() => {
    searchDate(moment(date), isChecked);
  }, [isChecked]);


  const handleChange = (prop) => (e) => {};
  const handleCheckChange = (prop) => (e) => {};
  const handleRadioGroup1 = (prop) => (e) => {};
  const handleRadioGroup2 = (prop) => (e) => {};
  const handleRadioGroup3 = (prop) => (e) => {};

  const handleClose = () => {
    setClose(!close);
  };

  useDispatchEffect(fetchDoc, null, true);

  useEffect(() => {
    const weekStart = moment(weekDates.from, "YYYY-MM-DD");
    const weekSelected = weekStart.isoWeek();
    const document =
      docs.find((doc) => {
        const weekDoc = moment(doc.date, "YYYY-MM-DD").isoWeek();
        return weekDoc === weekSelected;
      }) || {};
    if (document.date) {
      setValues({ ...(document?.data || initialValues) });
      setClose(false);
    }
  }, [weekDates.from]);

  useEffect(() => {
    if (location?.state !== null) setDate(new Date(location.state.data.date));
  }, [location]);

  const toPdf = () => {
    const element = targetRefPdf.current;
    var scale = 2;
    domtoimage
      .toPng(element, {
        width: element.clientWidth * scale,
        height: element.clientHeight * scale,
        style: {
          transform: "scale(" + scale + ")",
          transformOrigin: "top left",
        },
      })
      .then(function (dataUrl) {
        const pdf = new jsPDF("p", "pt", "a4");
        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();
        pdf.addImage(dataUrl, "JPEG", 0, 0, width, height);
        pdf.save(`${new Date().toISOString()}.pdf`);
      });
    // const dataUrl = exportToPng(element);
    // console.log(dataUrl);
    // const pdf = new jsPDF("p", "pt", "a4");
    // pdf.fromHTML(element);

    // pdf.save(`${new Date().toISOString()}.pdf`);
    //
  };

  const targetRefPdf = useRef();

  return (
    <>
      <div className="section2 monthly-document">
        <div className="columns">
          <div className="column">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p
                className="headingMain mt30"
                style={{ marginLeft: "12px"}}
              >
                <button
                  className="backlink"
                  onClick={onPrevMonth}
                  disabled={!isChecked}
                >
                  <IoIosArrowBack
                    size={24}
                    style={{ margin: "0 20px", marginLeft: 0 }}
                  />
                </button>
                <select
                  className="select selectBtn dropdownCustom2  is-normal"
                  style={{ height: "28px" }}
                  value={week}
                  onChange={(e) => onWeekChange(e.target.value)}
                >
                  {weeks.map((week, index) => (
                    <option key={index} value={index}>
                      {isChecked
                        ? `${moment()
                            .month(date.getMonth())
                            .format("MMMM")}  ${date.getFullYear()}`
                        : "Overall Progress"}
                    </option>
                  ))}
                </select>
                <button
                  className=" backlink"
                  onClick={onNextMonth}
                  style={{ margin: "0 20px" }}
                  disabled={!isChecked}
                >
                  <IoIosArrowForward size={24} style={{ margin: "0 20px" }} />
                </button>
                {!close > 0 && (
                  <button
                    className="button is-rounded"
                    style={{
                      float: "right",
                      marginRight: "10px",
                      color: "white",
                      backgroundColor: "#D37498",
                    }}
                    onClick={toPdf}
                  >
                    Download PDF
                  </button>
                )}
              </p>

              {values.date.length > 0 && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className="button   is-rounded  mt30"
                    style={{
                      float: "right",
                      marginRight: "10px",
                      color: "white",
                      backgroundColor: "#D37498",
                    }}
                    onClick={handleClose}
                  >
                    {close ? "Open" : "Close"}
                  </button>
                  <button
                    className="button is-rounded  mt30"
                    style={{
                      float: "right",
                      marginRight: "10px",
                      color: "white",
                      backgroundColor: "#D37498",
                    }}
                    onClick={toPdf}
                  >
                    {"Download PDF"}
                  </button>
                </div>
              )}
            </div>

            {!close && (
              <div className="column mt40" id="targetRefPdf" ref={targetRefPdf}>
                <div className="headerChart" id={"monthly-document"}>
                  <p id={"monthly-document"}>SUPERVISION CONTACT AGENDA</p>
                </div>
                <div className="box">
                  <div className="agendaSec1 mt10">
                    <div>
                      <p>
                        <span>Supervisee: </span>
                        <span className="contactAgendaInput">
                          {values.supervisee}
                        </span>
                      </p>
                      <p>
                        <span>Start Time: </span>
                        <span className="contactAgendaInput">
                          {values.startTime}
                        </span>
                      </p>
                      <p>
                        <span>Date: </span>
                        <span className="contactAgendaInput">
                          {values.date}
                        </span>
                      </p>
                    </div>
                    <div className="ml160">
                      <p>
                        <span>Supervisor: </span>
                        <span className="contactAgendaInput">
                          {values.supervisor}
                        </span>
                      </p>
                      <p>
                        <span>End Time: </span>
                        <span className="contactAgendaInput">
                          {values.endTime}
                        </span>
                      </p>
                      <p>
                        <span>Format: </span>
                        <span className="contactAgendaInput">
                          {values.format}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div style={{ height: "15px" }} />

                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkSize"
                      checked={values.fieldHoursCheck}
                      onChange={handleCheckChange("fieldHoursCheck")}
                    />
                    <span>Fieldwork Hours Check</span>
                  </label>
                  <div
                    className="agendasec2 mt20"
                    style={{ margin: "15px 40px" }}
                  >
                    <AccruedTable
                      title={`Month to Date:  ${moment()
                        .month(date.getMonth())
                        .format("MMMM")}`}
                      data={values?.accruedData?.weekly || {}}
                    />
                    <AccruedTable
                      title={`Total Accrued`}
                      data={values?.accruedData?.total || {}}
                    />
                    <div style={{ marginRight: "20px" }}>
                      <p className="checkText">
                        <input
                          type="radio"
                          name="checkbox1"
                          className="checkSize"
                          checked={Boolean(values.supervisedFieldwork)}
                          onChange={handleRadioGroup1("supervisedFieldwork")}
                        />
                        Supervised Fieldwork
                      </p>
                      <p className="checkText">
                        <input
                          type="radio"
                          name="checkbox1"
                          className="checkSize"
                          checked={Boolean(values.practicum)}
                          onChange={handleRadioGroup1("practicum")}
                        />
                        Practicum
                      </p>
                      <p className="checkText">
                        <input
                          type="radio"
                          name="checkbox1"
                          className="checkSize"
                          checked={Boolean(values.intensivePracticum)}
                          onChange={handleRadioGroup1("intensivePracticum")}
                        />
                        Intensive Practicum
                      </p>
                    </div>
                  </div>
                  <div style={{ height: "15px" }} />

                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkSize"
                      checked={values.superviseeCheck}
                      onChange={handleCheckChange("superviseeCheck")}
                    />
                    <span>Supervisee Check</span>
                  </label>

                  <div className="column mt20" style={{ margin: "0px 30px" }}>
                    <label style={{ lineHeight: "30px" }}>
                      Supervisee questions and feedback:
                    </label>
                    <textarea
                      className="textarea  "
                      placeholder="Type here..."
                      value={values.superviseeFeedback}
                      disabled
                    ></textarea>
                  </div>
                  <div style={{ height: "15px" }} />

                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkSize"
                      checked={values.observationChecK}
                      onChange={handleCheckChange("observationChecK")}
                    />
                    <span>Observation Check</span>
                  </label>

                  <div
                    className="mt20 agendasec3"
                    style={{ margin: "20px 50px" }}
                  >
                    <p>{`Observation date:   ${values.observationDate}`}</p>
                    <br />
                    <label className="mt10">Skills demonstrated:</label>
                    <br />
                    <p>
                      {
                        skills.find((item) => item.id == Number(values.skills))
                          ?.name
                      }
                    </p>
                    <p className="mt30">Feedback:</p>
                    <textarea
                      className="textarea  "
                      placeholder="Type here..."
                      value={values.observationFeedback}
                      onChange={handleChange("observationFeedback")}
                      disabled
                    ></textarea>
                  </div>
                  <div style={{ height: "15px" }} />

                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkSize"
                      checked={values.clinicalPracticeCheck}
                      onChange={handleChange("clinicalPracticeCheck")}
                    />
                    <span>Clinical Practice Check</span>
                  </label>

                  <div className="mt30" style={{ margin: "20px 50px" }}>
                    <p>{`Type:  ${values.type}`}</p>
                    <div className="check mt10">
                      <p>
                        <input
                          type="radio"
                          name="checkGroup2"
                          className="checkSize"
                          checked={values.completed}
                          onChange={handleRadioGroup2("completed")}
                        />
                        Completed
                      </p>
                      <p>
                        <input
                          type="radio"
                          name="checkGroup3"
                          className="checkSize"
                          checked={values.inPerson}
                          onChange={handleRadioGroup3("inPerson")}
                        />
                        In person
                      </p>
                      <p>
                        <input
                          type="radio"
                          name="checkGroup2"
                          className="checkSize"
                          checked={values.notCompleted}
                          onChange={handleRadioGroup2("notCompleted")}
                        />
                        Not completed
                      </p>
                      <p>
                        <input
                          type="radio"
                          name="checkGroup3"
                          className="checkSize"
                          checked={values.online}
                          onChange={handleRadioGroup3("online")}
                        />
                        Online
                      </p>
                    </div>
                    <p className="mt30" style={{ lineHeight: "30px" }}>
                      Feedback:
                    </p>
                    <textarea
                      className="textarea  "
                      placeholder="Type here..."
                      value={values.clinicalPracticeFeedback}
                      onChange={handleChange("clinicalPracticeFeedback")}
                      disabled
                    ></textarea>
                  </div>
                  <div style={{ height: "15px" }} />

                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkSize"
                      checked={values.taskListReviewCheck}
                      onChange={handleCheckChange("taskListReviewCheck")}
                    />
                    <span>Task List Review Check</span>
                  </label>

                  <div
                    className="mt30 agendaTable"
                    style={{ margin: "20px 20px" }}
                  >
                    <table>
                      <tr className="noBorder">
                        <th
                          rowSpan="2"
                          align="left"
                          className="agendaTableHeader"
                          style={{ textAlign: "left", paddingLeft: "16px" }}
                        >
                          Task
                        </th>
                        <th colSpan="2" className="agendaTableHeader">
                          Assessment
                        </th>
                        <th rowSpan="2" className="agendaTableHeader">
                          Feedback
                        </th>
                        <th rowSpan="2" className="agendaTableHeader">
                          Review
                        </th>
                      </tr>
                      <tr>
                        <th className="agendaTableText">Can Demonstrate</th>
                        <th className="agendaTableText"> Can Explain</th>
                      </tr>
                      {values.tasks?.length > 0 &&
                        values.tasks?.map((task, i) => (
                          <tr key={i}>
                            <td
                              style={{
                                width: "30%",
                                textAlign: "left",
                                paddingLeft: "16px",
                              }}
                            >
                              {task?.name}
                            </td>
                            <td style={{ width: "10%" }}>
                              <input
                                type="checkbox"
                                className=""
                                checked={task?.canDemonstrate}
                                readOnly
                              />
                            </td>
                            <td style={{ width: "10%" }}>
                              <input
                                type="checkbox"
                                className=""
                                checked={task?.canExplain}
                                readOnly
                              />
                            </td>
                            <td style={{ width: "20%" }}>{task?.feedback}</td>
                            <td style={{ width: "20%" }}>{task?.review}</td>
                          </tr>
                        ))}
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const AccruedTable = ({ title, data }) => {
  const {
    independent_hours,
    unrestricted_hours,
    percent_unrestricted,
    supervision_contacts,
    percent_supervision,
  } = data;

  return (
    <div>
      <table className="agenda-table">
        <tr className="barTableHeader">
          <th colSpan="2">{title}</th>
        </tr>
        <tr>
          <td>Independent Hours</td>
          <td className="colSize1">{independent_hours || ""}</td>
        </tr>
        <tr>
          <td>Unrestricted Hours</td>
          <td className="colSize1">{unrestricted_hours || ""}</td>
        </tr>
        <tr>
          <td>% Unrestricted</td>
          <td className="colSize1">{percent_unrestricted?.toFixed(0) || ""}</td>
        </tr>
        <tr>
          <td># Supervision Contacts</td>
          <td className="colSize1">{supervision_contacts?.toFixed(0) || ""}</td>
        </tr>
        <tr>
          <td>% Supervision</td>
          <td className="colSize1">{""}</td>
        </tr>
      </table>
    </div>
  );
};

const RecentEntries = ({ data }) => {
  return (
    <>
      <p>Recent Entries</p>
      <div></div>
      <ListItem
        task="A1"
        date="3/4/2023"
        comment="This is a log I wrote in order to display a string of sample text which would eventually truncate if it were to reach a length that is too..."
      />
    </>
  );
};

const FlaggedEntries = ({ data }) => {
  return (
    <>
      <p>Flagged Entries</p>
      <div></div>
    </>
  );
};

const ListItem = ({ date, comment, task }) => {
  const [check, setCheck] = useState(false);

  const handleArrows = () => {
    if (check) {
      return (
        <div className="toogle-btnMI" onClick={() => setCheck(!check)}>
          <IconContext.Provider value={{ color: "#D2D2D2", size: "3em" }}>
            <RiArrowDownSFill />
          </IconContext.Provider>
        </div>
      );
    } else
      return (
        <div className="toogle-btnMI" onClick={() => setCheck(!check)}>
          <IconContext.Provider value={{ color: "#D2D2D2", size: "3em" }}>
            <RiArrowRightSFill />
          </IconContext.Provider>
        </div>
      );
  };

  const handleInfo = () => {
    if (check) {
      return (
        <div className="container">
          <p>Log entry details</p>
          <div>content</div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className="content-item">
      {handleArrows()}
      <div className="list-item">
        <div className="top">
          <div className="left">{task}</div>
          <div className="center">
            <p className="date">{date}</p>
            <p>{comment}</p>
          </div>
          <div className="right">
            <IconContext.Provider value={{ color: "#FF7CBB", size: "3em" }}>
              <FiChevronRight />
            </IconContext.Provider>
          </div>
        </div>
        <div className="bottom">{handleInfo()}</div>
      </div>
    </div>
  );
};

export default MonthlyDocument;
