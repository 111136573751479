import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosArrowBack } from "react-icons/io";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setShowSidebar,
  useShowSidebar,
} from "../../../redux/reducers/SidebarReducer";
import LoadingSpinner from "../../../components/LoadingSpinner";
import {
  useData,
  useIsLoading,
  useUserSubscription,
} from "../../../redux/reducers/SubscriptionReducer";
import { fetchSubscriptions } from "../../../redux/sagas/subscription/fetchSaga";
import { useDispatchEffect } from "../../../utils/hooks";
import "./dashboardSubscription2.css";
import Dog from "../../../assets/dog.svg";
import Star from "../../../assets/star.svg";
import { toast } from "react-toastify";

const SubscriptionScreen2 = () => {
  const navigate = useNavigate();
  const showSidebar = useShowSidebar();
  const dispatch = useDispatch();

  let subscriptions = useData();
  subscriptions = subscriptions.filter((e) => e.name != "Trial");
  const userSubscription = useUserSubscription();

  const isLoading = useIsLoading();

  useDispatchEffect(fetchSubscriptions, null, subscriptions?.length == 0);

  const [selected, setSelected] = useState(userSubscription?.subscription?.id);

  let monthPrice;
  for (const subscription of subscriptions) {
    if (subscription.price.period === "month") {
      monthPrice = subscription.price.price;
    }
  }

  function hasDecimals(number) {
    return number % 1 !== 0;
  }

  const handleUpdate = () => {
    if (selected === 3 || selected === undefined) {
      return toast.error("Please select a subscription plan");
    } else {
      navigate(`/student/payment-screen-dashbard?subscription=${selected}`);
    }
  };

  const handleTypeOfSubscription = (item) => {
    if (item.price?.period === "month") {
      return (
        <div className="panel panel-default card-input">
          <div className="panel-head">
            <h1 className="title" style={{ marginTop: 40, marginBottom: 5 }}>
              <div className="price" style={{ fontWeight: 500 }}>
                <sub style={{ fontSize: 22, fontWeight: 400 }}>$</sub>{" "}
                {hasDecimals(item.price?.price)
                  ? item.price?.price.toFixed(2)
                  : item.price?.price}
              </div>
            </h1>
            <p className="subtitle2">
              <b>per month</b>
            </p>
          </div>
        </div>
      );
    }
    if (item.price?.period === "year") {
      return (
        <div className="panel panel-default card-input">
          <div className="panel-head">
            <h1 className="title" style={{ marginTop: 40, marginBottom: 5 }}>
              <div className="price" style={{ fontWeight: 500 }}>
                <sub style={{ fontSize: 22, fontWeight: 400 }}>$</sub>{" "}
                {hasDecimals(item.price?.price)
                  ? item.price?.price.toFixed(2)
                  : item.price?.price}
              </div>
            </h1>
            <p className="subtitle2">
              <b>per year</b>
            </p>

            <p className="subtitle2" style={{ marginTop: 10 }}>
              Save $
              {(monthPrice * 12 - item?.price?.price.toFixed(2)).toFixed(2)}
            </p>
          </div>
        </div>
      );
    }
    if (item.price?.period === "life_time") {
      return (
        <div className="panel panel-default card-input">
          <div className="panel-head">
            <h1 className="title" style={{ marginTop: 40, marginBottom: 5 }}>
              <div className="price" style={{ fontWeight: 500 }}>
                <sub style={{ fontSize: 22, fontWeight: 400 }}>$</sub>{" "}
                {hasDecimals(item.price?.price)
                  ? item.price?.price.toFixed(2)
                  : item.price?.price}
              </div>
            </h1>
            <p className="subtitle2">
              <b>
                Lifetime<br></br> Subscription
              </b>
            </p>
          </div>
        </div>
      );
    }
  };

  // Custom sorting function
  const sortBySubscriptionType = (a, b) => {
    if (a.price.period === "life_time") {
      return -1; // "a" comes before "b"
    } else if (b.price.period === "life_time") {
      return 1; // "b" comes before "a"
    } else if (a.price.period === "year") {
      return -1; // "a" comes before "b"
    } else if (b.price.period === "year") {
      return 1; // "b" comes before "a"
    } else {
      return 0; // maintain the existing order
    }
  };

  // Apply sorting to subscriptions array
  const sortedSubscriptions = subscriptions
    .slice()
    .sort(sortBySubscriptionType);

  return (
    <div className="section has-light-background">
      <div className="columns is-centered">
        <div className="column"></div>
      </div>

      <div className="column">
        <button
          className="Toggle_btn"
          onClick={() => dispatch(setShowSidebar(!showSidebar))}
        >
          {showSidebar ? <GiHamburgerMenu /> : <GiHamburgerMenu />}
        </button>
        <p className="headingMain">
          <a className=" backlink" onClick={(_) => navigate(-1)}>
            <IoIosArrowBack />
          </a>
          Change Subscription Plan
        </p>

        <div className="row-start-sub subsCard mt120">
          {isLoading ? (
            <LoadingSpinner style={{ height: "230px", width: "50%" }} />
          ) : (
            sortedSubscriptions.map((item) => (
              <div className="col-md-4 col-lg-4 col-sm-4" key={item.id}>
                {item.name === userSubscription?.subscription?.name &&
                  userSubscription.is_active && (
                    <div className="current-plan">
                      <div className="line"></div>
                      <p className="current-subscription">Current plan</p>
                      <div className="line"></div>
                    </div>
                  )}
                {item.price?.period === "life_time" && (
                  <div className="current-plan">
                    <img src={Star} alt="Star" />
                    <img src={Dog} alt="dog" />
                    <p
                      className="current-subscription"
                      style={{ color: "#B9547A" }}
                    >
                      Most Popular!
                    </p>
                    <img src={Star} alt="Star" />
                  </div>
                )}

                <label>
                  <input
                    type="radio"
                    name="subscription"
                    className={
                      item.name === userSubscription?.subscription?.name &&
                      userSubscription.is_active
                        ? "card-input-element-current"
                        : "card-input-element"
                    }
                    defaultChecked={userSubscription?.subscription?.name}
                    checked={item.id === selected}
                    onClick={(_) => {
                      setSelected(item.id);
                    }}
                  />
                  {handleTypeOfSubscription(item)}
                </label>
              </div>
            ))
          )}
        </div>
        <div className="subscriptionPlan">
          <h3>Which subscription should you buy? Lifetime? Annual? Monthly?</h3>
          <ol className="number-list">
            <li>
              If you are on the fast track you can finish supervision in a
              year... But let&#39;s be real boo, on average folks take about 18
              months.
            </li>
            <li>
              Depending on what your supervisor is able to do you need 1500-2000
              hours!
            </li>
            <li>
              Of course data based decisions here... which makes the most sense
              to you?
            </li>
          </ol>
        </div>
        <div
          className="columns is-mobile is-centered mt60"
          style={{ width: "950px" }}
        >
          <p className="comingSoon">
            <strong>
              <em>Coming soon!</em> Graduate plan
            </strong>
            <br></br>
            Access your documents, even after you are done with your training.
            <br></br>
            Included with Lifetime subscription.
          </p>
          <a
            className="button is-large is-rounded dashBtn"
            style={{
              backgroundColor: "#B9547A",
              width: "230px",
              textDecoration: "none",
              margin: "auto",
              marginTop: 30,
            }}
            // onClick={(_) =>
            //   navigate(
            //     `/student/payment-screen-dashbard?subscription=${selected}`
            //   )
            // }
            onClick={handleUpdate}
          >
            Update
          </a>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionScreen2;
