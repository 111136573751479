// Reducer that changes the state based on the action

import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

export const slice = createSlice({
  name: "monthlyDoc",
  initialState: {
    isLoading: false,
    accruedData: {
      total: {},
      weekly: {}
    },
    tasks: [],
    skills: [],
    docs: [],
    success: false,
    error: null
  },
  reducers: {
    startFetching: (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
      state.isLoading = false;
      state.error = null
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.success = false
    },
    setTotalAccruedData: (state, action) => {
      state.accruedData.total = action.payload;
      state.isLoading = false;
    },
    setWeeklyAccruedData: (state, action) => {
      state.accruedData.weekly = action.payload;
      state.isLoading = false;
    },
    setTasks: (state, action) => {
      state.tasks = action.payload;
      state.isLoading = false;
    },
    setSkills: (state, action) => {
      state.skills = action.payload;
      state.isLoading = false;
    },
    setDocs: (state, action) => {
      state.docs = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  startFetching,
  setSuccess,
  setError,
  setTotalAccruedData,
  setWeeklyAccruedData,
  setTasks,
  setSkills,
  setDocs
} = slice.actions;

export const useAccruedData = () => useSelector((state) => state.MonthlyDoc.accruedData);
export const useTasks = () => useSelector((state) => state.MonthlyDoc.tasks);
export const useSkills = () => useSelector((state) => state.MonthlyDoc.skills);
export const useDocs = () => useSelector((state) => state.MonthlyDoc.docs);
export const useIsLoading = () => useSelector((state) => state.MonthlyDoc.isLoading);
export const useSuccess = () => useSelector((state) => state.MonthlyDoc.success);
export const useError = () => useSelector((state) => state.MonthlyDoc.error);

export default slice.reducer;
