import React, { useEffect } from "react";
import CloseIcon from "../../assets/close.svg";
import "./CustomModal.css";

const CustomModal = ({
  children,
  maxWidth = "600px",
  onClose,
  modalVisible,
}) => {
  useEffect(() => {
    const body = document.body;
    if (modalVisible) body.classList.add("isHidden");
    return () => {
      body.classList.remove("isHidden");
    };
  }, [modalVisible]);

  return (
    <div className={`customModal ${modalVisible ? "active" : ""}`}>
      <div
        className="customModal__backdrop"
        onClick={() => onClose(false)}
      ></div>
      <div className="customModal__content" style={{ maxWidth: maxWidth }}>
        <img
          className="customModal__close"
          src={CloseIcon}
          onClick={() => onClose(false)}
          alt="Close"
        />

        <div className="customModal__inner">{children}</div>
      </div>
    </div>
  );
};
export default CustomModal;
