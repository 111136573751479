import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import StudentSidebar from '../../components/StudentSidebar'
import DashboardSubscription from "./dashboardSubscription";
import SubscriptionScreen2 from "./subscriptionScreen2Dashboard";
import PaymentDashboardScreen from "./paymentDashboardScreen";
import LogHour from "./logHour";
import EditLog from "./logHour/editLog"
import Dashboard from './dashboard';
import Upload from "./upload";
import SupervisionContact from "./supervision"
import MonthlyDocument from './monthlyDocument';
import EditAndResend from './editAndResend';
import ProfileScreen from "../profile";
import PromptScreen from "..//prompt";
import TermsAndCondition from "../termAndcondition";
import Privacy from "../privacyPolicy";
import Faq from '../faq';
import PasswordChangeProfile from '../dashboardChangepswd';
import { useIsSupervisor, useIsTrialExpired, useIsSubscriptionExpired, } from '../../redux/reducers/AuthReducer';
import {setIsTrialExpired, setIsSubscriptionExpired} from "../../redux/reducers/AuthReducer";
import PageUnauthorized from '../PageUnauthorized';
import { fetchProfile } from '../../redux/sagas/profile/fetchSaga';
import { useDispatchEffect } from '../../utils/hooks';
import { useIsLoading, useUserProfile } from '../../redux/reducers/ProfileReducer';
import { validateTrialPeriod, validateSubscriptionPeriod } from '../../utils/validation';
import LoadingSpinner from '../../components/LoadingSpinner';
import {useUserSubscription} from "../../redux/reducers/SubscriptionReducer";
import SubscriptionDialog from '../../components/SubscriptionDialog';
import { fetchUserSubscription } from "../../redux/sagas/subscription/fetchSaga";
import UpdatePaymentMethod from "./dashboardSubscription/UpdatePaymentMethod";


export default function StudentDashboard() {
  const isSupervisor = useIsSupervisor();
  const user = useUserProfile();
  const isLoading = useIsLoading();

  useDispatchEffect(fetchProfile, null, true);

  const isTrialExpired = useIsTrialExpired()
  const isSubscriptionExpired = useIsSubscriptionExpired()

  const [showDialog, setShowDialog] = useState(false);
  const [subscriptionMessage, setSubscriptionMessage] = useState('Looks like your 7 day free trial has expired.')


  useEffect(() => {
    if(isTrialExpired) {
      setSubscriptionMessage('Looks like your 7 day free trial has expired.')
      setShowDialog(true)
    } else if (isSubscriptionExpired) {
      setSubscriptionMessage('Looks like your subscription has expired.')
      setShowDialog(true)
    }
  }, [isTrialExpired, isSubscriptionExpired]);

  if(isLoading)
  return (
    <LoadingSpinner style={{ width: '100%'}} />
  )

  if(isSupervisor === true)
    return <PageUnauthorized />

  return (
    <div className="sectiondashboard">
        <StudentSidebar />
        <Routes>
          <Route
            path="/dashboard"
            element={<Dashboard />}
            />
          <Route
            path="/logHour"
            element={<LogHour />}
            />
          <Route
            path="/editLogHour/:id"
            element={<EditLog />}
            />
          <Route
            path="/monthly-document"
            element={<MonthlyDocument />}
            />
          <Route
            path="/dashboard-subscription"
            element={<DashboardSubscription />}
            />
          <Route
            path="/subscription-screen-dashbard"
            element={<SubscriptionScreen2 />}
            />
          <Route
            path="/subscription/update-payment-method"
            element={<UpdatePaymentMethod />}
            />
          <Route
            path="/payment-screen-dashbard"
            element={<PaymentDashboardScreen />}
            />
          <Route
            path="/upload"
            element={<Upload />}
            />
          <Route
            path="/supervision-contact-agenda"
            element={<SupervisionContact />}
            />
          <Route
            path="/edit-resend"
            element={<EditAndResend />}
            />
          <Route
            path="/profile-screen"
            element={<ProfileScreen />}
            />
          <Route
            path="/prompt-screen"
            element={<PromptScreen />}
            />
          <Route
            path="/terms-condition"
            element={<TermsAndCondition />}
          />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/faq" element={<Faq />} />
          <Route
            path="/password-change-profile"
            element={<PasswordChangeProfile />}
          />

        </Routes>

        <SubscriptionDialog isVisible={showDialog} closeDialog={() => setShowDialog(false)} message={subscriptionMessage}/>

    </div>

  )
}
