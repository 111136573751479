import React, { useEffect, useState, useRef } from "react";
import { useFormData } from "../../../redux/reducers/ExpLogReducer";
import moment from "moment";
import { useLocation } from "react-router-dom";
import LocalStorage from "../../../services/LocalStorage";
import { USER_DATA } from "../../../utils/CONSTANTS";
import { useAccruedData } from "../../../redux/reducers/MonthlyDocReducer";
import { useDispatchEffect } from "../../../utils/hooks";
import {
  fetchAccrued,
  fetchAccruedByDate,
} from "../../../redux/sagas/monthlyDocument/fetchAccruedSaga";
//from "../redux/reducers/MonthlyDocReducer";

export default function SupervisionContact() {
  const user = LocalStorage.getData(USER_DATA);
  const { state } = useLocation();
  console.log(state);
  const targetRefPdf = useRef();
  const accrued = useAccruedData();
  const [values, setValues] = useState();
  const { tasks: skills } = useFormData();
  const [date, setDate] = useState(new Date());

  const week = {
    from: moment(state.data.date_of_experience, "YYYY-MM-DD")
      .startOf("month")
      .format("YYYY-MM-DD"),
    to: moment(state.data.date_of_experience, "YYYY-MM-DD")
      .endOf("month")
      .format("YYYY-MM-DD"),
  };
  console.log(week);
  //%Y-%m-%d
  const accruedbyMonth = useDispatchEffect(
    fetchAccruedByDate,
    {
      u_id: user.id,
      week,
    },
    week.from
  );

  const data = useFormData();
  let { supervisors } = data;

  const handleChange = (prop) => (e) => {};
  const handleCheckChange = (prop) => (e) => {};
  const handleRadioGroup1 = (prop) => (e) => {};
  const handleRadioGroup2 = (prop) => (e) => {};
  const handleRadioGroup3 = (prop) => (e) => {};

  const supervisorFilter = (id) => {
    return supervisors.map((s) => {
      if (s.id == id) return s.name;
    });
  };

  useEffect(() => {
    setValues({
      accruedData: { total: accrued?.total, weekly: accrued?.weekly },
    });
  }, [accrued]);

  return (
    <>
      <div className="column mt40" id="targetRefPdf" ref={targetRefPdf}>
        <div className="headerChart" id={"monthly-document"}>
          <p id={"monthly-document"}>SUPERVISION CONTACT AGENDA</p>
        </div>
        <div className="box">
          <div className="agendaSec1 mt10">
            <div>
              <p>
                <span>Supervisee: </span>
                <span className="contactAgendaInput">{user.name}</span>
              </p>
              <p>
                <span>Start Time: </span>
                <span className="contactAgendaInput">
                  {state?.data?.time_of_expereince_start}
                </span>
              </p>
              <p>
                <span>Date: </span>
                <span className="contactAgendaInput">
                  {moment(state?.data?.date_of_experience).format("YYYY-MM-DD")}
                </span>
              </p>
            </div>
            <div className="ml160">
              <p>
                <span>Supervisor: </span>
                <span className="contactAgendaInput">
                  {supervisorFilter(state?.data?.supervisor)}
                </span>
              </p>
              <p>
                <span>End Time: </span>
                <span className="contactAgendaInput">
                  {state?.data?.time_of_expereince_end}
                </span>
              </p>
              <p>
                <span>Format: </span>
                <span className="contactAgendaInput">{values?.format}</span>
              </p>
            </div>
          </div>

          <div style={{ height: "15px" }} />

          <label className="checkbox-label">
            <input
              type="checkbox"
              className="checkSize"
              checked={values?.fieldHoursCheck}
              onChange={handleCheckChange("fieldHoursCheck")}
            />
            <span>Fieldwork Hours Check</span>
          </label>
          <div className="agendasec2 mt20" style={{ margin: "15px 40px" }}>
            <AccruedTable
              title={`Month to Date:  ${moment()
                .month(date.getMonth())
                .format("MMMM")}`}
              data={values?.accruedData?.weekly || {}}
            />
            <AccruedTable
              title={`Total Accrued`}
              data={values?.accruedData?.total || {}}
            />
            <div style={{ marginRight: "20px" }}>
              <p className="checkText">
                <input
                  type="radio"
                  name="checkbox1"
                  className="checkSize"
                  checked={Boolean(values?.supervisedFieldwork)}
                  onChange={handleRadioGroup1("supervisedFieldwork")}
                />
                Supervised Fieldwork
              </p>
              <p className="checkText">
                <input
                  type="radio"
                  name="checkbox1"
                  className="checkSize"
                  checked={Boolean(values?.practicum)}
                  onChange={handleRadioGroup1("practicum")}
                />
                Practicum
              </p>
              <p className="checkText">
                <input
                  type="radio"
                  name="checkbox1"
                  className="checkSize"
                  checked={Boolean(values?.intensivePracticum)}
                  onChange={handleRadioGroup1("intensivePracticum")}
                />
                Intensive Practicum
              </p>
            </div>
          </div>
          <div style={{ height: "15px" }} />

          <label className="checkbox-label">
            <input
              type="checkbox"
              className="checkSize"
              checked={values?.superviseeCheck}
              onChange={handleCheckChange("superviseeCheck")}
            />
            <span>Supervisee Check</span>
          </label>

          <div className="column mt20" style={{ margin: "0px 30px" }}>
            <label style={{ lineHeight: "30px" }}>
              Supervisee questions and feedback:
            </label>
            <textarea
              className="textarea  "
              placeholder="Type here..."
              value={values?.superviseeFeedback}
              disabled
            ></textarea>
          </div>
          <div style={{ height: "15px" }} />

          <label className="checkbox-label">
            <input
              type="checkbox"
              className="checkSize"
              checked={values?.observationChecK}
              onChange={handleCheckChange("observationChecK")}
            />
            <span>Observation Check</span>
          </label>

          <div className="mt20 agendasec3" style={{ margin: "20px 50px" }}>
            <p>{`Observation date:   ${values?.observationDate}`}</p>
            <br />
            <label className="mt10">Skills demonstrated:</label>
            <br />
            <p>
              {skills.find((item) => item.id == Number(values?.skills))?.name}
            </p>
            <p className="mt30">Feedback:</p>
            <textarea
              className="textarea  "
              placeholder="Type here..."
              value={values?.observationFeedback}
              onChange={handleChange("observationFeedback")}
              disabled
            ></textarea>
          </div>
          <div style={{ height: "15px" }} />

          <label className="checkbox-label">
            <input
              type="checkbox"
              className="checkSize"
              checked={values?.clinicalPracticeCheck}
              onChange={handleChange("clinicalPracticeCheck")}
            />
            <span>Clinical Practice Check</span>
          </label>

          <div className="mt30" style={{ margin: "20px 50px" }}>
            <p>{`Type:  ${values?.type}`}</p>
            <div className="check mt10">
              <p>
                <input
                  type="radio"
                  name="checkGroup2"
                  className="checkSize"
                  checked={values?.completed}
                  onChange={handleRadioGroup2("completed")}
                />
                Completed
              </p>
              <p>
                <input
                  type="radio"
                  name="checkGroup3"
                  className="checkSize"
                  checked={values?.inPerson}
                  onChange={handleRadioGroup3("inPerson")}
                />
                In person
              </p>
              <p>
                <input
                  type="radio"
                  name="checkGroup2"
                  className="checkSize"
                  checked={values?.notCompleted}
                  onChange={handleRadioGroup2("notCompleted")}
                />
                Not completed
              </p>
              <p>
                <input
                  type="radio"
                  name="checkGroup3"
                  className="checkSize"
                  checked={values?.online}
                  onChange={handleRadioGroup3("online")}
                />
                Online
              </p>
            </div>
            <p className="mt30" style={{ lineHeight: "30px" }}>
              Feedback:
            </p>
            <textarea
              className="textarea  "
              placeholder="Type here..."
              value={values?.clinicalPracticeFeedback}
              onChange={handleChange("clinicalPracticeFeedback")}
              disabled
            ></textarea>
          </div>
          <div style={{ height: "15px" }} />

          <label className="checkbox-label">
            <input
              type="checkbox"
              className="checkSize"
              checked={values?.taskListReviewCheck}
              onChange={handleCheckChange("taskListReviewCheck")}
            />
            <span>Task List Review Check</span>
          </label>

          <div className="mt30 agendaTable" style={{ margin: "20px 20px" }}>
            <table>
              <tr className="noBorder">
                <th
                  rowSpan="2"
                  align="left"
                  className="agendaTableHeader"
                  style={{ textAlign: "left", paddingLeft: "16px" }}
                >
                  Task
                </th>
                <th colSpan="2" className="agendaTableHeader">
                  Assessment
                </th>
                <th rowSpan="2" className="agendaTableHeader">
                  Feedback
                </th>
                <th rowSpan="2" className="agendaTableHeader">
                  Review
                </th>
              </tr>
              <tr>
                <th className="agendaTableText">Can Demonstrate</th>
                <th className="agendaTableText"> Can Explain</th>
              </tr>
              {state?.data?.task?.length > 0 &&
                state?.data?.task?.map((task, i) => (
                  <tr key={i}>
                    <td
                      style={{
                        width: "30%",
                        textAlign: "left",
                        paddingLeft: "16px",
                      }}
                    >
                      {task?.name}
                    </td>
                    <td style={{ width: "10%" }}>
                      <input
                        type="checkbox"
                        className=""
                        checked={task?.canDemonstrate}
                        readOnly
                      />
                    </td>
                    <td style={{ width: "10%" }}>
                      <input
                        type="checkbox"
                        className=""
                        checked={task?.canExplain}
                        readOnly
                      />
                    </td>
                    <td style={{ width: "20%" }}>{task?.feedback}</td>
                    <td style={{ width: "20%" }}>{task?.review}</td>
                  </tr>
                ))}
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

const AccruedTable = ({ title, data }) => {
  const {
    independent_hours,
    unrestricted_hours,
    percent_unrestricted,
    supervision_contacts,
    percent_supervision,
  } = data;

  return (
    <div>
      <table className="agenda-table">
        <tr className="barTableHeader">
          <th colSpan="2">{title}</th>
        </tr>
        <tr>
          <td>Independent Hours</td>
          <td className="colSize1">{independent_hours || ""}</td>
        </tr>
        <tr>
          <td>Unrestricted Hours</td>
          <td className="colSize1">{unrestricted_hours?.toFixed(0) || ""}</td>
        </tr>
        <tr>
          <td>% Unrestricted</td>
          <td className="colSize1">{percent_unrestricted?.toFixed(0) || ""}</td>
        </tr>
        <tr>
          <td># Supervision Contacts</td>
          <td className="colSize1">{supervision_contacts?.toFixed(0) || ""}</td>
        </tr>
        <tr>
          <td>% Supervision</td>
          <td className="colSize1">{percent_supervision?.toFixed(0)}</td>
        </tr>
      </table>
    </div>
  );
};
