import { createAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { startFetching, setTableData, setError } from "../../reducers/DashboardReducer";

export const fetchTable = createAction("dashboard/fetchTable");

function* fetchData() {
  yield put(startFetching(true));
  try {
    let res = yield call(ApiService.getTableData);
    if(res.data)
        yield put(setTableData(res.data));
  } catch (error) {
    console.log({ error });
    yield put(setError(error))
  }
}

export function* fetchTableSaga() {
  yield takeLatest(fetchTable, fetchData);
}

