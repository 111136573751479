import { createAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { call, all, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { setError, setSuccess, startFetching } from "../../reducers/RecordFileReducer";
import { useDispatch } from "react-redux";
import { fetchFiles } from "./fetchRecordFilesSaga";
import { useDispatchEffect } from "../../../utils/hooks";

export const submitRecordFile = createAction("recordFile/submitFile");


function* submitFile({ payload }) {
  const { formData, refreshFiles } = payload

  yield put(startFetching(true))
  try {
    let res = yield call(ApiService.postRecordFile, formData);
    if(res.data.id) {
      yield put(setSuccess(true))
      toast.success('Successfully submitted!');
      refreshFiles()
    } else {
      for (const errField in res.data) {
        const errors = res.data[errField];
        if(Array.isArray(errors) && errors.length)
          errors.forEach(err => {
            toast.error(err);
          })
        console.log('errors: ', errors)
      }
    }
  } catch (error) {
    console.error('postRecordFile: ', error);
    yield put(setSuccess(false))
    toast.error(error);
  }
}

export function* submitRecordFileSaga() {
  yield takeLatest(submitRecordFile, submitFile);
}
