import { createAction } from '@reduxjs/toolkit'
import { call, put, takeLatest } from 'redux-saga/effects'
import ApiService from '../../../services/ApiService'
import {
  startFetching,
  setFlagged,
  setPastFlagged,
  setErrors
} from '../../reducers/ExpLogReducer'
import moment from 'moment'

export const fetchFlagged = createAction('experienceLog/fetchFlagged')

function* fetchData ({payload = null}) {
  yield put(startFetching(true))
  try {
    let res = yield call(ApiService.getFlagged, payload)
    if (res.data.results) yield put(setFlagged(res.data.results))
  } catch (error) {
    console.log({ error })
    yield put(setErrors(error))
  }
}

export function* fetchFlaggedSaga () {
  yield takeLatest(fetchFlagged, fetchData)
}

export const fetchPastFlagged = createAction('experienceLog/fetchPastFlagged')

function* fetchData2 ({ payload = null }) {
  yield put(startFetching(true))
  let pending = [],
    approved = []
  try {
    let res = yield call(ApiService.getApproved, payload)
    let res2 = yield call(ApiService.getPending, payload)
    if (res.data.results) {
      approved = approved.concat(res.data.results)
      approved = approved?.sort((a, b) =>
        moment(b?.date_of_experience, 'YYYY-MM-DD').diff(
          moment(a?.date_of_experience, 'YYYY-MM-DD')
        )
      )
    }
    if (res2.data.results) {
      pending = pending.concat(res2.data.results)
      pending = pending?.sort((a, b) =>
        moment(b?.date_of_experience, 'YYYY-MM-DD').diff(
          moment(a?.date_of_experience, 'YYYY-MM-DD')
        )
      )
    }

    yield put(setPastFlagged([...approved, ...pending]))
  } catch (error) {
    console.log({ error })
    yield put(setErrors(error))
  }
}

export function* fetchPastFlaggedSaga () {
  yield takeLatest(fetchPastFlagged, fetchData2)
}
