import { createAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { startFetching, setChartData, setError } from "../../reducers/DashboardReducer";

export const fetchChartData = createAction("dashboard/fetchChartData");

function* fetchData() {
  yield put(startFetching(true))
  try {
    let res = yield call(ApiService.getCharData);
    if(res.data)
        yield put(setChartData(res.data));
  } catch (error) {
    console.log({ error });
    yield put(setError(error))
  }
}

export function* fetchChartSaga() {
  yield takeLatest(fetchChartData, fetchData);
}

