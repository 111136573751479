import "./home.css";
import logo from "../../assets/home.png";
import traker from "../../assets/home 2.svg";
import sun from "../../assets/sun.svg";
import bottomLogo from "../../assets/bottomLogo.png";
import { useNavigate, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSupervisor, useToken } from "../../utils/hooks";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSupervisor = useSupervisor();
  const Token = useToken();
  const date = new Date();
  const year = date.getFullYear();

  if (Token && isSupervisor !== null) {
    if (isSupervisor === true)
      return <Navigate to="/supervisor/signlogs" replace />;
    else if (isSupervisor === false)
      return <Navigate to="/student/logHour" replace />;
  }

  return (
    <section className="splash-has-background">
      {/* <div className="splash-bottom-mobile">
        <div className="splash-colab-bottom">
          In collaboration with
          <img className="splash-image-logo" src={bottomLogo} />
        </div>
      </div> */}
      <div className="splash-left">
        <div className="splash-text">
          <div className="splash-title">
            Want to make tracking hours less aversive?
          </div>
          <h4 className="splash-subtitle">Maximize Your Fieldwork Experience with <label>Tracker!</label></h4>
          <p className="splash-textContent" style={{ marginBottom: "10px" }}>
            Come on, we know logging those hours #suck. Have you experienced mathematical 
            Jenga trying to get excel sheets to turn green? Freaked the f#%k out about if 
            you can prove you performed the hours you actually did? Scared that the BACB is 
            out to audit you? Chose this major because you&apos;re not good at math? WE GOT YOU 
            BOO &lt;3
          </p>
          <p className="splash-textContent">
            <strong>TRACKER! Is the best web-based app that allows both trainees and supervisors to easily 
            track their experience hours for BACB certification.</strong> With the help of this app, trainees 
            can input activities, analyze their progress with the 5th edition task list for competency, 
            and share reports with supervisors. 
          </p>
        </div>
        <div className="splash-bottom">
          <div className="splash-colab-bottom">
            {/* In collaboration with
            <img
              style={{ height: "6rem", marginBottom: "10px" }}
              src={bottomLogo}
            /> */}
            <p className="splash-textContent">
              Copyright &copy; {year} Tracker. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
      <div className="splash-right">
        <div className="container-splash">
          <img src={sun} alt="sun" className="sun-splash" />
          <img className="splash-image-logo" src={traker} alt="Tracker" />
          <div className="splash-text-right">
            <p className="splash-question">#PUMPED to Track My Hours!</p>
            <p className="splash-q-r">I&apos;m a:</p>
          </div>
          <div className="button-container">
          <button
              className="button is-rounded role-button role-button-trainee"
              onClick={(_) => {
                // dispatch(setUserIsSupervisor(true));
                navigate("/login/supervisor");
              }}
            >
              Supervisor
            </button>
            <button
              className="button is-rounded role-button role-button-student"
              onClick={(_) => {
                // dispatch(setUserIsSupervisor(false));
                navigate("/login/student");
              }}
            >
              Trainee
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;

/*
<section className="has-background">
      <div className="container">
        <div className="columns is-centered is-horizontal-center">
          <div className="column is-centered columns is-multiline">
            <div className='columns is-flex is-flex-direction-column is-align-items-center'>
            <img
              src={logo}
              alt="Melton Hill Lake"
              className="column is-centered is-third-fourth log"
            />
            <img
              src={bottomLogo}
              alt="Melton Hill Lake"
              className="column is-centered is-third-fourth log bottom-logo"
            />
            </div>
           
            <div className="is-centered column is-full columns">
              <p
                style={{ textAlign: 'center' }}
                className="mt20 column is-centered is-narrow"
              >
                I am a:
              </p>
            </div>
            <div className="button-container">
              <button
                className="button is-rounded role-button role-button-trainee"
                onClick={_ => {
                  // dispatch(setUserIsSupervisor(true));
                  navigate('/login/supervisor');
                }}
              >
                Supervisor
              </button>
              <button
                className="button is-rounded role-button role-button-student"
                onClick={_ => {
                  // dispatch(setUserIsSupervisor(false));
                  navigate('/login/student');
                }}
              >
                Trainee
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
*/
