import { createAction } from "@reduxjs/toolkit";
import ApiService from "../../../services/ApiService";
import { AUTH_TOKEN, USER_DATA } from "../../../utils/CONSTANTS";
import LocalStorage from "../../../services/LocalStorage";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  logoutUser,
  setAuthToken,
  setError,
  setIsTrialExpired,
  setIsSubscriptionExpired,
  setSuccess,
  setUser,
  setUserIsSupervisor,
  startLogin,
  resendToken,
  setResetMode,
} from "../../reducers/AuthReducer";
import { toast } from "react-toastify";
import {
  validateSubscriptionPeriod,
  validateTrialPeriod,
} from "../../../utils/validation";
import { setUserSubscription } from "../../reducers/SubscriptionReducer";

export const signInAction = createAction("auth/signIn");

function* login({ payload }) {
  console.log("payload: ", payload);
  yield put(startLogin(null));
  try {
    let res = yield call(ApiService.login, payload);
    console.log("login res: ", res.data);
    if (res.data.token) {
      const { token, user } = res.data;
      let isSubscriptionValid = true;
      if (!user.is_supervisor && user.is_paid) {
        try {
          let res = yield call(ApiService.getUserSubscription);
          if (res.data.results && res.data.results.length) {
            const subscription = res.data.results[0];
            yield put(setUserSubscription(subscription));
            isSubscriptionValid = validateSubscriptionPeriod(subscription);
          }
        } catch (error) {
          console.log({ error });
        }
      }

      ApiService.setAuthHeader(token);
      LocalStorage.storeData(AUTH_TOKEN, token);
      LocalStorage.storeData(USER_DATA, user);
      const isTrialValid =
        user.is_paid === false
          ? validateTrialPeriod(new Date(user.date_joined))
          : true;
      yield put(setIsSubscriptionExpired(!isSubscriptionValid));
      yield put(setIsTrialExpired(!isTrialValid));
      yield put(setUser(user));
      yield put(setAuthToken(token));
    }

    if (res.data.unverifiedUser && res.data.user) {
      yield put(setUser(res.data.user));
      LocalStorage.storeData(USER_DATA, res.data.user);
      yield put(setResetMode(true));
    }

    if (res.data.non_field_errors) {
      toast.error(res.data.non_field_errors[0]);
      yield put(setError(res.data.non_field_errors[0]));
    }
    if (res.data.detail) {
      toast.info(res.data.detail);
      yield put(setError(res.data.detail));
    }
  } catch (error) {
    console.log({ error });
    yield put(setError(error));
  }
}
export function* loginSaga() {
  yield takeLatest(signInAction, login);
}

export const signUpAction = createAction("auth/signUp");

function* signup({ payload }) {
  console.log("payload: ", payload);
  yield put(startLogin(null));
  try {
    let res = yield call(ApiService.signup, payload);
    console.log("signup res: ", res.data);
    if (res.data) {
      const user = res.data;
      if (user.id) {
        LocalStorage.storeData(USER_DATA, user);
        yield put(setUser(user));
        toast.success("Successfully registered", { autoClose: 3000 });
      } else {
        yield put(setSuccess(false));
        for (const errField in res.data) {
          const error = res.data[errField];
          if (Array.isArray(error) && error.length)
            error.forEach((e) => toast.error(e));
        }
      }
    }
  } catch (error) {
    console.log({ error });
    yield put(setError(error));
    const errors = Object.values(error?.response?.data ?? {});
    toast.error(errors.join(error));
  }
}
export function* signupSaga() {
  yield takeLatest(signUpAction, signup);
}

export const verifyEmailAction = createAction("auth/verifyEmail");

function* verifyEmail({ payload }) {
  console.log("payload: ", payload);
  yield put(startLogin(null));
  try {
    let res = yield call(ApiService.verifyEmail, payload);
    console.log("verifyEmail res: ", res.data);
    if (res.data.message === "Succesfully User activated") {
      toast.success(res.data.message);
      yield put(setSuccess(true));
      const token = res.data.token;
      ApiService.setAuthHeader(token);
      LocalStorage.storeData(AUTH_TOKEN, token);
      yield put(setAuthToken(token));
    } else {
      toast.error(res.data.message);
      yield put(setSuccess(false));
    }
  } catch (error) {
    console.log({ error });
    yield put(setError(error));
    const errors = Object.values(error?.response?.data ?? {});
    toast.error(errors.join(error));
    yield put(setSuccess(false));
  }
}
export function* verifyEmailSaga() {
  yield takeLatest(verifyEmailAction, verifyEmail);
}

export const signOutAction = createAction("auth/signOut");

function* logout() {
  LocalStorage.delete(AUTH_TOKEN);
  LocalStorage.delete(USER_DATA);
  ApiService.removeAuthHeader();
  yield put(logoutUser(null));
}
export function* logoutSaga() {
  yield takeLatest(signOutAction, logout);
}

export const resendTokenAction = createAction("auth/resendEmail");

function* resendemail({ payload }) {
  try {
    let res = yield call(ApiService.resendEmail, payload);
    console.log("resendemail res: ", res.data);
    if (res.data.success) {
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {
    console.log({ error });
    yield put(setError(error));
    const errors = Object.values(error?.response?.data ?? {});
    toast.error(errors.join(error));
  }
}
export function* resendemailSaga() {
  yield takeLatest(resendTokenAction, resendemail);
}
