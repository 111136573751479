import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { GiHamburgerMenu } from "react-icons/gi";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { IoIosArrowBack } from "react-icons/io";
import {
  setShowSidebar,
  useShowSidebar,
} from "../../../redux/reducers/SidebarReducer";
import LoadingSpinner from "../../../components/LoadingSpinner";
import {
  setSuccess,
  useFormData,
  useErrors,
  useIsLoading,
  useSuccess, useMonthlyChecks
} from "../../../redux/reducers/ExpLogReducer";
import { fetchStaticData } from "../../../redux/sagas/experienceLog/formDataSaga";
import { fetchCurrentLogById } from "../../../redux/sagas/experienceLog/fetchExpLogByIdSaga";
import { useCurrentLog } from "../../../redux/reducers/ExpLogReducer";
import { useDispatchEffect } from "../../../utils/hooks";
import { validateLogHourForm } from "../../../utils/validation";
import {
  GROUP_HELP,
  INDEPENDENT_HELP,
  LABELS,
  LOG_DATA, OBSERVATION_HELP,
  RESTRICTED_HELP,
  UNRESTRICTED_HELP
} from "../../../utils/CONSTANTS";
import { useNavigate, useParams } from "react-router-dom";
import { deleteExpLog, editExpLog } from "../../../redux/sagas/experienceLog/editFormSaga";
import MultipleSelect from "../../../components/MultipleSelect";
import Tooltip from "../../../components/Tooltip";
import { fetchMonthlyCheck } from "../../../redux/sagas/experienceLog/fetchMonthlyCheckSaga";
import { Stack, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ApiService from "../../../services/ApiService";
import { useSupervisors } from "../../../redux/reducers/ProfileReducer";
import { fetchSupervisors } from "../../../redux/sagas/profile/supervisorSaga";
import swal from "sweetalert";

const EditLog = () => {
  let { id } = useParams()

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showSidebar = useShowSidebar();
  const data = useFormData();
  const isLoading = useIsLoading();

  const [flagItems, setFlagItems] = useState(new Map());

  let {
    expTypes,
    setting,
    contacts,
    tasks,
  } = data;

  const taskNames = tasks?.map((t) => t.name.split(" ")[0]);
  const taskDescriptions = tasks?.map((t) => t.name.split(/^(\D*\d)(.*)$/)[2]);
  const taskIds = tasks?.map((t) => t.id);

  const supervisors = useSupervisors()
  const [addNewSupervisor, setAddNewSupervisor] = useState(false);

  useDispatchEffect(fetchStaticData, null, expTypes?.length == 0);
  useDispatchEffect(fetchCurrentLogById, id )
  const refreshSupervisors = useDispatchEffect(fetchSupervisors);


  const log = useCurrentLog()

  const [initialValues, setInitialValues] = useState({
    expereince_type: "",
    setting: "",
    method_of_supervision: "",
    client_observation:  "",
    supervision_contact:  "",
    task:  [],
    supervisor:  "",
    date_of_experience: moment().format("MM/DD/YYYY"),
    // time_of_experience: log?.time_of_experience || "",
    time_of_expereince_start: "",
    time_of_expereince_end: "",
    unrestricted_hours: "",
    restricted_hours: "",
    experience_hours: "",
    individual_or_group: "",
    supervision_start_time: "",
    supervision_end_time: "",
    supervised_hours: "",
    independant_hours: "",
    experience_note: "",
    trainee_supervisor: ""
  });

  useEffect(() => {

    setInitialValues({
      expereince_type: log?.expereince_type?.id || 1,
      setting: log?.setting?.id || 1,
      method_of_supervision: log?.method_of_supervision || "",
      client_observation: log?.client_observation || 1,
      supervision_contact: log?.supervision_contact?.id || 1,
      task: log.task?.map((t) => t.name.split(" ")[0]) || [],
      supervisor: JSON.stringify(log.trainee_supervisor),
      date_of_experience:
        log?.date_of_experience || moment().format("MM/DD/YYYY"),
      // time_of_experience: log?.time_of_experience || "",
      time_of_expereince_start: log?.time_of_expereince_start || "",
      time_of_expereince_end: log?.time_of_expereince_end || "",
      unrestricted_hours: log?.unrestricted_hours || "",
      restricted_hours: log?.restricted_hours || "",
      experience_hours: log?.experience_hours || "",
      individual_or_group: log?.individual_or_group || "Individual",
      supervision_start_time: log?.supervision_start_time || "",
      supervision_end_time: log?.supervision_end_time || "",
      supervised_hours: log?.supervised_hours || "",
      independant_hours: log?.independant_hours || "",
      experience_note: log?.experience_note || "",
      trainee_supervisor: log?.trainee_supervisor || null,
    })

  }, [log]);


  const createNewSupervisorForTrainee = async (supervisor) => {
    const form = {
      name: supervisor
    }

    try {
      const createdUser = await ApiService.postTraineeSupervisor(form)

      // Refresh supervisor list after creating a new one
      refreshSupervisors()
      setAddNewSupervisor(false)

      return createdUser.data.id
    } catch (e) {
      console.log(e);
      return null
    }
  }

  const onDelete = () => {
    swal({
      title: "Are you sure you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(
          deleteExpLog({
            id: log?.id,
            callback: () => {
              navigate("/student/monthly-document", {
                replace: true,
              });
            },
          })
        );
      }
    });
  };

  const onSubmit = async (values, onSubmitProps) => {
    const supervisor = JSON.parse(values.supervisor)

    // If user add new supervisor we need to create that record to use for trainee_supervisor foreign key
    let createdSupervisorId = null
    if (addNewSupervisor) {
      createdSupervisorId = await createNewSupervisorForTrainee(values.newSupervisor)
    }

    const selected = values.task;
    const newValues = {
      ...values,
      task: selected.map((t) => taskIds[taskNames.indexOf(t)]),
    };

    newValues["supervisor"] = supervisor?.supervisor?.user || null
    newValues["trainee_supervisor"] = supervisor?.id

    if (addNewSupervisor) {
      newValues["supervisor"] = null
      newValues["trainee_supervisor"] = createdSupervisorId
    }

    if (values.individual_or_group == "No supervision") {
      newValues["method_of_supervision"] = null;
      newValues["supervision_start_time"] = null;
      newValues["supervision_end_time"] = null;
      newValues["supervised_hours"] = null;
      newValues["independant_hours"] = null;
      newValues["client_observation"] = null;
      newValues["supervision_contact"] = null;
    }

    dispatch(
      editExpLog({
        id: log?.id,
        log: newValues,
      })
    );
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit,
    validate: validateLogHourForm,
  });

  const logCreateSuccess = useSuccess();
  const logCreateErrors = useErrors();

  useEffect(() => {
    if (logCreateSuccess) {
      toast.success("Successfully updated!");
      dispatch(setSuccess(false));
      navigate(-1);
    }
  }, [logCreateSuccess]);

  useEffect(() => {
    if (logCreateErrors && Array.isArray(logCreateErrors))
      logCreateErrors.forEach((err) => {
        toast.error(err);
      });
  }, [logCreateErrors]);

  useEffect(() => {
    if (log?.data) {
      const flaggedItems = new Map();
      Object.keys(log?.data).forEach((key) => {
        if (Object.keys(LABELS).includes(key)) flaggedItems.set(key, true);
      });
      setFlagItems(flaggedItems);
    }
  }, []);


  if (isLoading) return <LoadingSpinner style={{ width: "100%" }} />;

  return (
    <div className="section">
      <div className="columns ">
        <div className="column">
          <button
            className="Toggle_btn"
            onClick={() => dispatch(setShowSidebar(!showSidebar))}
          >
            {showSidebar ? <GiHamburgerMenu /> : <GiHamburgerMenu />}
          </button>

          <p
            className="headingMain headMain"
            style={{ overflowWrap: "break-word" }}
          >
            Edit Log
          </p>
          <div className="loghourDiv">
            <form onSubmit={formik.handleSubmit} className="logHourForm">
              <div className="column mt30 is-five-fifths logForm">
                <label className="label">Experience Type</label>
                <select
                  name="expereince_type"
                  className="select dropdownCustom mt is-normal"
                  value={formik.values.expereince_type}
                  onChange={formik.handleChange}
                >
                  <option value={0} disabled>
                    Select
                  </option>
                  {expTypes.map((item) => (
                    <option value={Number(item.id)} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <span className="Register__error">
                  {(formik.touched.expereince_type &&
                      formik.errors.expereince_type) ||
                    ""}
                </span>

                <label className="label">Setting</label>

                <select
                  name="setting"
                  className="select dropdownCustom mt is-normal"
                  value={formik.values.setting}
                  onChange={formik.handleChange}
                >
                  <option value={0} disabled>
                    Select
                  </option>
                  {setting.map((item) => (
                    <option value={Number(item.id)} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <span className="Register__error">
                  {(formik.touched.setting && formik.errors.setting) || ""}
                </span>

                <label className="label justify-between log__supervisor">Supervisor
                  <div>
                    <button onClick={() => setAddNewSupervisor(!addNewSupervisor)} type="button">
                      { !addNewSupervisor ? '+ Add new' : 'Select' }
                    </button>
                  </div>
                </label>
                { !addNewSupervisor
                  ? <select
                    name="supervisor"
                    className="select dropdownCustom mt is-normal"
                    value={formik.values.supervisor}
                    onChange={formik.handleChange}
                  >
                    <option value={0} disabled>
                      Select
                    </option>
                    {supervisors.map((item) => (
                      <option value={JSON.stringify(item)} key={item.id}>
                        {item.name + ' ' + item.lastname}
                      </option>
                    ))}
                  </select>
                  : <input
                    className="input is-small"
                    type="text"
                    placeholder="New Supervisor Name"
                    name="newSupervisor"
                    value={formik.values.newSupervisor}
                    onChange={formik.handleChange}
                  />
                }
                <span className="Register__error">
                  {(formik.touched.supervisor && formik.errors.supervisor) ||
                    ""}
                </span>

                <label className="label">Date of Experience</label>
                <input
                  className="input is-small"
                  type="date"
                  placeholder={formik.values.date_of_experience}
                  name="date_of_experience"
                  value={formik.values.date_of_experience}
                  onChange={formik.handleChange}
                />
                <span className="Register__error">
                  {(formik.touched.date_of_experience &&
                      formik.errors.date_of_experience) ||
                    ""}
                </span>

                <label className="label">Time of Experience Start</label>
                <input
                  className="input is-small"
                  type="time"
                  placeholder=" "
                  name="time_of_expereince_start"
                  value={formik.values.time_of_expereince_start}
                  onChange={formik.handleChange}
                />
                <span className="Register__error">
                  {(formik.touched.time_of_expereince_start &&
                      formik.errors.time_of_expereince_start) ||
                    ""}
                </span>
                <label className="label">Time of Experience End</label>
                <input
                  className="input is-small"
                  type="time"
                  placeholder=" "
                  name="time_of_expereince_end"
                  value={formik.values.time_of_expereince_end}
                  onChange={formik.handleChange}
                />
                <span className="Register__error">
                  {(formik.touched.time_of_expereince_end &&
                      formik.errors.time_of_expereince_end) ||
                    ""}
                </span>

                <label className="label">
                  Unrestricted Hours <Tooltip content={UNRESTRICTED_HELP} />
                </label>
                <input
                  className="input is-small"
                  type="number"
                  placeholder="Type here ..."
                  name="unrestricted_hours"
                  value={formik.values.unrestricted_hours}
                  onChange={formik.handleChange}
                />
                <span className="Register__error">
                  {(formik.touched.unrestricted_hours &&
                      formik.errors.unrestricted_hours) ||
                    ""}
                </span>

                <label className="label">
                  Restricted Hours <Tooltip content={RESTRICTED_HELP} />
                </label>
                <input
                  className="input is-small"
                  type="number"
                  placeholder="Type here ..."
                  name="restricted_hours"
                  value={formik.values.restricted_hours}
                  onChange={formik.handleChange}
                />
                <span className="Register__error">
                  {(formik.touched.restricted_hours &&
                      formik.errors.restricted_hours) ||
                    ""}
                </span>

                <label className="label">Experience Hours </label>
                <input
                  className="input is-small"
                  type="number"
                  placeholder="Type here ..."
                  name="experience_hours"
                  value={formik.values.experience_hours}
                  onChange={formik.handleChange}
                />
                <span className="Register__error">
                  {(formik.touched.unrestricted_hours &&
                      formik.errors.unrestricted_hours &&
                      formik.touched.restricted_hours &&
                      formik.touched.restricted_hours) ||
                    ""}
                </span>

                <label className="label">
                  Individual <Tooltip content={INDEPENDENT_HELP} /> or group
                  supervision <Tooltip content={GROUP_HELP} /> ?
                </label>

                <select
                  name="individual_or_group"
                  className="select dropdownCustom mt is-normal"
                  value={formik.values.individual_or_group}
                  onChange={formik.handleChange}
                >
                  <option value={""} disabled>
                    Select
                  </option>
                  <option value="Individual">Individual</option>
                  <option value="Group">Group</option>
                  <option value="No supervision">No supervision</option>
                </select>
                <span className="Register__error">
                  {(formik.touched.individual_or_group &&
                      formik.errors.individual_or_group) ||
                    ""}
                </span>

                {formik.values.individual_or_group ===
                "No supervision" ? null : (
                  <>
                    <label className="label">Method of Supervision</label>

                    <select
                      name="method_of_supervision"
                      className="select dropdownCustom mt is-normal"
                      value={formik.values.method_of_supervision}
                      onChange={formik.handleChange}
                    >
                      <option value={""} disabled>
                        Select
                      </option>
                      <option value="In Person" key="In Person">
                        In Person
                      </option>
                      <option value="Virtual" key="Virtual">
                        Virtual
                      </option>
                    </select>

                    <span className="Register__error">
                      {(formik.touched.method_of_supervision &&
                          formik.errors.method_of_supervision) ||
                        ""}
                    </span>

                    <label className="label">Supervision Start Time</label>
                    <input
                      className="input is-small"
                      type="time"
                      placeholder=" "
                      name="supervision_start_time"
                      value={formik.values.supervision_start_time}
                      onChange={formik.handleChange}
                    />
                    <span className="Register__error">
                      {(formik.touched.supervision_start_time &&
                          formik.errors.supervision_end_time) ||
                        ""}
                    </span>

                    <label className="label">Supervision End Time</label>
                    <input
                      className="input is-small"
                      type="time"
                      placeholder=" "
                      name="supervision_end_time"
                      value={formik.values.supervision_end_time}
                      onChange={formik.handleChange}
                    />
                    <span className="Register__error">
                      {(formik.touched.supervision_end_time &&
                          formik.errors.supervision_end_time) ||
                        ""}
                    </span>

                    <label className="label">Supervised Hours </label>
                    <input
                      className="input is-small"
                      type="number"
                      placeholder="Type here ..."
                      name="supervised_hours"
                      value={formik.values.supervised_hours}
                      onChange={formik.handleChange}
                    />
                    <span className="Register__error">
                      {(formik.touched.supervised_hours &&
                          formik.errors.supervised_hours) ||
                        ""}
                    </span>

                    <label className="label">
                      Independent Hours
                      <Tooltip content={INDEPENDENT_HELP} />{" "}
                    </label>
                    <input
                      className="input is-small"
                      type="number"
                      placeholder="Type here ..."
                      name="independant_hours"
                      value={formik.values.independant_hours}
                      onChange={formik.handleChange}
                    />
                    <span className="Register__error">
                      {(formik.touched.independant_hours &&
                          formik.errors.independant_hours) ||
                        ""}
                    </span>

                    <label className="label">
                      Client Observation?
                      <Tooltip content={OBSERVATION_HELP} />
                    </label>

                    <select
                      name="client_observation"
                      className="select dropdownCustom mt is-normal"
                      value={formik.values.client_observation}
                      onChange={formik.handleChange}
                    >
                      <option value={""} disabled>
                        Select
                      </option>
                      <option value="Yes" key="yes">
                        Yes
                      </option>
                      <option value="No" key="no">
                        No
                      </option>
                    </select>

                    <span className="Register__error">
                      {(formik.touched.client_observation &&
                          formik.errors.client_observation) ||
                        ""}
                    </span>

                    <label className="label">Supervision contacts</label>
                    <select
                      name="supervision_contact"
                      className="select dropdownCustom mt is-normal"
                      value={formik.values.supervision_contact}
                      onChange={formik.handleChange}
                    >
                      <option value={0} disabled>
                        Select
                      </option>
                      {contacts.map((item) => (
                        <option value={Number(item.id)} key={item.id}>
                          {item.contact}
                        </option>
                      ))}
                    </select>
                    <span className="Register__error">
                      {(formik.touched.supervision_contact &&
                          formik.errors.supervision_contact) ||
                        ""}
                    </span>
                  </>
                )}

                <label className="label">Task list items documented</label>

                <MultipleSelect
                  name="task"
                  value={formik.values.task}
                  handleChange={formik.handleChange}
                  items={taskNames}
                  description={taskDescriptions}
                />

                <span className="Register__error">
                  {(formik.touched.task && formik.errors.task) || ""}
                </span>

                <label className="label">Summary of Supervision Activity</label>
                <textarea
                  className="textarea"
                  placeholder="Type here..."
                  name="experience_note"
                  value={formik.values.experience_note}
                  onChange={formik.handleChange}
                ></textarea>
                <span className="Register__error">
                  {(formik.touched.experience_note &&
                    formik.errors.experience_note) ||
                    ""}
                </span>

                <div
                  className="mt30"
                  style={{ display: "flex", flexWrap: "nowrap", gap: 10 }}
                >
                 <button
                    className="button is-two-fifths is-rounded dashBtn"
                    type="submit"
                  >
                    Edit
                  </button>
                  <button
                    className="button is-two-fifths is-rounded dashBtn"
                    onClick={onDelete}
                    type="button"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </form>

            <MonthCard />
          </div>
        </div>
      </div>
    </div>
  );
};

const MonthCard = () => {
  const checks = useMonthlyChecks();

  useDispatchEffect(fetchMonthlyCheck, null, checks === null);

  return (
    <div className="mt30 month-card">
      <div className="month">{moment(new Date()).format("MMMM YYYY")}</div>
      <Stack width="100%" direction="row">
        <div className="header">Supervised: 2,000</div>
        <div className="header">Concentrated: 1,500</div>
      </Stack>
      <Stack width="100%" direction="row">
        <Stack width="50%" spacing={2} p={3} borderRight="1px solid #B7B7B7">
          <CheckItem
            label="Minimum 20 Total Hours"
            checked={checks?.min_hours > 20}
          />
          <CheckItem
            label="Maximum 130 Total Hours"
            checked={checks?.max_hours < 130}
          />
          <CheckItem label="Minimum 4 Total Contracts" />
          <CheckItem
            label="Minimum 1 Observation with Client"
            checked={checks?.min_observation >= 1}
          />
          <CheckItem
            label="Minimum 5% Supervision of Total Hours"
            checked={checks?.five_percent_of_total_hours >= 5}
          />
          <CheckItem
            label="Minimum 50% Individual of Total Supervision Hours"
            checked={checks?.fifty_percent_individual >= 50}
          />
          <CheckItem
            label="Maximum 50% Group of Total Supervision Hours"
            checked={checks?.fifty_percent_group <= 50}
          />
        </Stack>
        <Stack width="50%" spacing={2} p={3}>
          <CheckItem
            label="Minimum 20 Total Hours"
            checked={checks?.min_hours > 20}
          />
          <CheckItem
            label="Maximum 130 Total Hours"
            checked={checks?.max_hours < 130}
          />
          <CheckItem label="Minimum 4 Total Contracts" />
          <CheckItem
            label="Minimum 1 Observation with Client"
            checked={checks?.min_observation >= 1}
          />
          <CheckItem
            label="Minimum 5% Supervision of Total Hours"
            checked={checks?.five_percent_of_total_hours >= 5}
          />
          <CheckItem
            label="Minimum 50% Individual of Total Supervision Hours"
            checked={checks?.fifty_percent_individual >= 50}
          />
          <CheckItem
            label="Maximum 50% Group of Total Supervision Hours"
            checked={checks?.fifty_percent_group <= 50}
          />
        </Stack>
      </Stack>
    </div>
  );
};

const CheckItem = ({ checked, label }) => {
  return (
    <Stack
      direction="row"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <CheckCircleIcon
        sx={{
          color: checked ? "#D37498" : "#FFF",
          fontSize: "20px",
          mr: "16px",
        }}
      />
      <Typography
        className="check-label"
        style={{ color: checked ? "#D37498" : "#939597" }}
      >
        {label}
      </Typography>
    </Stack>
  );
};

export default EditLog;
