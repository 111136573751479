import React, { useEffect, useState } from "react";
import {
  useCsvData,
  useIsLoading,
  useFormData,
} from "../redux/reducers/ExpLogReducer";
import { fetchCsvData } from "../redux/sagas/experienceLog/fetchCsvSaga";
import { useDispatchEffect } from "../utils/hooks";
import MultipleSelect from "../components/MultipleSelect";
import { useFormik } from "formik";
import FormControl from "@mui/material/FormControl";
import { useDispatch } from "react-redux";
import { fetchStaticData } from "../redux/sagas/experienceLog/formDataSaga";

const headers = [
  { key: "id", label: "id" },
  { key: "expereince_type.name", label: "expereince_type" },
  { key: "setting.name", label: "setting" },
  { key: "method_of_supervision.name", label: "method_of_supervision" },
  { key: "client_observation.name", label: "client_observation" },
  { key: "supervision_contact.contact", label: "supervision_contact" },
  { key: "task.name", label: "task" },
  { key: "supervisor.name", label: "supervisor" },
  { key: "date_of_experience", label: "date_of_experience" },
  // { key: "time_of_experience" , label: "time_of_experience"  },
  { key: "time_of_expereince_start", label: "time_of_expereince_start" },
  { key: "time_of_expereince_end", label: "time_of_expereince_end" },
  { key: "unrestricted_hours", label: "unrestricted_hours" },
  { key: "restricted_hours", label: "restricted_hours" },
  { key: "experience_hours", label: "experience_hours" },
  { key: "individual_or_group", label: "individual_or_group" },
  { key: "supervision_start_time", label: "supervision_start_time" },
  { key: "supervision_end_time", label: "supervision_end_time" },
  { key: "supervised_hours", label: "supervised_hours" },
  { key: "independant_hours", label: "independant_hours" },
  { key: "experience_note", label: "experience_note" },
  { key: "status", label: "status" },
];

export default function CSVDownloadBtn() {
  const isLoading = useIsLoading();
  const [all, setAll] = useState(true);
  const [mounted, setMounted] = useState(true);
  const [initialPayload, setInitialPayload] = useState({
    tasks: [],
    from_date: null,
    to_date: null
  });

  const data = useFormData();
  let { tasks } = data;

  let taskNames = tasks?.map((t) => t.name.split(" ")[0]);
  let taskDescriptions = tasks?.map((t) => t.name.split(/^(\D*\d)(.*)$/)[2]);
  let taskIds = tasks?.map((t) => t.id);

  useDispatchEffect(fetchStaticData);


  const initialValues = {
    task: [],
    from_date: null,
    to_date: null,
  };

  const downloadFile = (linkURL) => {
    var link = document.createElement("a");
    link.download = "Experience logs.csv";
    link.href = linkURL;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const dispatch = useDispatch();

  const onSubmit = (values, onSubmitProps) => {
    setMounted(false);
    let res = [];
    const from_date = formik.values.from_date;
    const to_date = formik.values.to_date;
    const payload = {
      tasks: res,
      from_date: from_date || null,
      to_date: to_date || null
    };

    if (all) {
      dispatch(fetchCsvData(payload))
    } else {
      const selected = formik.values.task;

      res = selected.map((t) => taskIds[taskNames.indexOf(t)]);
      payload.tasks = res;
      dispatch(fetchCsvData(payload))
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const csvData = useCsvData();
  const [downloadLink, setDownloadLink] = useState("");


  // function for generating file and set download link
  const makeTextFile = () => {
    const data = new Blob([csvData], { type: "text/plain" });

    if (downloadLink !== "") window.URL.revokeObjectURL(downloadLink);

    const linkURL =  window.URL.createObjectURL(data)
    setDownloadLink(linkURL);

    if (!mounted) downloadFile(linkURL);
  };

  useEffect(() => {
    makeTextFile();
  }, [csvData]);

  if (!isLoading)
    return (
      <>
        <form onSubmit={formik.handleSubmit}>
          <div
            style={{
              display: "flex",
              margin: "2rem 0",
              flexDirection: "column",
            }}
          >
            <div style={{ width: "100%" }}>
              <MultipleSelect
                name="task"
                value={formik.values.task}
                handleChange={formik.handleChange}
                items={taskNames}
                description={taskDescriptions}
                disabled={all}
              />
            </div>
            <div style={{ width: "100%" }}>
              <div className="task_data__date-container">
                <FormControl sx={{ width: "100%", p: 0, mt: "10px" }}>
                  <input
                    className="input "
                    type="date"
                    placeholder={formik.values.from_date}
                    name="from_date"
                    value={formik.values.from_date}
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <span> - </span>
                <FormControl sx={{ width: "100%", p: 0, mt: "10px" }}>
                  <input
                    className="input "
                    type="date"
                    placeholder={formik.values.to_date}
                    name="to_date"
                    value={formik.values.to_date}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </div>
            </div>
            <div>
              <label className="checkbox" style={{ margin: "1rem 10px" }}>
                <input
                  type="checkbox"
                  style={{ marginRight: "5px" }}
                  checked={all}
                  onChange={(e) => setAll(e.target.checked)}
                />
                Select all tasks
              </label>
            </div>
          </div>
          <button
            type="submit"
            className="button is-two-fifths is-rounded dashBtn mt30"
          >
            <b> {isLoading ? "Loading ..." : "Download CSV"}</b>
          </button>
        </form>
      </>
    );
  else return null;
}
