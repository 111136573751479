import { useMonthlyChecks } from "../../redux/reducers/ExpLogReducer";
import { useDispatchEffect } from "../../utils/hooks";
import { fetchMonthlyCheck } from "../../redux/sagas/experienceLog/fetchMonthlyCheckSaga";
import moment from "moment";
import { Stack, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect, useState } from "react";

const MonthCard = ({ values, expHours }) => {
  const checks = useMonthlyChecks();

  const SUPERVISED_FIELDWORK_HOURS = 2000
  const CONCENTRATED_FIELDWORK_HOURS = 1500

  const [supervised, setSupervised] = useState({});
  const [concentrated, setConcentrated] = useState({});
  const [validations, setValidations] = useState({
    total_hours: 0,
    total_observation: 0,
    total_supervised: 0,
    total_supervision_contacts: 0,
    group_supervised: 0,
    individual_supervised: 0,
    total_unrestricted_hours: 0,
  });

  const [isSupervised, setIsSupervised] = useState(false);
  const [isConcentrated, setIsConcentrated] = useState(false);
  const [isCurrentMonth, setIsCurrentMonth] = useState(true);
  const today = moment()

  useEffect(() => {
    if (checks != null) {
      setSupervised(checks.supervised)
      setConcentrated(checks.concentrated)
    }
  }, [checks]);

  useEffect(() => {
    const selectedDate = moment(values.date_of_experience)
    setIsCurrentMonth(selectedDate.month() == today.month())

    const supervisedCalc = {}
    supervisedCalc.total_hours = supervised.total_hours + expHours
    supervisedCalc.total_supervision_contacts = supervised.total_supervision_contacts + (values.supervision_contact == 1 ? 1: 0)
    supervisedCalc.total_observation = supervised.total_observation + (values.client_observation == "Yes" ? 1: 0)
    supervisedCalc.total_supervised = supervised.total_supervised + values.supervised_hours
    supervisedCalc.total_unrestricted_hours = supervised.total_unrestricted_hours + values.unrestricted_hours
    supervisedCalc.individual_supervised = supervised.individual_supervised
    supervisedCalc.group_supervised = supervised.group_supervised

    if (values.individual_or_group == 'Individual') {
      supervisedCalc.individual_supervised += values.supervised_hours
    } else if (values.individual_or_group == 'Group') {
      supervisedCalc.group_supervised += values.supervised_hours
    }

    const concentratedCalc = {}
    concentratedCalc.total_hours = concentrated.total_hours + expHours
    concentratedCalc.total_supervision_contacts = concentrated.total_supervision_contacts + (values.supervision_contact == 1 ? 1: 0)
    concentratedCalc.total_observation = concentrated.total_observation + (values.client_observation == "Yes" ? 1: 0)
    concentratedCalc.total_supervised = concentrated.total_supervised + values.supervised_hours
    concentratedCalc.total_unrestricted_hours = concentrated.total_unrestricted_hours + values.unrestricted_hours
    concentratedCalc.individual_supervised = concentrated.individual_supervised
    concentratedCalc.group_supervised = concentrated.group_supervised

    if (values.individual_or_group == 'Individual') {
      concentratedCalc.individual_supervised += values.supervised_hours
    } else if (values.individual_or_group == 'Group') {
      concentratedCalc.group_supervised += values.supervised_hours
    }


    if (values.expereince_type == 1) {
      setIsSupervised(true)
      setIsConcentrated(false)
      setValidations(supervisedCalc)
    } else if (values.expereince_type == 2) {
      setIsSupervised(false)
      setIsConcentrated(true)
      setValidations(concentratedCalc)
    }

  }, [values, supervised, concentrated, expHours]);




  useDispatchEffect(fetchMonthlyCheck, null, checks === null);

  return (
    <div className="mt30 month-card">
      <div className="month">{moment(new Date()).format("MMMM YYYY")}</div>
      <Stack width="100%" direction="row">
        <div className="header">Supervised: 2,000</div>
        <div className="header">Concentrated: 1,500</div>
      </Stack>
      <Stack width="100%" direction="row">
        <Stack width="50%" spacing={2} p={3} borderRight="1px solid #B7B7B7">
          <CheckItem
            label="Minimum 20 Total Hours"
            checked={validations.total_hours >= 20}
            selected={isSupervised && isCurrentMonth}
          />
          <CheckItem
            label="Maximum 130 Total Hours"
            checked={validations.total_hours <= 130}
            selected={isSupervised && isCurrentMonth}
          />
          <CheckItem
            label="Minimum 4 Total Contacts"
            checked={validations.total_supervision_contacts >= 4}
            selected={isSupervised && isCurrentMonth} />
          <CheckItem
            label="Minimum 1 Observation with Client"
            checked={validations.total_observation >= 1}
            selected={isSupervised && isCurrentMonth}
          />
          <CheckItem
            label="Minimum 5% Supervision of Total Hours"
            checked={((validations.total_supervised/validations.total_hours)*100) >= 5}
            selected={isSupervised && isCurrentMonth}
          />
          <CheckItem
            label="Minimum 50% Individual of Total Supervision Hours"
            checked={((validations.individual_supervised/validations.total_supervised)*100) >= 50}
            selected={isSupervised && isCurrentMonth}
          />
          <CheckItem
            label="Maximum 50% Group of Total Supervision Hours"
            checked={((validations.group_supervised/validations.total_supervised)*100) <= 50}
            selected={isSupervised && isCurrentMonth}
          />
          <CheckItem
            label="Minimum 60% Unrestricted Activities"
            checked={((validations.total_unrestricted_hours/SUPERVISED_FIELDWORK_HOURS)*100) >= 60}
            selected={isSupervised && isCurrentMonth}
          />
        </Stack>
        <Stack width="50%" spacing={2} p={3}>
          <CheckItem
            label="Minimum 20 Total Hours"
            checked={validations.total_hours >= 20}
            selected={isConcentrated && isCurrentMonth}
          />
          <CheckItem
            label="Maximum 130 Total Hours"
            checked={validations.total_hours <= 130}
            selected={isConcentrated && isCurrentMonth}
          />
          <CheckItem
            label="Minimum 6 Total Contacts"
            checked={validations.total_supervision_contacts >= 6}
            selected={isConcentrated && isCurrentMonth}
          />
          <CheckItem
            label="Minimum 1 Observation with Client"
            checked={validations.total_observation >= 1}
            selected={isConcentrated && isCurrentMonth}
          />
          <CheckItem
            label="Minimum 10% Supervision of Total Hours"
            checked={((validations.total_supervised/validations.total_hours)*100) >= 10}
            selected={isConcentrated && isCurrentMonth}
          />
          <CheckItem
            label="Minimum 50% Individual of Total Supervision Hours"
            checked={((validations.individual_supervised/validations.total_supervised)*100) >= 50}
            selected={isConcentrated && isCurrentMonth}
          />
          <CheckItem
            label="Maximum 50% Group of Total Supervision Hours"
            checked={((validations.group_supervised/validations.total_supervised)*100) <= 50}
            selected={isConcentrated && isCurrentMonth}
          />
          <CheckItem
            label="Minimum 60% Unrestricted Activities"
            checked={((validations.total_unrestricted_hours/CONCENTRATED_FIELDWORK_HOURS)*100) >= 60}
            selected={isConcentrated && isCurrentMonth}
          />
        </Stack>
      </Stack>
    </div>
  );
};


const CheckItem = ({ checked, selected, label }) => {
  return (
    <Stack
      direction="row"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Typography
        className="check-label"
        style={{ color: checked && selected ? "#44de46" : selected ? '#d24848' :  "#939597" }}
      >
        {label}
      </Typography>
    </Stack>
  );
};

export default MonthCard
