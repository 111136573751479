import { createAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import {  setSuccess } from "../../reducers/ProfileReducer";
import { toast } from "react-toastify";

export const sendInvitation = createAction("profile/sendInvitation");

function* postSendInvitation({ payload }) {
  const { form } = payload
  try {
    let res = yield call(ApiService.postSendInvitation, form);
    yield put(setSuccess(true))
    toast.success('Invitation Sent!');
  } catch (error) {
    console.error('postSupervisors: ', error);
    yield put(setSuccess(false))
    toast.error(error);
  }
}

export function* sendInvitationSaga() {
  yield takeLatest(sendInvitation, postSendInvitation);
}
