import { createAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { setTasks, startFetching, setError } from "../../reducers/MonthlyDocReducer";

export const fetchTasks = createAction("monthlyDoc/fetchTasks");

function* fetchData() {
  yield put(startFetching(true));
  try {
    let res = yield call(ApiService.getTraineeTasks);
    if(res.data)
        yield put(setTasks(res.data.results));
  } catch (error) {
    console.log({ error });
    yield put(setError(error))
  }
}

export function* fetchTasksSaga() {
  yield takeLatest(fetchTasks, fetchData);
}

