import { createAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { startFetching, setRecordFiles, setError } from "../../reducers/RecordFileReducer";

export const fetchFiles = createAction("recordFile/fetchFiles");

function* fetchRecordFiles() {
  yield put(startFetching(true))
  try {
    let res = yield call(ApiService.getRecordFiles);
    yield put(setRecordFiles(res.data));
  } catch (error) {
    console.log({ error });
    yield put(setError(error))
  }
}

export function* fetchRecordFilesSaga() {
  yield takeLatest(fetchFiles, fetchRecordFiles);
}
