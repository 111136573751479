import React from "react";
import { Chart, Tooltip, Title, ArcElement, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

Chart.register(Tooltip, Title, ArcElement, Legend);

export default function DoughnutChartConcentrated(props) {
  let { label, data, backgroundColor, hoverOffset, width, height, nonConcentrated, concentrated } = props;
  let difference = 2000 - data[0];
  data = [data[0], difference]

  return (
    <div style={{ position: "relative" }}>
      <Doughnut
        {...props}
        style={{ width, height }}
        data={{
          datasets: [
            {
              label,
              data,
              backgroundColor,
              hoverOffset,
              spacing: 0,
            },
          ],
        }}
      />
      {/* <div className="percent-value">{(data[0] * 100) / data[0]}%</div> */}
      <div className="percent-value">
        {parseFloat(((concentrated + nonConcentrated)/2000 * 100)).toFixed(1)}%
      </div>
    </div>
  );
}
