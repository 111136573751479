import { createAction } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { setCouponDetails, setPaymentSuccess, setSuccess, startFetching,  } from "../../reducers/SubscriptionReducer";
import {setIsSubscriptionExpired} from "../../reducers/AuthReducer";

export const addSubscription = createAction("subscription/addSubscription");

function* addSub({ payload }) {
  console.log("payload: ", payload);
  yield put(startFetching(true))
  try {
    let res = yield call(ApiService.addSubscription, payload);
    console.log('addSub res: ', res.data);
    if(res.data.msg === 'Successfully Charged for Subscription') {
      toast.success(res.data.msg);
      yield put(setIsSubscriptionExpired(false))
      yield put(setSuccess(true))
    } else {
        toast.error(res.data.error);
        yield put(setSuccess(false))
    }
  } catch (error) {
    console.log({ error });
    const errors = Object.values(error?.response?.data ?? {});
    toast.error(errors.join(error));
    yield put(setSuccess(false))
  }
}
export function* addSubscritionSaga() {
  yield takeLatest(addSubscription, addSub)
}

export const updateSubscription = createAction("subscription/updateSubscription");

function* updateSub({ payload }) {
  console.log("payload: ", payload);
  yield put(startFetching(true))
  try {
    let res = yield call(ApiService.updateSubscription, payload);
    console.log('updateSub res: ', res.data);
    if(res.data.msg === 'Successfully Updated Subscription') {
      toast.success(res.data.msg);
      yield put(setSuccess(true))
      yield put(setPaymentSuccess(true))

    } else {
        toast.error(res.data.error);
        yield put(setSuccess(false))
      yield put(setPaymentSuccess(false))

    }
  } catch (error) {
    console.log({ error });
    const errors = Object.values(error?.response?.data ?? {});
    toast.error(errors.join(error));
    yield put(setSuccess(false))
    yield put(setPaymentSuccess(false))

  }
}
export function* updateSubscritionSaga() {
  yield takeLatest(updateSubscription, updateSub)
}


export const deleteSubscription = createAction("subscription/deleteSubscription");

function* deleteSub({ payload }) {
  console.log("payload: ", payload);
  yield put(startFetching(true))
  try {
    let res = yield call(ApiService.deleteSubscription, payload);
    console.log('addSub res: ', res.data);
    if(res.data.msg === 'Successfully Canceled Subscription') {
      toast.success(res.data.msg);
      yield put(setSuccess(true))
    } else {
      toast.error(res.data.error);
      yield put(setSuccess(false))
    }
  } catch (error) {
    console.log({ error });
    const errors = Object.values(error?.response?.data ?? {});
    toast.error(errors.join(error));
    yield put(setSuccess(false))
  }
}
export function* deleteSubscriptionSaga() {
  yield takeLatest(deleteSubscription, deleteSub)
}

export const updatePaymentSuccess = createAction("subscription/updatePaymentSuccess");

function* updatePayment({ payload }) {
  yield put(setPaymentSuccess(payload?.status))

}

export function* updatePaymentSuccessSaga() {
  yield takeLatest(updatePaymentSuccess, updatePayment)
}

export const updateCouponDetails = createAction("subscription/updateCouponDetails");

function* updateCoupon({ payload }) {
  yield put(setCouponDetails(payload?.coupon))

}

export function* updateCouponDetailsSaga() {
  yield takeLatest(updateCouponDetails, updateCoupon)
}