import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { GiConsoleController, GiHamburgerMenu } from "react-icons/gi";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/LoadingSpinner";
import MultipleSelect from "../../../components/MultipleSelect";
import MonthCard from "../../../components/ExperienceLog/MonthCard";
import MonthCardSupervised from "../../../components/ExperienceLog/MonthCardSupervised";
import MonthCardConcentrated from "../../../components/ExperienceLog/MonthCardConcentrated";
import { fetchMonthlyCheck } from "../../../redux/sagas/experienceLog/fetchMonthlyCheckSaga";
import Tooltip from "../../../components/Tooltip";
import {
  setSuccess,
  useErrors,
  useFormData,
  useIsLoading,
  useMonthlyChecks,
  useSuccess,
} from "../../../redux/reducers/ExpLogReducer";
import {
  setShowSidebar,
  useShowSidebar,
} from "../../../redux/reducers/SidebarReducer";
import { fetchStaticData } from "../../../redux/sagas/experienceLog/formDataSaga";
import { submitExpLog } from "../../../redux/sagas/experienceLog/submitFormSaga";
import {
  GROUP_HELP,
  INDEPENDENT_HELP,
  OBSERVATION_HELP,
  RESTRICTED_HELP,
  UNRESTRICTED_HELP,
  EXPERIENCE_TYPE_HELP,
  SETTING_HELP,
  SUPERVISOR_HELP,
  DATE_OF_EXPERIENCE_HELP,
  TIME_IN_HELP,
  TIME_OUT_HELP,
  SUP_START_HELP,
  SUP_END_HELP,
  TASK_LIST_ITEM_HELP,
  METHOD_OF_SUP_HELP,
  EXPERIENCE_HRS_HELP,
  SUPERVISION_CONTACT_HELP,
  SUMMARY_HELP,
  INDEPENDENT_HOURS_HELP,
  SUMMARY_HELP_ARRAY,
} from "../../../utils/CONSTANTS";
import { useDispatchEffect } from "../../../utils/hooks";
import { validateLogHourForm } from "../../../utils/validation";
import { useNavigate } from "react-router-dom";
import "./logHour.css";
import React from "react";
import { useSupervisors } from "../../../redux/reducers/ProfileReducer";
import {
  fetchSupervisors,
  submitTraineeSupervisor,
} from "../../../redux/sagas/profile/supervisorSaga";
import ApiService from "../../../services/ApiService";
import CustomModal from "../../../components/CustomModal/CustomModal";
import Confirmation from "../../../components/Confirmation/Confirmation";
import LocalStorage from "../../../services/LocalStorage";
import LoginConfirmationPopup from "../../../components/LoginConfirmationPopup/LoginConfirmationPopup";

const LogHour = () => {
  const navigate = useNavigate();
  const data = useFormData();
  const isLoading = useIsLoading();
  let { expTypes, setting, obsevations, methods, contacts, tasks } = data;
  const inputRef = useRef(null);
  const supervisors = useSupervisors();
  const [addNewSupervisor, setAddNewSupervisor] = useState(false);
  // tasks.length && tasks.sort((a,b) => (a.id - b.id));
  const taskNames = tasks?.map((t) => t.name.split(" ")[0]);
  const taskDescriptions = tasks?.map((t) => t.name.split(/^(\D*\d)(.*)$/)[2]);

  const taskIds = tasks?.map((t) => t.id);

  useDispatchEffect(fetchStaticData, null, expTypes?.length == 0);
  const refreshSupervisors = useDispatchEffect(fetchSupervisors);

  const [modalVisible, setModalVisible] = useState(false);
  const [loginModalVisible, setLoginModalVisible] = useState(false);

  const initialValues = {
    expereince_type: 0,
    setting: 0,
    method_of_supervision: "",
    client_observation: "",
    supervision_contact: 0,
    task: [],
    supervisor: 0,
    newSupervisor: "",
    newSupervisorEmail: "",
    date_of_experience: moment().format("YYYY-MM-DD"),
    // time_of_experience: "",
    time_of_expereince_start: moment().format("HH:mm"),
    time_of_expereince_end: "",
    unrestricted_hours: 0,
    restricted_hours: 0,
    experience_hours: 0,
    individual_or_group: "",
    supervision_start_time: "",
    supervision_end_time: "",
    supervised_hours: 0,
    independant_hours: 0,
    experience_note: "",
  };

  const dispatch = useDispatch();
  const loginPopup = LocalStorage.getData("loginPopup");

  useEffect(()=>{
if(loginPopup){
    setLoginModalVisible(true)
    LocalStorage.delete("loginPopup")
}
  },[])

  const createNewSupervisorForTrainee = async (
    supervisor,
    newSupervisorEmail
  ) => {
    let name = supervisor;
    let lastname = "";
    if (supervisor.indexOf(" ") > 0) {
      name = supervisor.split(" ")[0];
      const lastnameArr = supervisor.split(" ");
      lastnameArr.shift();
      lastname = lastnameArr.join(" ");
    }

    const form = {
      name,
      lastname,
      email: newSupervisorEmail,
    };

    try {
      const createdUser = await ApiService.postTraineeSupervisor(form);
      // Refresh supervisor list after creating a new one
      refreshSupervisors();
      setAddNewSupervisor(false);
      return createdUser.data.id;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const onSubmit = async (values, onSubmitProps) => {
    const supervisor = JSON.parse(values.supervisor);

    // If user add new supervisor we need to create that record to use for trainee_supervisor foreign key
    let createdSupervisorId = null;
    if (addNewSupervisor) {
      createdSupervisorId = await createNewSupervisorForTrainee(
        values.newSupervisor,
        values.newSupervisorEmail
      );
    }

    let suma =
      formik.values.unrestricted_hours + formik.values.restricted_hours;
    if (suma > 0) {
      formik.values.experience_hours = suma;
    }

    if (formik.values.expereince_type === "2") {
      //is concentrated
      formik.values.experience_hours = formik.values.experience_hours * 1.33;
    }

    const selected = values.task;
    const newValues = {
      ...values,
      task: selected.map((t) => taskIds[taskNames.indexOf(t)]),
    };

    newValues["supervisor"] = supervisor?.supervisor?.user || null;
    newValues["trainee_supervisor"] = supervisor?.id;

    if (addNewSupervisor) {
      newValues["supervisor"] = null;
      newValues["trainee_supervisor"] = createdSupervisorId;
    }

    if (values.individual_or_group == "No supervision") {
      newValues["method_of_supervision"] = null;
      newValues["supervision_start_time"] = null;
      newValues["supervision_end_time"] = null;
      newValues["supervised_hours"] = null;
      newValues["independant_hours"] = null;
      newValues["client_observation"] = null;
      newValues["supervision_contact"] = null;
    }

    dispatch(submitExpLog(newValues));
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate: (values) => validateLogHourForm(values, { addNewSupervisor }),
  });

  const logCreateSuccess = useSuccess();
  const logCreateErrors = useErrors();


  useEffect(() => {
    if (logCreateSuccess) {
      setModalVisible(true);
      dispatch(setSuccess(false));
      formik.resetForm();
    }
  }, [logCreateSuccess]);

  useEffect(() => {
    if (logCreateErrors && Array.isArray(logCreateErrors))
      logCreateErrors.forEach((err) => {
        toast.error(err);
      });
  }, [logCreateErrors]);

  const [sumExpHours, setSumExpHours] = useState(0);
  const [sumResHours, setSumResHours] = useState(0);
  const [sumIndHours, setSumIndHours] = useState(0);
  const [sumSupHours, setSumSupHours] = useState(0);

  useEffect(() => {
    var hours, minutes;

    if (
      formik.values.time_of_expereince_start !== "" &&
      formik.values.time_of_expereince_end !== ""
    ) {
      let startTime = moment(formik.values.time_of_expereince_start, "HH:mm");
      let endTime = moment(formik.values.time_of_expereince_end, "HH:mm");

      var duration = moment.duration(endTime.diff(startTime));

      // duration in hours
      hours = parseInt(duration.asHours());
      // duration in minutes
      minutes = parseInt(duration.asMinutes()) % 60;
      if (minutes === 0) {
        formik.values.experience_hours = hours;
        setSumExpHours(hours);
      } else {
        // HERE
        let decimal = minutes / 60;
        let numDecimal = (Math.round((hours + decimal) * 4) / 4).toFixed(2);
        formik.values.experience_hours = numDecimal;
        setSumExpHours(numDecimal);
      }
    }
    if (formik.values.experience_hours) {
      formik.values.restricted_hours =
        formik.values.experience_hours - formik.values.unrestricted_hours;
      setSumResHours(
        formik.values.experience_hours - formik.values.unrestricted_hours
      );
    }

    if (formik.values.experience_hours) {
      formik.values.independant_hours =
        formik.values.experience_hours - formik.values.supervised_hours;
      setSumIndHours(
        formik.values.experience_hours - formik.values.supervised_hours
      );
    }

    if (
      formik.values.supervision_start_time !== "" &&
      formik.values.supervision_end_time !== ""
    ) {
      let startTime = moment(formik.values.supervision_start_time, "HH:mm");
      let endTime = moment(formik.values.supervision_end_time, "HH:mm");

      var supDuration = moment.duration(endTime.diff(startTime));

      // duration in hours
      hours = parseInt(supDuration.asHours());
      // duration in minutes
      minutes = parseInt(supDuration.asMinutes()) % 60;
      if (minutes === 0) {
        formik.values.supervised_hours = hours;
        setSumSupHours(hours);
      } else {
        // HERE
        let decimal = minutes / 60;
        let numDecimal = (Math.round((hours + decimal) * 4) / 4).toFixed(2);
        formik.values.supervised_hours = numDecimal;
        setSumSupHours(numDecimal);
      }

      if (formik.values.experience_hours) {
        formik.values.independant_hours =
          formik.values.experience_hours - formik.values.supervised_hours;
        setSumIndHours(
          formik.values.experience_hours - formik.values.supervised_hours
        );
      }
    }
  }, [formik.values]);

  const showSidebar = useShowSidebar();

  if (isLoading) return <LoadingSpinner style={{ width: "100%" }} />;

  const handleClickedTooltipData = () => {
    const mappedArray = SUMMARY_HELP_ARRAY?.map((data) => {
      const result = `${data}\nAns:-\n`;
      return result;
    });

    inputRef.current.value = mappedArray.join("");
  };

  return (
    <div className="section">
      <div className="columns ">
        <div className="column">
          <button
            className="Toggle_btn"
            onClick={() => dispatch(setShowSidebar(!showSidebar))}
          >
            {showSidebar ? <GiHamburgerMenu /> : <GiHamburgerMenu />}
          </button>

          <p
            className="headingMain headMain"
            style={{ overflowWrap: "break-word" }}
          >
            Log Experience Hours
          </p>
          <div className="loghourDiv">
            <form onSubmit={formik.handleSubmit} className="logHourForm">
              <div className="column mt30 is-five-fifths logForm">
                <label className="label">
                  Experience Type <Tooltip content={EXPERIENCE_TYPE_HELP} />
                </label>
                <select
                  name="expereince_type"
                  className="select dropdownCustom mt is-normal"
                  value={formik.values.expereince_type}
                  onChange={formik.handleChange}
                >
                  <option value={0} disabled>
                    Select
                  </option>
                  {expTypes.map((item) => (
                    <option value={Number(item.id)} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <span className="Register__error">
                  {(formik.touched.expereince_type &&
                    formik.errors.expereince_type) ||
                    ""}
                </span>

                <label className="label">
                  Setting <Tooltip content={SETTING_HELP} />
                </label>

                <select
                  name="setting"
                  className="select dropdownCustom mt is-normal"
                  value={formik.values.setting}
                  onChange={formik.handleChange}
                >
                  <option value={0} disabled>
                    Select
                  </option>
                  {setting.map((item) => (
                    <option value={Number(item.id)} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <span className="Register__error">
                  {(formik.touched.setting && formik.errors.setting) || ""}
                </span>

                <label className="label justify-between log__supervisor">
                  <div>
                    Supervisor <Tooltip content={SUPERVISOR_HELP} />
                  </div>
                  <div>
                    <button
                      onClick={() => setAddNewSupervisor(!addNewSupervisor)}
                      type="button"
                    >
                      {!addNewSupervisor ? "+ Add new" : "Select"}
                    </button>
                  </div>
                </label>
                {!addNewSupervisor ? (
                  <select
                    name="supervisor"
                    className="select dropdownCustom mt is-normal"
                    value={formik.values.supervisor}
                    onChange={formik.handleChange}
                  >
                    <option value={0} disabled>
                      Select
                    </option>
                    {supervisors.map((item) => (
                      <option value={JSON.stringify(item)} key={item.id}>
                        {item.name + " " + item.lastname}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    className="input is-small"
                    type="text"
                    placeholder="New Supervisor Name"
                    name="newSupervisor"
                    value={formik.values.newSupervisor}
                    onChange={formik.handleChange}
                  />
                )}
                {addNewSupervisor ? (
                  <span className="Register__error">
                    {(formik.touched.newSupervisor &&
                      formik.errors.newSupervisor) ||
                      ""}
                  </span>
                ) : (
                  <span className="Register__error">
                    {(formik.touched.supervisor && formik.errors.supervisor) ||
                      ""}
                  </span>
                )}

                {addNewSupervisor && (
                  <>
                    <label className="label justify-between log__supervisor">
                      Supervisor Email
                    </label>
                    <input
                      className="input is-small"
                      type="text"
                      placeholder="New Supervisor Email"
                      name="newSupervisorEmail"
                      value={formik.values.newSupervisorEmail}
                      onChange={formik.handleChange}
                    />
                    <span className="Register__error">
                      {(formik.touched.newSupervisorEmail &&
                        formik.errors.newSupervisorEmail) ||
                        ""}
                    </span>
                  </>
                )}

                <label className="label">
                  Date of Experience{" "}
                  <Tooltip content={DATE_OF_EXPERIENCE_HELP} />
                </label>
                <input
                  className="input is-small"
                  type="date"
                  placeholder={formik.values.date_of_experience}
                  name="date_of_experience"
                  value={formik.values.date_of_experience}
                  onChange={formik.handleChange}
                />
                <span className="Register__error">
                  {(formik.touched.date_of_experience &&
                    formik.errors.date_of_experience) ||
                    ""}
                </span>

                <label className="label">
                  Time of Experience Start <Tooltip content={TIME_IN_HELP} />
                </label>
                <input
                  className="input is-small"
                  type="time"
                  placeholder=" "
                  name="time_of_expereince_start"
                  value={formik.values.time_of_expereince_start}
                  onChange={formik.handleChange}
                />
                <span className="Register__error">
                  {(formik.touched.time_of_expereince_start &&
                    formik.errors.time_of_expereince_start) ||
                    ""}
                </span>
                <label className="label">
                  Time of Experience End <Tooltip content={TIME_OUT_HELP} />
                </label>
                <input
                  className="input is-small"
                  type="time"
                  placeholder=" "
                  name="time_of_expereince_end"
                  value={formik.values.time_of_expereince_end}
                  onChange={formik.handleChange}
                />
                <span className="Register__error">
                  {(formik.touched.time_of_expereince_end &&
                    formik.errors.time_of_expereince_end) ||
                    ""}
                </span>

                <label className="label">
                  Unrestricted Hours <Tooltip content={UNRESTRICTED_HELP} />
                </label>
                <input
                  className="input is-small"
                  type="number"
                  placeholder="Type here ..."
                  name="unrestricted_hours"
                  value={formik.values.unrestricted_hours}
                  onChange={formik.handleChange}
                />
                <span className="Register__error">
                  {(formik.touched.unrestricted_hours &&
                    formik.errors.unrestricted_hours) ||
                    ""}
                </span>

                <label className="label">
                  Restricted Hours <Tooltip content={RESTRICTED_HELP} />
                </label>
                <input
                  className="input is-small"
                  type="number"
                  placeholder="Type here ..."
                  name="restricted_hours"
                  value={formik.values.restricted_hours}
                  onChange={formik.handleChange}
                />
                <span className="Register__error">
                  {(formik.touched.restricted_hours &&
                    formik.errors.restricted_hours) ||
                    ""}
                </span>

                <label className="label">
                  Experience Hours
                  <Tooltip content={EXPERIENCE_HRS_HELP} />
                </label>
                <input
                  className="input is-small"
                  type="number"
                  placeholder="Type here ..."
                  name="experience_hours"
                  value={sumExpHours}
                  onChange={formik.handleChange}
                />
                <span className="Register__error">
                  {(formik.touched.unrestricted_hours &&
                    formik.errors.unrestricted_hours &&
                    formik.touched.restricted_hours &&
                    formik.touched.restricted_hours) ||
                    ""}
                </span>

                <label className="label">
                  Individual <Tooltip content={INDEPENDENT_HELP} /> or group
                  supervision <Tooltip content={GROUP_HELP} /> ?
                </label>

                <select
                  name="individual_or_group"
                  className="select dropdownCustom mt is-normal"
                  value={formik.values.individual_or_group}
                  onChange={formik.handleChange}
                >
                  <option value={""} disabled>
                    Select
                  </option>
                  <option value="Individual">Individual</option>
                  <option value="Group">Group</option>
                  <option value="No supervision">No supervision</option>
                </select>
                <span className="Register__error">
                  {(formik.touched.individual_or_group &&
                    formik.errors.individual_or_group) ||
                    ""}
                </span>

                {formik.values.individual_or_group ===
                "No supervision" ? null : (
                  <>
                    <label className="label">
                      Method of Supervision
                      <Tooltip content={METHOD_OF_SUP_HELP} />
                    </label>

                    <select
                      name="method_of_supervision"
                      className="select dropdownCustom mt is-normal"
                      value={formik.values.method_of_supervision}
                      onChange={formik.handleChange}
                    >
                      <option value={""} disabled>
                        Select
                      </option>
                      <option value="In Person" key="In Person">
                        In Person
                      </option>
                      <option value="Virtual" key="Virtual">
                        Virtual
                      </option>
                    </select>

                    <span className="Register__error">
                      {(formik.touched.method_of_supervision &&
                        formik.errors.method_of_supervision) ||
                        ""}
                    </span>

                    <label className="label">
                      Supervision Start Time
                      <Tooltip content={SUP_START_HELP} />
                    </label>
                    <input
                      className="input is-small"
                      type="time"
                      placeholder=" "
                      name="supervision_start_time"
                      value={formik.values.supervision_start_time}
                      onChange={formik.handleChange}
                    />
                    <span className="Register__error">
                      {(formik.touched.supervision_start_time &&
                        formik.errors.supervision_end_time) ||
                        ""}
                    </span>

                    <label className="label">
                      Supervision End Time <Tooltip content={SUP_END_HELP} />
                    </label>
                    <input
                      className="input is-small"
                      type="time"
                      placeholder=" "
                      name="supervision_end_time"
                      value={formik.values.supervision_end_time}
                      onChange={formik.handleChange}
                    />
                    <span className="Register__error">
                      {(formik.touched.supervision_end_time &&
                        formik.errors.supervision_end_time) ||
                        ""}
                    </span>

                    <label className="label">Supervised Hours </label>
                    <input
                      className="input is-small"
                      type="number"
                      placeholder="Type here ..."
                      name="supervised_hours"
                      value={formik.values.supervised_hours}
                      onChange={formik.handleChange}
                    />
                    <span className="Register__error">
                      {(formik.touched.supervised_hours &&
                        formik.errors.supervised_hours) ||
                        ""}
                    </span>

                    <label className="label">
                      Independent Hours
                      <Tooltip content={INDEPENDENT_HOURS_HELP} />{" "}
                    </label>
                    <input
                      className="input is-small"
                      type="number"
                      placeholder="Type here ..."
                      name="independant_hours"
                      value={formik.values.independant_hours}
                      onChange={formik.handleChange}
                    />
                    <span className="Register__error">
                      {(formik.touched.independant_hours &&
                        formik.errors.independant_hours) ||
                        ""}
                    </span>

                    <label className="label">
                      Client Observation?
                      <Tooltip content={OBSERVATION_HELP} />
                    </label>

                    <select
                      name="client_observation"
                      className="select dropdownCustom mt is-normal"
                      value={formik.values.client_observation}
                      onChange={formik.handleChange}
                    >
                      <option value={""} disabled>
                        Select
                      </option>
                      <option value="Yes" key="yes">
                        Yes
                      </option>
                      <option value="No" key="no">
                        No
                      </option>
                    </select>

                    <span className="Register__error">
                      {(formik.touched.client_observation &&
                        formik.errors.client_observation) ||
                        ""}
                    </span>

                    <label className="label">
                      Supervision contacts{" "}
                      <Tooltip content={SUPERVISION_CONTACT_HELP} />
                    </label>
                    <select
                      name="supervision_contact"
                      className="select dropdownCustom mt is-normal"
                      value={formik.values.supervision_contact}
                      onChange={formik.handleChange}
                    >
                      <option value={0} disabled>
                        Select
                      </option>
                      {contacts.map((item) => (
                        <option value={Number(item.id)} key={item.id}>
                          {item.contact}
                        </option>
                      ))}
                    </select>
                    <span className="Register__error">
                      {(formik.touched.supervision_contact &&
                        formik.errors.supervision_contact) ||
                        ""}
                    </span>
                  </>
                )}

                <label className="label">
                  Task list items documented
                  <Tooltip content={TASK_LIST_ITEM_HELP} />
                </label>

                <MultipleSelect
                  name="task"
                  value={formik.values.task}
                  handleChange={formik.handleChange}
                  items={taskNames}
                  description={taskDescriptions}
                />

                {/* {formik.values.individual_or_group ===
                "No supervision" ? null : ( */}
                <>
                  <span className="Register__error">
                    {(formik.touched.task && formik.errors.task) || ""}
                  </span>

                  <label className="label justify-between log__supervisor">
                    <div>
                      Summary of Activity
                      <Tooltip content={SUMMARY_HELP} />
                    </div>
                    <div>
                      <button onClick={handleClickedTooltipData} type="button">
                        Copy Template
                      </button>
                    </div>
                  </label>
                  <textarea
                    className="textarea"
                    placeholder="Type here..."
                    name="experience_note"
                    ref={inputRef}
                    value={formik.values.experience_note}
                    onChange={formik.handleChange}
                  ></textarea>
                  <span className="Register__error">
                    {(formik.touched.experience_note &&
                      formik.errors.experience_note) ||
                      ""}
                  </span>
                </>
                {/* )} */}

                <button
                  className="button is-two-fifths is-large is-rounded dashBtn mt30"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
            {formik.values.expereince_type === 0 ? (
              <MonthCard values={formik.values} expHours={sumExpHours} />
            ) : null}
            {formik.values.expereince_type === "1" ? (
              <MonthCardSupervised
                values={formik.values}
                expHours={sumExpHours}
              />
            ) : null}
            {formik.values.expereince_type === "2" ? (
              <MonthCardConcentrated
                values={formik.values}
                expHours={sumExpHours}
              />
            ) : null}
          </div>
        </div>
      </div>

      <CustomModal
        modalVisible={modalVisible}
        onClose={setModalVisible}
        maxWidth="450px"
      >
        <Confirmation setModalVisible={setModalVisible} />
      </CustomModal>

      <CustomModal
        modalVisible={loginModalVisible}
        onClose={setLoginModalVisible}
        maxWidth="450px"
      >
<LoginConfirmationPopup setModalVisible={setLoginModalVisible}/>
        
      </CustomModal>
    </div>
  );
};

const MonthCardNew = () => {
  const checks = useMonthlyChecks();

  useDispatchEffect(fetchMonthlyCheck, null, checks === null);

  return (
    <div className="mt30 month-card">
      <div className="month">{moment(new Date()).format("MMMM YYYY")}</div>
      <Stack width="100%" direction="row">
        <div className="header">Supervised: 2,000</div>
        <div className="header">Concentrated: 1,500</div>
      </Stack>
      <Stack width="100%" direction="row">
        <Stack width="50%" spacing={2} p={3} borderRight="1px solid #B7B7B7">
          <CheckItem
            label="Minimum 20 Total Hours"
            checked={checks?.min_hours > 20}
          />
          <CheckItem
            label="Maximum 130 Total Hours"
            checked={checks?.max_hours < 130}
          />
          <CheckItem label="Minimum 4 Total Contracts" />
          <CheckItem
            label="Minimum 1 Observation with Client"
            checked={checks?.min_observation >= 1}
          />
          <CheckItem
            label="Minimum 5% Supervision of Total Hours"
            checked={checks?.five_percent_of_total_hours >= 5}
          />
          <CheckItem
            label="Minimum 50% Individual of Total Supervision Hours"
            checked={checks?.fifty_percent_individual >= 50}
          />
          <CheckItem
            label="Maximum 50% Group of Total Supervision Hours"
            checked={checks?.fifty_percent_group <= 50}
          />
        </Stack>
        <Stack width="50%" spacing={2} p={3}>
          <CheckItem
            label="Minimum 20 Total Hours"
            checked={checks?.min_hours > 20}
          />
          <CheckItem
            label="Maximum 130 Total Hours"
            checked={checks?.max_hours < 130}
          />
          <CheckItem label="Minimum 4 Total Contracts" />
          <CheckItem
            label="Minimum 1 Observation with Client"
            checked={checks?.min_observation >= 1}
          />
          <CheckItem
            label="Minimum 5% Supervision of Total Hours"
            checked={checks?.five_percent_of_total_hours >= 5}
          />
          <CheckItem
            label="Minimum 50% Individual of Total Supervision Hours"
            checked={checks?.fifty_percent_individual >= 50}
          />
          <CheckItem
            label="Maximum 50% Group of Total Supervision Hours"
            checked={checks?.fifty_percent_group <= 50}
          />
        </Stack>
      </Stack>
    </div>
  );
};

const MonthCardSupervisedNew = () => {
  const checks = useMonthlyChecks();

  useDispatchEffect(fetchMonthlyCheck, null, checks === null);

  return (
    <div className="mt30 month-card">
      <div className="month">{moment(new Date()).format("MMMM YYYY")}</div>
      <Stack width="100%" direction="row">
        <div className="header" style={{ width: "100%" }}>
          Supervised: 2,000
        </div>
      </Stack>
      <Stack width="100%" direction="row">
        <Stack width="100%" spacing={1} p={3}>
          <CheckItem
            label="Minimum 20 Total Hours"
            checked={checks?.min_hours > 20}
          />
          <CheckItem
            label="Maximum 130 Total Hours"
            checked={checks?.max_hours < 130}
          />
          <CheckItem label="Minimum 4 Total Contracts" />
          <CheckItem
            label="Minimum 1 Observation with Client"
            checked={checks?.min_observation >= 1}
          />
          <CheckItem
            label="Minimum 5% Supervision of Total Hours"
            checked={checks?.five_percent_of_total_hours >= 5}
          />
          <CheckItem
            label="Minimum 50% Individual of Total Supervision Hours"
            checked={checks?.fifty_percent_individual >= 50}
          />
          <CheckItem
            label="Maximum 50% Group of Total Supervision Hours"
            checked={checks?.fifty_percent_group <= 50}
          />
        </Stack>
      </Stack>
    </div>
  );
};

const MonthCardConcentratedNew = () => {
  const checks = useMonthlyChecks();

  useDispatchEffect(fetchMonthlyCheck, null, checks === null);

  return (
    <div className="mt30 month-card">
      <div className="month">{moment(new Date()).format("MMMM YYYY")}</div>
      <Stack width="100%" direction="row">
        <div className="header" style={{ width: "100%" }}>
          Concentrated: 1,500
        </div>
      </Stack>
      <Stack width="100%" direction="row">
        <Stack width="100%" spacing={1} p={3}>
          <CheckItem
            label="Minimum 20 Total Hours"
            checked={checks?.min_hours > 20}
          />
          <CheckItem
            label="Maximum 130 Total Hours"
            checked={checks?.max_hours < 130}
          />
          <CheckItem label="Minimum 4 Total Contracts" />
          <CheckItem
            label="Minimum 1 Observation with Client"
            checked={checks?.min_observation >= 1}
          />
          <CheckItem
            label="Minimum 5% Supervision of Total Hours"
            checked={checks?.five_percent_of_total_hours >= 5}
          />
          <CheckItem
            label="Minimum 50% Individual of Total Supervision Hours"
            checked={checks?.fifty_percent_individual >= 50}
          />
          <CheckItem
            label="Maximum 50% Group of Total Supervision Hours"
            checked={checks?.fifty_percent_group <= 50}
          />
        </Stack>
      </Stack>
    </div>
  );
};

const CheckItem = ({ checked, label }) => {
  return (
    <Stack
      direction="row"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <CheckCircleIcon
        sx={{
          color: checked ? "#D37498" : "#FFF",
          fontSize: "20px",
          mr: "16px",
        }}
      />
      <Typography
        className="check-label"
        style={{ color: checked ? "#D37498" : "#939597" }}
      >
        {label}
      </Typography>
    </Stack>
  );
};
export default LogHour;
