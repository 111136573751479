// Reducer that changes the state based on the action

import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { returnUpperBound } from "../../utils/textUtil";

export const slice = createSlice({
  name: "dashboard",
  initialState: {
    isLoading: false,
    chartData: null,
    tableData: [],
    success: false,
    error: null
  },
  reducers: {
    startFetching: (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.success = false
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
      state.isLoading = false;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.success= false;
      state.isLoading = false;
    },
    setChartData: (state, action) => {
      state.chartData = action.payload;
      state.success = true;
      state.isLoading = false;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
      state.success = true;
      state.isLoading = false;
    },
  },
});

export const {
  startFetching,
  setSuccess,
  setError,
  setChartData,
  setTableData
} = slice.actions;

export const useChartData = () => useSelector((state) => state.Dashboard.chartData);
export const usetableData = () =>
  useSelector((state) => {
    const tableData = state.Dashboard.tableData;
    let newTableData = Array.isArray(tableData)
      ? tableData.map((item) => {
          const newItem = { ...item };
          for (let key in newItem) {
            if (typeof newItem[key] === "number") {
              newItem[key] = returnUpperBound(newItem[key]);
            }
          }
          return newItem;
        })
      : [];
    return newTableData;
  });
export const useIsLoading = () => useSelector((state) => state.Dashboard.isLoading);
export const useSuccess = () => useSelector((state) => state.Dashboard.success);
export const useError = () => useSelector((state) => state.Dashboard.error);

export default slice.reducer;
