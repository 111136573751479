import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosArrowBack } from "react-icons/io";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setShowSidebar,
  useShowSidebar,
} from "../../../../redux/reducers/SidebarReducer";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import {
  useIsLoading,
  useUserSubscription,
  useSuccess,
  usePaymentSuccess,
} from "../../../../redux/reducers/SubscriptionReducer";
import { fetchUserSubscription } from "../../../../redux/sagas/subscription/fetchSaga";
import { updatePaymentMethod } from "../../../../redux/sagas/subscription/updatePaymentMethodSaga";
import { useDispatchEffect } from "../../../../utils/hooks";
import "../dashboardSubscription.css";
import "./updatePaymentMethod.css";
import CardPaymentForm from "../../../../components/CardPaymentForm";
import moment from "moment/moment";

const UpdatePaymentMethod = () => {
  const navigate = useNavigate();
  const showSidebar = useShowSidebar();
  const dispatch = useDispatch();

  useDispatchEffect(fetchUserSubscription);
  const userSubscription = useUserSubscription();
  const isLoading = useIsLoading();
  const success = useSuccess();
  const paymentSuccess = usePaymentSuccess();

  const onSubmit = ({ token }) => {
    dispatch(
      updatePaymentMethod({
        token: token,
      })
    );
  };

  useEffect(() => {
    if (paymentSuccess) navigate(`/student/dashboard-subscription`);
  }, [paymentSuccess]);

  return (
    <div className="section has-light-background">
      <div className="columns">
        <div className="column"></div>
      </div>
      <div className="columns">
        <div className="column max-width-449">
          <button
            className="Toggle_btn"
            onClick={() => dispatch(setShowSidebar(!showSidebar))}
          >
            {showSidebar ? <GiHamburgerMenu /> : <GiHamburgerMenu />}
          </button>
          <p className="headingMain">
            <a className=" backlink" onClick={(_) => navigate(-1)}>
              <IoIosArrowBack />
            </a>
            Payment Information
          </p>

          <div className="mt60">
            <p className="pera">Current plan:</p>
            <p className="heading2nd text-primary mt20">
              ${userSubscription?.subscription?.price?.price?.toFixed(2)}
              <span style={{ fontWeight: 600, fontSize: 24 }}>
                {" "}
                / {userSubscription?.subscription?.price?.period.toLowerCase()}
              </span>
            </p>
            <p className="pera mt-2">
              Next payment will be on{" "}
              {moment(userSubscription?.expiry).format("MMM D, YYYY")}
            </p>
            {!userSubscription?.is_lifetime && (
              <a
                className=" changelink "
                onClick={(_) =>
                  navigate(`/student/subscription-screen-dashbard`)
                }
              >
                Change subscription plan
              </a>
            )}
            <div>
              <hr className="hr-primary" />
            </div>
          </div>

          <div className="row-start subsCard ">
            {isLoading ? (
              <LoadingSpinner style={{ height: "230px", width: "50%" }} />
            ) : (
              <div className="max-width-449"></div>
            )}
          </div>
          <div className="mt-4">
            <CardPaymentForm onSuccess={onSubmit} isUpdate={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePaymentMethod;
