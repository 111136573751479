import moment from "moment";

export const isEmpty = (value) => {
  if (value === null || value === undefined) return true;
  else if (typeof value === "string" && value.length === 0) return true;
  else if (typeof value === "number" && value < 0) return true;
  else if (Array.isArray(value) && value.length <= 0) return true;
  else return false;
};

export const isDropDownEmpty = (value) => {
  if (value <= 0) return true;
  return false;
};
export const isNotSelected = (param = 0) => param && param === 0;
export const isValidNumber = (number) => /[^a-z \.]([0-9])*\d/.test(number);

export const validateLogHourForm = (values, additionalParams = {}) => {
  const {
    expereince_type,
    setting,
    supervisor,
    date_of_experience,
    time_of_expereince_start,
    time_of_expereince_end,
    unrestricted_hours,
    restricted_hours,
    experience_hours,
    individual_or_group,
    method_of_supervision,
    supervision_start_time,
    supervision_end_time,
    supervised_hours,
    independant_hours,
    client_observation,
    supervision_contact,
    task,
    experience_note,
    newSupervisorEmail,
    newSupervisor,
  } = values;
  const { addNewSupervisor } = additionalParams;
  let errors = {};

  if (isDropDownEmpty(expereince_type)) {
    errors["expereince_type"] = "⋆Required";
  }
  if (isDropDownEmpty(setting)) {
    errors["setting"] = "⋆Required";
  }
  if (isDropDownEmpty(supervisor) && !addNewSupervisor) {
    errors["supervisor"] = "⋆Required";
  }
  if (isEmpty(date_of_experience)) {
    errors["date_of_experience"] = "⋆Required";
  }
  if (isEmpty(time_of_expereince_start)) {
    errors["time_of_expereince_start"] = "⋆Required";
  }
  if (isEmpty(time_of_expereince_end)) {
    errors["time_of_expereince_end"] = "⋆Required";
  }
  if (isEmpty(unrestricted_hours)) {
    errors["unrestricted_hours"] = "⋆Required";
  }
  if (isEmpty(restricted_hours)) {
    errors["restricted_hours"] = "⋆Required";
  }
  if (isEmpty(experience_hours)) {
    errors["experience_hours"] = "⋆Required";
  }
  if (isEmpty(individual_or_group)) {
    errors["individual_or_group"] = "⋆Required";
  }
  if (
    isEmpty(method_of_supervision) &&
    individual_or_group !== "No supervision"
  ) {
    errors["method_of_supervision"] = "⋆Required";
  }
  if (
    isEmpty(supervision_start_time) &&
    individual_or_group !== "No supervision"
  ) {
    errors["supervision_start_time"] = "⋆Required";
  }
  if (
    isEmpty(supervision_end_time) &&
    individual_or_group !== "No supervision"
  ) {
    errors["supervision_end_time"] = "⋆Required";
  }
  if (isEmpty(supervised_hours) && individual_or_group !== "No supervision") {
    errors["supervised_hours"] = "⋆Required";
  }
  if (isEmpty(independant_hours) && individual_or_group !== "No supervision") {
    errors["independant_hours"] = "⋆Required";
  }
  if (isEmpty(client_observation) && individual_or_group !== "No supervision") {
    errors["client_observation"] = "⋆Required";
  }
  if (addNewSupervisor && isEmpty(newSupervisor)) {
    errors["newSupervisor"] = "⋆Required";
  }
  if (addNewSupervisor && isEmpty(newSupervisorEmail)) {
    errors["newSupervisorEmail"] = "⋆Required";
  }
  if (
    isDropDownEmpty(supervision_contact) &&
    individual_or_group !== "No supervision"
  ) {
    errors["supervision_contact"] = "⋆Required";
  }
  if (isEmpty(task)) {
    errors["task"] = "⋆Required";
  }
  if (isEmpty(experience_note)) {
    errors["experience_note"] = "⋆Required";
  }

  if (Number(values.unrestricted_hours) < 0) {
    errors.unrestricted_hours = "Hours cannot be negative";
  }
  if (Number(values.restricted_hours) < 0) {
    errors.restricted_hours = "Hours cannot be negative";
  }
  if (Number(values.experience_hours) < 0) {
    errors.experience_hours = "Hours cannot be negative";
  }
  if (Number(values.supervised_hours) < 0) {
    errors.supervised_hours = "Hours cannot be negative";
  }
  if (Number(values.independant_hours) < 0) {
    errors.independant_hours = "Hours cannot be negative";
  }
  if (
    Number(values.supervised_hours) >= 0 &&
    Number(values.independant_hours) >= 0
  ) {
    const startTime = moment(values.supervision_start_time, "HH:mm");
    const endTime = moment(values.supervision_end_time, "HH:mm");
    const startTimeExperience = moment(
      values.time_of_expereince_start,
      "HH:mm"
    );
    const endTimeExperience = moment(values.supervision_end_time, "HH:mm");
    const diffMinutesExperience = endTimeExperience.diff(
      startTimeExperience,
      "minutes"
    );

    const diffMinutes = endTime.diff(startTime, "minutes");
    const diffHrs = Math.floor(diffMinutes / 60);
    const diffMins = diffMinutes % 60;
    const durationInMinutes = diffHrs * 60 + diffMins;
    const supervisedHoursInMinutes = Number(values.supervised_hours) * 60;
    const totalHoursInMinutes = diffMinutesExperience;
    if (supervisedHoursInMinutes > durationInMinutes)
      errors.supervised_hours =
        "Value cannot be more than " +
        diffHrs +
        " hours and " +
        diffMins +
        " minutes";
    else if (totalHoursInMinutes < durationInMinutes) {
      const value = totalHoursInMinutes - durationInMinutes;
      const hours = Math.floor(value / 60);
      const minutes = value % 60;
      errors.supervision_end_time = `Supervision duration cannot exceed experience duration.`;
    }
  }
  return errors;
};

export const validateStudentRegistration = (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = "⋆Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email format";
  }
  if (!values.password) {
    errors.password = "⋆Required";
  } else if (values.password.length < 8) {
    errors.password = "Password must be 8 characters long";
  }
  if (!values.name) {
    errors.name = "⋆Required";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = "⋆Required";
  }
  if (values.password && values.confirmPassword) {
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Passwords not matched";
    }
  }
  if (!values.check) {
    errors.check = "Please agree to our terms and conditions";
  }
  return errors;
};
export const validateSupervisorRegistration = (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = "⋆Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email format";
  }
  if (!values.password) {
    errors.password = "⋆Required";
  } else if (values.password.length < 8) {
    errors.password = "Password must be 8 characters long";
  }
  if (!values.name) {
    errors.name = "⋆Required";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = "⋆Required";
  }
  if (values.password && values.confirmPassword) {
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Passwords not matched";
    }
  }
  if (!values.check) {
    errors.check = "Please agree to our terms and conditions";
  }
  if (!values.bacb_account_number) {
    errors.bacb_account_number = "⋆Required";
  } else if (!/^BACB\d{6}$/.test(values.bacb_account_number)) {
    errors.bacb_account_number = "Not a valid number";
  } else if (Number(values.bacb_account_number) <= 0) {
    errors.bacb_account_number = "ID Number cannot be negative";
  }
  if (!values.bacb_certification_number) {
    errors.bacb_certification_number = "⋆Required";
  } else if (
    !/^[0-9]-[0-9]{2}-[0-9]{5}$/.test(values.bacb_certification_number)
  ) {
    errors.bacb_certification_number = "Not a valid number";
  } else if (Number(values.bacb_certification_number) <= 0) {
    errors.bacb_certification_number = "ID Number cannot be negative";
  }
  if (!values.supervisor_qualification) {
    errors.supervisor_qualification = "⋆Required";
  }
  if (/^[0-9]+$/.test(values.supervisor_qualification)) {
    errors.supervisor_qualification =
      "Supervisor Qualification can not be numbers";
  }
  if (!values.date_qualified) {
    errors.date_qualified = "⋆Required";
  }
  if (!values.date_contract) {
    errors.date_contract = "⋆Required";
  }

  return errors;
};
export const validateSupervisorProfile = (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = "⋆Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email format";
  }
  if (!values.name) {
    errors.name = "⋆Required";
  }
  if (!values.bacb_account_number) {
    errors.bacb_account_number = "⋆Required";
  } else if (Number(values.bacb_account_number) <= 0) {
    errors.bacb_account_number = "ID Number cannot be negative";
  }
  if (!values.bacb_certification_number) {
    errors.bacb_certification_number = "⋆Required";
  } else if (Number(values.bacb_certification_number) <= 0) {
    errors.bacb_certification_number = "ID Number cannot be negative";
  }
  if (!values.supervisor_qualification) {
    errors.supervisor_qualification = "⋆Required";
  }
  if (/^[0-9]+$/.test(values.supervisor_qualification)) {
    errors.supervisor_qualification =
      "Supervisor Qualification can not be numbers";
  }
  if (!values.date_qualified) {
    errors.date_qualified = "⋆Required";
  }
  if (!values.date_contract) {
    errors.date_contract = "⋆Required";
  }

  return errors;
};

export const validateTrialPeriod = (date) => {
  const creationDate = moment(date);
  const today = new Date();
  const daysRemaining = moment(today).diff(creationDate, "days");
  return daysRemaining <= 7;
};

export const validateSubscriptionPeriod = (subscription) => {
  // If user subscription is lifetime allow access
  if (subscription.is_lifetime) return true;

  const isUserProfileExpired = subscription
    ? new Date(subscription.expiry).getTime() + 2 * 60000 < new Date().getTime()
    : false;
  return !isUserProfileExpired;
};

export const validateMFVF = (values) => {
  const { fileName, supervisor, downloaded, signed, filled } = values;
  let errors = {};

  if (!downloaded) errors.downloaded = "*Required";
  if (!signed) errors.signed = "*Required";
  if (!filled) errors.filled = "*Required";
  if (supervisor === 0) errors.supervisor = "*Required";
  if (isEmpty(fileName)) errors.fileName = "*Required";
  return errors;
};

export const validateFFVF = (values) => {
  const { fileName, supervisor, description, downloaded, signed, filled } =
    values;
  let errors = {};

  if (!downloaded) errors.downloaded = "*Required";
  if (!signed) errors.signed = "*Required";
  if (!filled) errors.filled = "*Required";
  if (isEmpty(description)) errors.description = "*Required";
  if (supervisor === 0) errors.supervisor = "*Required";
  if (isEmpty(fileName)) errors.fileName = "*Required";
  return errors;
};
