import { createAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { startFetching, setCurrentLogById, setErrors } from "../../reducers/ExpLogReducer";

export const fetchCurrentLogById = createAction("experienceLog/fetchCurrentLogById");

function* fetchCurrentLog({ payload } ) {
  yield put(setCurrentLogById({}));
  yield put(startFetching(true))
  try {
    let res = yield call(ApiService.getExperienceLogById, payload);
    console.log(res.data);
    if(res.data)
      yield put(setCurrentLogById(res.data));
  } catch (error) {
    console.log({ error });
    yield put(setErrors(error))
  }
}

export function* fetchExpLogByIdSaga() {
  yield takeLatest(fetchCurrentLogById, fetchCurrentLog);
}
