/*import React from "react";
import { Chart, Tooltip, Title, ArcElement, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

Chart.register(Tooltip, Title, ArcElement, Legend);

export default function DoughnutChart(props) {
  const { label, data, backgroundColor, width, height, labels } =
    props;
  const total = data.reduce((r, c) => r + c, 0);
  //console.log('data: ', data, total)

  return (
    <div style={{ position: "relative" }}>
      <Doughnut
        {...props}
        style={{ width, height }}
        data={{
          labels: labels,
          datasets: [
            {
              label,
              data,
              backgroundColor,
              spacing: 0,
            },
          ],
        }}
      />
      {/* <div className="percent-value">{(data[0] * 100) / data[0]}%</div> /}
      <div className="percent-value">
        {/*parseFloat((data[0] / total) * 100).toFixed(1)}%*}
      </div>
    </div>
  );
}*/

import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DoughnutChart(props) {
  const { label, data, backgroundColor, width, height, labels } = props;

  const dataC = {
    labels: labels,
    datasets: [
      {
        label: label,
        data: data,
        backgroundColor: backgroundColor,
      },
    ],
  };
  const opt = {
    plugins: {
      legend: {
        display: false,
        title: {
          display: false,
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            let total = context.dataset.data.reduce((r, c) => r + c, 0);
            return (
              context.label +
              ": " +
              parseFloat((context.parsed / total) * 100).toFixed(1) +
              "%"
            );
          },
        },
      },
    },
  };

  return <Doughnut data={dataC} style={{ width, height }} options={opt} />;
}
