import { createAction } from "@reduxjs/toolkit";
import ApiService from "../../../services/ApiService";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  setError,
  setUpdateUploadImageMsg,
  setUploadImageLink,
} from "../../reducers/ProfileReducer";
import axios from "axios";
import { toast } from "react-toastify";


export const fetchUploadLink = createAction("profile/fetchUploadLink");
export const updateUpload = createAction("profile/updateUpload");

function* fetchData({ payload }) {
  const { key, file } = payload;
  try {
    let res = yield call(ApiService.getSignedUrl, { key: key });
    yield put(setUploadImageLink(res.data));

    var instance = axios.create();
    delete instance.defaults.headers.common["Authorization"];
    yield instance.put(`${res.data}`, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
  } catch (error) {
    console.log("error=", error);
    yield put(setError(error));
  }
  yield call(updateUploadData, { payload: { key } });
  

}

function* updateUploadData({ payload }) {
  const { key } = payload;
  try {
    let res = yield call(ApiService.updateUploadImage, { key: key });
    yield put(setUpdateUploadImageMsg(res.data));
    if (res.status === 200 || res.status === 201 ) {
      //yield put(setUpdateSuccess(true));
      toast.success("Image Uploaded Successfully ", { autoClose: 3000 });
    } else {
      toast.error("Image Not Uploaded", { autoClose: 3000 });
    }
  } catch (error) {
    yield put(setError(error));
  }
}

export function* uploadImageSaga() {
  yield takeLatest(fetchUploadLink, fetchData);
}
