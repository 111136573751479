import moment from "moment";
import React, { useEffect } from "react";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
  useFlagged,
  useIsLoading,
  usePastFlagged
} from "../../redux/reducers/ExpLogReducer";
import {
  fetchFlagged,
  fetchPastFlagged
} from "../../redux/sagas/experienceLog/fetchFlaggedSaga";
import { useDispatchEffect } from "../../utils/hooks";
import "./Flagged.css";
import { LABELS, LOG_DATA } from "../../utils/CONSTANTS";
import LocalStorage from "../../services/LocalStorage";

const FlaggedTables = ({ flaggedLogs, pastLogs, isLoading }) => {
  const navigate = useNavigate();

  const onEdit = log => {
    LocalStorage.storeData(LOG_DATA, log);
    navigate(`/student/edit-resend`);
  };

  const onEditLog = log => {
    LocalStorage.storeData(LOG_DATA, log);
    navigate(`/student/editLogHour/${log.id}`);
  };

  return (
    <div>

      <p className="heading2nd mt80">Flagged Logs</p>
      <div
        className="column flagtable__container mt30"
        style={{ padding: 0 }}
      >
        <table className="Flagtable">
          <tr>
            <th>Date</th>
            <th>Flagged Items</th>
          </tr>
          {!isLoading &&
            flaggedLogs.map(log => (
              <tr key={'flagged-' + log.id}>
                <td>
                  {moment(log.date_of_experience).format("MM/DD/YYYY")}
                </td>
                <td className="tableFlex">
                  <span>{getFlaggedItems(log?.data)}</span>
                  <span className="editSpanTable">
                        <button
                          className=" editText"
                          onClick={_ => onEdit(log)}
                        >
                          Edit and resend
                        </button>
                      </span>
                </td>
              </tr>
            ))}
        </table>
        {isLoading && (
          <LoadingSpinner style={{ height: "100px", width: "100%" }} />
        )}
        {!isLoading && flaggedLogs.length === 0 && (
          <div className="empty-list">No flagged items found</div>
        )}
      </div>

      <p className="heading2nd mt80">Experience Logs</p>
      <div
        className="column flagtable__container mt30"
        style={{ padding: 0 }}
      >
        <table className="Flagtable">
          <tr>
            <th>Date</th>
            <th className="flagged-items">Description</th>
          </tr>
          {!isLoading &&
            pastLogs.map(item => (
              <tr key={item.id}>
                <td>
                  <button
                    className=" editText"
                    onClick={_ => onEditLog(item)}
                  >
                    {moment(item.date_of_experience).format("MM/DD/YYYY")}
                  </button>
                </td>
                <td className="tableFlex">
                      <span>
                        {item?.expereince_type?.name}: {item?.setting?.name}
                      </span>
                  <span className="statusSpanTable">{item.status}</span>
                </td>
              </tr>
            ))}
        </table>
        {isLoading && (
          <LoadingSpinner style={{ height: "100px", width: "100%" }} />
        )}
        {!isLoading && pastLogs.length === 0 && (
          <div className="empty-list">No flagged items found</div>
        )}
      </div>
    </div>
  );
};

const getFlaggedItems = data => {
  const labels = [];
  if (data) {
    Object.keys(data).forEach(key => {
      labels.push(LABELS[key]);
    });
    return labels.join(", ");
  } else return "";
};

export default FlaggedTables;
