import { createAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { call, all, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { setError, setSuccess, startFetching } from "../../reducers/MonthlyDocReducer";

export const submitDocument = createAction("monthlyDoc/submitDocument");


function* submitDoc({ payload }) {
  console.log("payload: ", payload);
  yield put(startFetching(true))
  try {
    let res = yield call(ApiService.postMonthlyDocument, payload);
    console.log('submitDoc res.data:', res.data);
    if(res.data.id) {
      yield put(setSuccess(true))
      toast.success('Successfully submitted!');
    } else {
      for (const errField in res.data) {
        const errors = res.data[errField];
        if(Array.isArray(errors) && errors.length)
          errors.forEach(err => {
            toast.error(err);
          })
        console.log('errors: ', errors)
      }
    }
  } catch (error) {
    console.error('postExpLog: ', error);
    yield put(setSuccess(false))
    toast.error(error);
  } 
}

export function* submitDocSaga() {
  yield takeLatest(submitDocument, submitDoc);
}
