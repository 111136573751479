import axios from "axios";
import { BASE_URL } from "../utils/CONSTANTS";

axios.defaults.baseURL = BASE_URL;
//http://localhost:8000  https://www.fieldworktracker.com
//axios.defaults.baseURL = "http://localhost:8000";
//axios.defaults.baseURL = "https://www.fieldworktracker.com";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers["Accept"] = "application/json";
axios.defaults.headers["X-CSRFToken"] =
  "ZgT2jJvV2D7L3zktN7I7FW4Bk8Nshero3do19BEBUvrYPJnwoyBfy8WkZrwZ8YG4";
axios.defaults.timeout = 120000;
axios.defaults.validateStatus = (status) => status >= 200 && status < 500;

const post = (url, body = {}) => axios.post(url, body);
const get = (url, params = {}) => axios.get(url, { params });
const put = (url, body = {}) => axios.put(url, body);
const patch = (url, body = {}) => axios.patch(url, body);
const del = (url, params = {}) => axios.delete(url, { params });

const ApiService = {
  axios,
  setAuthHeader: (token) => {
    axios.defaults.headers.common["Authorization"] = `token ${token}`;
  },
  removeAuthHeader: () => {
    delete axios.defaults.headers.common["Authorization"];
  },

  login: (params) => post(`/api/v1/login/`, params),
  signup: (params) => post(`/api/v1/signup/`, params),
  verifyEmail: (params) => post(`/users/email/verify-user/`, params),
  resendEmail: (params) => post(`/api/v1/resendemail/`, params),
  forgetPassSend: (params) => post(`/users/reset-password/send/`, params),
  forgetPassResend: (params) => post(`/users/reset-password/resend/`, params),
  forgetPassVerify: (params) => post(`/users/reset-password/verify/`, params),
  changePassword: (params) => post(`/users/password/change/`, params),

  getCharData: () => get(`/api/v1/dashboard-api/`),
  getTableData: () => get(`/api/v1/dashboard-table-api/`),

  getProfile: () => get(`/users/profile/`),
  updateProfile: (id, params) => put(`/users/profile/${id}/`, params),
  updateSupervisor: (id, params) => put(`/users/supervisor/${id}/`, params),
  getBadges: () => get("/api/v1/badges-api/"),
  getTraineeSupervisors: () => get("/api/v1/trainee-supervisors/"),
  postTraineeSupervisor: (params) =>
    post(`/api/v1/trainee-supervisors/`, params),
  updateTraineeSupervisor: (id, params) =>
    patch(`/api/v1/trainee-supervisors/${id}/`, params),
  deleteTraineeSupervisor: (id) => del(`/api/v1/trainee-supervisors/${id}/`),
  postSendInvitation: (params) =>
    post(`/api/v1/send-supervisor-invitation/`, params),

  getExperienceTypes: () => get(`/api/v1/expereince-type/`),
  getSetting: () => get(`/api/v1/setting/`),
  getSupervisors: () => get(`/api/v1/get-supervisor/`),
  getMethodSupervision: () => get(`/api/v1/method-supervision/`),
  getClientObservation: () => get(`/api/v1/client-observation/`),
  getSupervisionContact: () => get(`/api/v1/supervision-contact/`),
  getTasks: () => get(`/api/v1/task/`),
  postExpLog: (params) => post("api/v1/experience-log/", params),
  updateExpLog: (id, params) => put(`api/v1/experience-log/${id}/`, params),
  deleteExpLog: (id) => del(`api/v1/experience-log/${id}/`),
  getAll: () => get(`/api/v1/experience-log/`),
  getExperienceLogById: (id) => get(`/api/v1/experience-log/${id}/`),
  getFlagged: (month) =>
    get(
      `/api/v1/experience-log/?status=flagged${month ? "&month=" + month : ""}`
    ),
  getPending: (month) =>
    get(
      `/api/v1/experience-log/?status=pending${month ? "&month=" + month : ""}`
    ),
  getApproved: (month) =>
    get(
      `/api/v1/experience-log/?status=approved${month ? "&month=" + month : ""}`
    ),
  getCsvData: (params) => post(`/api/v1/download-experience-log/`, params),
  getTest: (params) => get(`/api/v1/test/`, params),
  getValidate: () => get(`/api/v1/validate/`),
  postRecordFile: (body) => post(`/api/v1/upload-record/`, body),
  getRecordFiles: () => get(`/api/v1/record-files/`),
  patchRecordFileId: (id, body) => patch(`/api/v1/upload-record/${id}/`, body),
  deleteRecordFile: (id) => del(`/api/v1/upload-record/${id}/`),

  getSubscriptions: () => get(`/api/v1/subscription/`),
  getUserSubscription: () => get(`/api/v1/user-subscription/`),
  addSubscription: (params) => post(`/api/v1/charge-payment/`, params),
  updateSubscription: (params) => put(`/api/v1/charge-payment/`, params),
  deleteSubscription: (params) => post(`/api/v1/modify-subscription/`, params),
  updatePaymentMethod: (params) =>
    post(`/api/v1/modify-payment-method/`, params),
  getUserPaymentDetails: () => get(`/api/v1/user-payment-details/`),

  getPrivacyPolicy: () => get(`/modules/privacy-policy/`),
  getTermsConditions: () => get(`/modules/terms-and-conditions/`),

  getAccrued: (uid) => get(`/api/v1/get-accrued/?u_id=${uid}`),
  getAccruedByDate: (uid, from, to) =>
    get(`/api/v1/get-accrued/?u_id=${uid}&from_date=${from}&to_date=${to}`),

  // Supervisor side

  getPendingByDate: (date) =>
    get(`/api/v1/experience-log/?date_of_experience=${date}&status=pending`),
  getPendingDates: () => get(`/api/v1/get-dates/`),
  getFlaggedByDate: (date) =>
    get(`/api/v1/experience-log/?date_of_experience=${date}&status=flagged`),
  postFlagLog: (params) => post("/api/v1/flag-log/", params),
  getTrainees: () => get("/api/v1/all-trainee/"),
  removeTrainee: (params) => post("/api/v1/remove-user/", params),
  postMonthlyDocument: (params) => post("/api/v1/monthy-log/", params),
  getMonthlyDocument: () => get("/api/v1/monthy-log/"),
  getTraineeTasks: () => get("/api/v1/get-trainee-task/"),
  checkCouponValidation: (params) =>
    get(`/api/v1/verify-coupon?coupon=${params}`),
  //Upload File in Profile Page
  getSignedUrl: (key) => post("/users/upload/signed-url", key),
  updateUploadImage: (key) => post("/users/upload/picture", key),
};

export default ApiService;
