export const faqData = [
  {
    question: "What is Tracker!",
    answer:
      "TRACKER! Is the best web-based app that allows both trainees and supervisors to easily track their experience hours for BACB certification. With the help of this app, trainees can input activities, analyze their progress with the 5th edition task list for competency, and share reports with supervisors.",
  },
  {
    question: "What are the restricted and unrestricted hours?",
    answer:
      "WTF is restricted? This is all of your direct work with clients that is behavior analytic in nature. You can only have up to 40% of your fieldwork hours in this category, or none at all.\n\n WTF is unrestricted? much any activity that is away from direct work with clients and is behavior analytic in nature. \n\n These things are 99% of what you will do in the future as a BCBA. \n\n Examples: observation, training, data collection, conducting assessments, writing, graphing, research etc. \n\n Non-examples: school stuff, administrative tasks, non-behavioral tasks, crisis management ",
  },
  {
    question: "How long do I store my documents from supervision?",
    answer: "7 years, babe.",
  },
  {
    question: "What is considered a supervision contact?",
    answer:
      "If your supervisor was present then this counts towards you supervision contact per supervisory period (4 contacts needed for supervised, 6 contacts needed concentrated). : A real-time interaction between the supervisor and trainee that takes place in an individual or group format.",
  },
  {
    question: "How often do I have to meet with my supervisor?",
    answer:
      "Supervised: 5% of your fieldwork hours have to be supervised by your supervisor! \n\n Concentrated: 10% of your fieldwork hours have to be supervised by your supervisor!",
  },
  {
    question: "What is discussed in supervision?",
    answer:
      "Great question! Your supervisor can follow a curriculum BUT we suggest using our task list grid to ensure that all topics are taught in supervision. #fluency Did you know the more you practice each task list topic, utilizing behavior skills training, you’ll become competent. We think 10 opportunities within each topics the gold ticket. Work smarter not harder babe. And use our proprietary grid to watch your hard work unfold!",
  },
  {
    question:
      "How often do I have to fill our my monthly verification form? What do I do with these forms?",
    answer:
      "It’s in the title, monthly! The BACB is clear, you’ve got 30 days to fill it out and sign. Follow our prompts under Fieldwork Forms and upload the MVF to Tracker!",
  },
  {
    question:
      "Supervision is so stressful... how can I make it less overwhelming?",
    answer:
      "WOULDN’T IT BE NICE TO SIT FOR THE BCBA EXAM AND FEEL CONFIDENT KNOWING YOU HAD THE TRAINING FOR EACH TASK-LIST TOPIC?  \n\n Tracker! Does that for you.\n\n  WOULDN’T IT BE NICE TO SIGN INTO ONE PLATFORM THAT STORES YOUR NOTES, ALLOWS YOUR SUPERVISOR TO ANALYZE AND APPROVE YOUR DOCUMENTATION IN REAL-TIME, AND STORE ALL MONTHLY AND FINAL VERIFICATION FORMS? \n\n An efficient and effective fieldwork process as well as an organized documentation system will help ensure that all relevant documents remain secure while keeping both you as the Trainee or Supervisor up-to-date on the progress being made throughout the course training. \n\n Tracker! Is the system that keeps stress at bay and everyone enjoying the process.",
  },
  {
    question:
      "How long will the supervision process take from start to finish?",
    answer:
      "Supervision can be completed in as short as 12 months , but often 18 months is the average. 1500-2000 hours have to be accrued throughout this process and the number of hours is determined by what is offered through your supervisor, some are able to accommodate 5% supervision and others 10% supervision",
  },
];
