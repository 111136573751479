import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useDispatchEffect } from "../utils/hooks";
import { fetchFiles } from "../redux/sagas/recordFile/fetchRecordFilesSaga";
import { deleteFile } from "../redux/sagas/recordFile/deleteRecordFileSaga";
import { useFormik, useFormikContext } from "formik";
import { validateFFVF, validateMFVF } from "../utils/validation";
import {
  useSupervisors,
  useUserProfile,
} from "../redux/reducers/ProfileReducer";
import { useDropzone } from "react-dropzone";
import { Container } from "react-bootstrap";
import UploadIcon from "../assets/Tray.png";
import swal from "sweetalert";
import "./component.css";
import { fetchSupervisors } from "../redux/sagas/profile/supervisorSaga";
import { submitRecordFile } from "../redux/sagas/recordFile/submitRecordFileSaga";
import { BASE_URL } from "../utils/CONSTANTS";
import { fetchFile } from "../redux/sagas/recordFile/fetchIdRecordFileSaga";

const UploadDocumentModal = ({
  isEdit = false,
  initialValues: initialFormValues,
  type = "M-FVF",
  onClose,
  date,
  data,
  onRefresh,
}) => {
  const dispatch = useDispatch();
  const user = useUserProfile();
  const supervisors = useSupervisors();

  const refreshFiles = useDispatchEffect(fetchFiles, null, true);
  useDispatchEffect(fetchSupervisors, null, true);
  const [fileName, setFileName] = useState("");
  const [document, setDocument] = useState();
  const [link, setLink] = useState("");
  let fileFormat = useRef();

  const onDrop = useCallback((acceptedFiles) => {
    fileFormat.current = acceptedFiles;
    formik.values.fileName = acceptedFiles[0].name;
    setFileName(acceptedFiles[0].name);
    setDocument(fileFormat.current[0]);
    const file = acceptedFiles[0];
    setFieldValue("image", file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const refreshData = () => {
    onRefresh();
    refreshFiles();
  };

  const confirmFileDelete = (fileName, id) => {
    swal({
      title: "Delete this file?",
      text: fileName,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleCancel();
        dispatch(
          deleteFile({
            id,
            refreshFiles: refreshData,
          })
        );
      }
    });
  };

  const handleRemove = () => {
    const fileName = type === 'M-FVF' ? data?.monthly_form.title : data?.title;
    const id  = type === 'M-FVF' ? data?.monthly_form.id : data?.id;

    confirmFileDelete(fileName, id);
  };

  const handleCancel = () => {
    onClose();
  };

  const displayButtons = () => {
    return (
      <>
        <div
          style={{ display: "flex" }}
          className="column is-centered is-horizontal-center mt-3"
        >
          <button
            className="secondary-button-modal mr-3"
            type="button"
            style={{ cursor: "pointer" }}
            onClick={handleCancel}
          >
            Cancel
          </button>

          <button
            style={{ cursor: "pointer" }}
            className="primary-button-modal"
            type="submit"
          >
            {isEdit ? "Save Changes" : "Upload Document"}
          </button>
        </div>
      </>
    );
  };

  const attachmentContainer = () => {
    return (
      <div className="columns attachment mt30" {...getRootProps()}>
        <Container className="attachments  column is-three-fifths">
          <div className="pdf"></div>
          <div className="add-files">
            <input
              {...getInputProps()}
              accept="image/*,.pdf"
              type="file"
              name="image"
            />
            <img src={UploadIcon} alt="upload" />{" "}
            <p className="DragText">Drag docs here</p>
            <p className="DropText">
              or click to choose docs from your computer
            </p>
          </div>
        </Container>
      </div>
    );
  };

  const onSubmit = (values, onSubmitProps) => {
    let supervisor = null;
    if (values?.supervisor) {
      supervisor = JSON.parse(values?.supervisor);
    }
    const newValues = {
      ...values,
    };

    if (isEdit) {
      const editFormData = new FormData();
      if (document) {
        editFormData.append("document", document);
      }

      editFormData.append("title", fileName);
      editFormData.append("user", user.id);
      editFormData.append("notes", newValues.notes);

      if (supervisor) {
        newValues["supervisor"] = supervisor?.supervisor?.user || null;
        newValues["trainee_supervisor"] = supervisor?.id || null;
      }

      if (newValues["supervisor"]) {
        editFormData.append("supervisor", newValues.supervisor);
      }

      if (newValues["trainee_supervisor"]) {
        editFormData.append(
          "trainee_supervisor",
          newValues?.trainee_supervisor
        );
      }

      let id = null;

      if (type === "M-FVF") {
        id = data?.monthly_form.id;
      } else {
        id = data.id;
        editFormData.append("description", newValues.description);
      }

      dispatch(
        fetchFile({
          id: id,
          formData: editFormData,
          refreshFiles: refreshData,
        })
      );
    } else {
      if (supervisor?.supervisor !== null)
        newValues["supervisor"] = supervisor?.supervisor?.user || null;
      newValues["trainee_supervisor"] = supervisor?.id || null;

      const formData = new FormData();
      formData.append("title", fileName);
      formData.append("document", document);
      formData.append("user", user.id);
      formData.append("notes", newValues.notes);

      if (supervisor?.supervisor !== null)
        formData.append("supervisor", newValues.supervisor);

      formData.append(
        "trainee_supervisor",
        newValues?.trainee_supervisor || null
      );
      if (type !== "M-FVF") {
        formData.append("form_type", "Final");
        formData.append("description", newValues.description);
      } else {
        formData.append("form_type", "Monthly");
        formData.append("date", date);
      }

      dispatch(
        submitRecordFile({
          formData: formData,
          refreshFiles: refreshData,
        })
      );
    }
    onClose();
  };

  let formik;

  if (type === "M-FVF") {
    formik = useFormik({
      initialValues: initialFormValues,
      onSubmit,
      validate: validateMFVF,
    });
  } else {
    formik = useFormik({
      initialValues: initialFormValues,
      onSubmit,
      validate: validateFFVF,
    });
  }

  const { setFieldValue } = formik;

  useEffect(() => {
    if (isEdit) {
      if (type === "M-FVF") {
        setLink(data?.monthly_form?.document);
        setFileName(data?.monthly_form?.title);
      } else {
        setLink(data?.document);
        setFileName(data?.title);
      }
    }
  }, []);

  useEffect(() => {
    if (formik.values.image !== null) {
      setFileName(formik.values.image.name);
      formik.values.fileName = formik.values.image.name;
      setDocument(formik.values.image);
    }
  }, [formik.values]);
  return (
    <div className="upload-container">
      <h3>Document Upload</h3>
      <p>Before uploading, did you make sure you</p>
      <form
        onSubmit={formik.handleSubmit}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <label className="checkbox-label-pink">
          <input
            type="checkbox"
            className="checkSize-pink"
            name="filled"
            checked={formik.values.filled}
            onChange={formik.handleChange}
          />
          <span>Filled in all the fields</span>
          <span className="Register__error">
            {(formik.touched.filled && formik.errors.filled) || ""}
          </span>
        </label>
        <label className="checkbox-label-pink">
          <input
            type="checkbox"
            className="checkSize-pink"
            name="downloaded"
            checked={formik.values.downloaded}
            onChange={formik.handleChange}
          />
          <span>
            Downloaded the PDF before filling out the form (not in the browser)
          </span>
          <span className="Register__error">
            {(formik.touched.downloaded && formik.errors.downloaded) || ""}
          </span>
        </label>
        <label className="checkbox-label-pink">
          <input
            type="checkbox"
            className="checkSize-pink"
            name="signed"
            checked={formik.values.signed}
            onChange={formik.handleChange}
          />
          <span>Get the form signed by your supervisor</span>
          <span className="Register__error">
            {(formik.touched.signed && formik.errors.signed) || ""}
          </span>
        </label>
        <div className="column is-two-fifths">{attachmentContainer()}</div>
        <div className="column-input">
          <label className="label">File Name</label>
          <input
            className="input is-small"
            type="text"
            name="fileName"
            value={formik.values.fileName}
            onChange={formik.handleChange}
            disabled
          />
        </div>
        <span className="Register__error">
          {(formik.touched.fileName && formik.errors.fileName) || ""}
        </span>
        {type === "M-FVF" ? (
          <></>
        ) : (
          <>
            <div className="column-input">
              <label className="label">Description</label>
              <input
                className="input is-small"
                type="text"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                //onBlur={formik.handleBlur}
              />
            </div>
            <span className="Register__error">
              {(formik.touched.description && formik.errors.description) || ""}
            </span>
          </>
        )}
        <div className="column-input">
          <label className="label">Supervisor</label>
          <select
            name="supervisor"
            className="select dropdownCustom mt is-normal"
            value={formik.values.supervisor}
            onChange={formik.handleChange}
          >
            <option value={0} disabled>
              Select
            </option>
            {supervisors.map((item) => (
              <option value={JSON.stringify(item)} key={item.id}>
                {item.name + " " + item.lastname}
              </option>
            ))}
          </select>
        </div>
        <span className="Register__error">
          {(formik.touched.supervisor && formik.errors.supervisor) || ""}
        </span>
        <div className="column-input">
          <label className="label">Notes</label>
          <textarea
            className="textarea"
            name="notes"
            value={formik.values.notes}
            onChange={formik.handleChange}
          />
        </div>
        {displayButtons()}
      </form>
    </div>
  );
};

export default UploadDocumentModal;
