import { createAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { startFetching, setLogs, setErrors, setFlagged, setPastFlagged } from "../../reducers/ExpLogReducer";
import moment from "moment/moment";

export const fetchAll = createAction("experienceLog/fetchAll");

function* fetchData({payload = null}) {
  yield put(startFetching(true))
  try {
    let monthlyLogs = yield call(ApiService.getAll);
    if(monthlyLogs.data.results) yield put(setLogs(monthlyLogs.data.results));

    let flaggedLogs = yield call(ApiService.getFlagged, payload)
    if (flaggedLogs.data.results) yield put(setFlagged(flaggedLogs.data.results))

    let pending = [],
      approved = []

    let res = yield call(ApiService.getApproved, payload)
    let res2 = yield call(ApiService.getPending, payload)
    if (res.data.results) {
      approved = approved.concat(res.data.results)
      approved = approved?.sort((a, b) =>
        moment(b?.date_of_experience, 'YYYY-MM-DD').diff(
          moment(a?.date_of_experience, 'YYYY-MM-DD')
        )
      )
    }
    if (res2.data.results) {
      pending = pending.concat(res2.data.results)
      pending = pending?.sort((a, b) =>
        moment(b?.date_of_experience, 'YYYY-MM-DD').diff(
          moment(a?.date_of_experience, 'YYYY-MM-DD')
        )
      )
    }

    yield put(setPastFlagged([...approved, ...pending]))
  } catch (error) {
    console.log({ error });
    yield put(setErrors(error))
  }
}

export function* fetchAllSaga() {
  yield takeLatest(fetchAll, fetchData);
}

