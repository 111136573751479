import { createAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { startFetching, setLogs, setErrors, setMonthlyChecks } from "../../reducers/ExpLogReducer";

export const fetchMonthlyCheck = createAction("experienceLog/fetchMonthlyCheck");

function* fetchData() {
  yield put(startFetching(true))
  try {
    let res = yield call(ApiService.getValidate);
    if(res.data)
        yield put(setMonthlyChecks(res.data));
  } catch (error) {
    console.log({ error });
    yield put(setErrors(error))
  }
}

export function* fetchMonthlyCheckSaga() {
  yield takeLatest(fetchMonthlyCheck, fetchData);
}

