import React, { useEffect } from 'react'
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import { IconButton } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
export default function Tooltip({ content }) {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMouseEnter = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleMouseLeave = (event) => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
  
    // useEffect(() => {
    //   open && setTimeout(handleClick, 50*content?.length);
    // }, [open]);

  return (
    <span>
        <IconButton
          aria-label={id}
          sx={{
            bgcolor: "transparent",
            "& .MuiButtonBase-root": {
              borderRadius: "56px",
              width: "15px",
              height: "15px",
              
            },
            padding: 0
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <HelpIcon sx={{ color: "#D37498", fontSize: '18px' }}/>
        </IconButton>

      <Popper
        id={id} 
        open={open} 
        anchorEl={anchorEl}
        placement="bottom"
        disablePortal={false}
        transition
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={1000}>
                    <Paper sx={{ bgcolor: '#D37498', width: '250px', p: 1, zIndex: 100 }}>
                        <p className='tooltip-content'>{ content }</p>
                    </Paper>
                </Fade>
                )}
      </Popper>
    </span>
  )
}
