import React from "react";
import "./LoginConfirmationPopup.css";
import badge from "../../assets/badge.svg";

const LoginConfirmationPopup = ({ setModalVisible }) => {
  return (
    <div className="confirmationBlock">
      <img src={badge} alt="" />
      <p>Hey! Look at you, being responsible and logging your hours. Don&apos;t worry, we are here on this journey with you and taking some of the stress away from this daunting task. So pour a drink and head over to your dashboard. Hover on those question marks if you need additional prompts. You got this!</p>
    </div>
  );
};
export default LoginConfirmationPopup;
