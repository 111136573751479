import { createAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { call, all, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { setFormData, setErrors, setSuccess, startFetching } from "../../reducers/ExpLogReducer";

export const editExpLog = createAction("experienceLog/editExpLog");
export const deleteExpLog = createAction("experienceLog/deleteExpLog");

function* submitForm({ payload }) {
  console.log("payload: ", payload);
  const { id, log } = payload; 
  yield put(startFetching(true))
  try {
    let res = yield call(ApiService.updateExpLog, id, log);
    console.log('editExpLog: ', res.data);
    if(res.data.id) {
      yield put(setSuccess(true))
    } else {
      yield put(setSuccess(false));
      const errors = []
      for (const errField in res.data) {
        const error = res.data[errField];
        if(Array.isArray(error) && error.length)
          errors.push(...error.map(err => `${errField}: ${err}!`));
      }
      console.log('errors: ', errors)
      yield put(setErrors(errors));
    }
  } catch (error) {
    console.error('postExpLog: ', error);
    yield put(setSuccess(false))
    const errors = Object.values(error?.response?.data ?? {});
    toast.error(errors.join(error));
  } 
}

function* deleteLog({ payload }) {
  const { id, callback } = payload; 
  try {
    let res = yield call(ApiService.deleteExpLog, id);
    console.log('deleteExpLog: ', res.data);
    if (res.status >= 200 && res.status < 300) {
      toast.success('Successfully deleted!');
      callback?.()
      return;
    }

    if (res.data?.detail) {
      toast.error(res.data.detail);
    }
  } catch (error) {
    console.log({ error });
    console.log(error)
    toast.error('Failed to delete!');
  } 
}

export function* editFormSaga() {
  yield takeLatest(editExpLog, submitForm);
}

export function* deleteFormSaga() {
  yield takeLatest(deleteExpLog, deleteLog);
}
