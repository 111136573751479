import { createAction } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { setPaymentSuccess, setSuccess, startFetching,  } from "../../reducers/SubscriptionReducer";
import {setIsSubscriptionExpired} from "../../reducers/AuthReducer";

export const updatePaymentMethod = createAction("subscription/updatePaymentMethodSaga");

function* updatePayment({ payload }) {
  console.log("payload: ", payload);
  yield put(startFetching(true))
  try {
    let res = yield call(ApiService.updatePaymentMethod, payload);
    console.log('updatePayment res: ', res.data);
    if(res.data.msg === 'Successfully Updated Payment Method') {
      toast.success(res.data.msg);
      yield put(setIsSubscriptionExpired(false))
      yield put(setSuccess(true))
      yield put(setPaymentSuccess(true))

    } else {
      toast.error(res.data.error);
      yield put(setSuccess(false))
      yield put(setPaymentSuccess(false))

    }
  } catch (error) {
    console.log({ error });
    const errors = Object.values(error?.response?.data ?? {});
    toast.error(errors.join(error));
    yield put(setSuccess(false))
    yield put(setPaymentSuccess(false))

  }
}
export function* updatePaymentMethodSaga() {
  yield takeLatest(updatePaymentMethod, updatePayment)
}
