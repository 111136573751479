import { createAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import {startFetching, setTotalAccruedData, setWeeklyAccruedData, setError } from "../../reducers/MonthlyDocReducer";

export const fetchAccrued = createAction("monthlyDoc/fetchAccrued");

function* fetchData({ payload }) {
  console.log("fetchAccrued payload: ", payload);
  yield put(startFetching(true));
  try {
    let res = yield call(ApiService.getAccrued, payload);
    if(res.data)
        yield put(setTotalAccruedData(res.data));
  } catch (error) {
    console.log({ error });
    yield put(setError(error))
  }
}

export function* fetchAccruedSaga() {
  yield takeLatest(fetchAccrued, fetchData);
}

export const fetchAccruedByDate = createAction("monthlyDoc/fetchAccruedByDate");

function* fetchData2({ payload }) {
  console.log("fetchAccruedByDate payload: ", payload);
  const { u_id, week } = payload; 
  const { from, to } = week; 
  yield put(startFetching(true));
  try {
    let res = yield call(ApiService.getAccruedByDate, u_id, from, to);
    console.log('fetchAccruedByDate res.data: ', res.data)
    if(res.data)
        yield put(setWeeklyAccruedData(res.data));
  } catch (error) {
    console.log({ error });
    yield put(setError(error))
  }
}

export function* fetchAccruedByDateSaga() {
  yield takeLatest(fetchAccruedByDate, fetchData2);
}

