import { GiHamburgerMenu } from "react-icons/gi";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {
  setUserProfile,
  useBadgeValue,
  useImageUploadLink,
  useUpdateUploadImage,
  useImageUploading,
  useUserProfile,
} from "../../redux/reducers/ProfileReducer";
import {
  setShowSidebar,
  useShowSidebar,
} from "../../redux/reducers/SidebarReducer";
import { signOutAction } from "../../redux/sagas/auth/AuthSagas";
import badge from "../../assets/badge.png";
import badge1 from "../../assets/badges/1.png";
import badge2 from "../../assets/badges/2.png";
import badge3 from "../../assets/badges/3.png";
import badge4 from "../../assets/badges/4.png";
import badge5 from "../../assets/badges/5.png";
import badge6 from "../../assets/badges/6.png";
import badge7 from "../../assets/badges/7.png";
import badge8 from "../../assets/badges/8.png";
import badge9 from "../../assets/badges/9.png";
import badge10 from "../../assets/badges/10.png";
import editIcon from "../../assets/edit.svg";

import "./ProfileScreen.css";
import { useDispatchEffect } from "../../utils/hooks";
import { fetchBadges } from "../../redux/sagas/profile/fetchBadgesSaga";
import swal from "sweetalert";

import InviteSupervisor from "../../components/StudentProfile/InviteSupervisor";
import {
  fetchUploadLink,
  updateUpload,
} from "../../redux/sagas/profile/uploadSaga";
import { fetchProfile } from "../../redux/sagas/profile/fetchSaga";
import { useState } from "react";
import { toast } from "react-toastify";

const ProfileScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showSidebar = useShowSidebar();
  const user = useUserProfile();
  const badgeValue = useBadgeValue();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const imageUploading = useImageUploading();
  const imageUrl = useUpdateUploadImage();

  const confirmLogout = () => {
    swal({
      title: "Are you sure you want to logout?",
      // text: "you want to Logout!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        onLogout();
        // swal("You are Logged out Succeccfully!", {
        //   icon: "success",
        // });
      }
    });
  };

  const onLogout = () => {
    dispatch(signOutAction());
    navigate("/");
    window.location.reload();
  };

  const handleFileDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files && event.dataTransfer.files[0];
    if (
      !["image/png", "image/jpeg", "image/jpg", "image/svg"].includes(file.type)
    ) {
      toast.error("Invalid File Format", { autoClose: 3000 });
      return;
    }
    handleFileUpload(file);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleFileUpload(file);
  };

  const handleFileUpload = (file) => {
    if (file.size > 20000000) {
      toast.error("File size cannot be more than 20MB", { autoClose: 3000 });
      return;
    }
    const key = `media/test/${user.id}/${Date.now()}.${
      file.name.split(".")[1]
    }`;

    dispatch(
      fetchUploadLink({
        key: key,
        file: file,
      })
    );
    handleUpdateUploadApi(key);
  };

  const handleUpdateUploadApi = (key) => {
    dispatch(updateUpload({ key: key }));
  };

  useDispatchEffect(fetchBadges, null, true);

  return (
    <div className="section has-light-background">
      <div className="columns">
        <div className="column"></div>
      </div>
      <div className="columns">
        <div className="column">
          <button
            className="Toggle_btn"
            onClick={() => dispatch(setShowSidebar(!showSidebar))}
          >
            {showSidebar ? <GiHamburgerMenu /> : <GiHamburgerMenu />}
          </button>

          <p className="headingMain">Profile</p>
          <div
            className="profile-block"
            onDrop={handleDrop}
            onDragOver={handleFileDragOver}
          >
            {imageUploading ? (
              <div className="profile-block-loader"></div>
            ) : imageUrl ? (
              <img src={imageUrl.profile_pic} alt="Profile Picture" />
            ) : (
              <img
                src={user.profile_pic ? user.profile_pic : badge5}
                alt="Profile Picture"
              />
            )}

            <label className="profile-edit">
              <img src={editIcon} alt="Edit" />
              <input
                type="file"
                id="fileInput"
                onChange={handleFileChange}
                accept="image/*"
              />
            </label>
          </div>

          <p className="pera mt20">Name</p>
          <p className="heading3rd ">{user.name}</p>
          <p className="pera mt30">Email</p>
          <p className="heading3rd ">{user.email}</p>

          <p className="pera mt40">
            <a
              className="pera  mt20"
              onClick={(_) => navigate(`/student/password-change-profile`)}
            >
              Change Password
            </a>
          </p>

          <p className="pera mt20">
            <a
              className="pera  mt20"
              onClick={(_) => navigate(`/student/terms-condition`)}
            >
              Terms and Conditions
            </a>
          </p>
          <p className="pera mt20">
            <a
              className="pera  mt20"
              onClick={(_) => navigate(`/student/privacy`)}
            >
              Privacy Policy
            </a>
          </p>
          <p className="pera mt20">
            <a
              className="pera  mt20"
              onClick={(_) => navigate(`/student/dashboard-subscription`)}
            >
              Subscription
            </a>
          </p>

          <p className="mt40">
            <a className=" changelink mt60" onClick={confirmLogout}>
              Log out
            </a>
          </p>
        </div>
      </div>
      <InviteSupervisor />

      <div className="columns mt60">
        <div
          className={`column ${
            isTabletOrMobile ? "" : "is-two-thirds"
          } mainBadges`}
        >
          <p> Badges - Non-concentrated hours</p>
          <BadgesTable percentage={badgeValue.percentage_supervised} />
        </div>
      </div>
      <div className="columns">
        <div
          className={`column ${
            isTabletOrMobile ? "" : "is-two-thirds"
          } mainBadges mt20`}
        >
          <p> Badges - Concentrated hours</p>
          <BadgesTable percentage={badgeValue.percentage_concentrated} />
        </div>
      </div>
    </div>
  );
};

const BadgesTable = ({ percentage }) => {
  return (
    <>
      <div className="badges mt50">
        <div className="nestedDivBadges">
          <img
            className={`badge-icon ${percentage >= 10 ? "dark" : "light"}`}
            src={badge1}
          />
          <p className={percentage >= 10 ? "dark" : "light"}>10% Completed</p>
        </div>
        <div className="nestedDivBadges">
          <img
            className={`badge-icon ${percentage >= 20 ? "dark" : "light"}`}
            src={badge2}
          />
          <p className={percentage >= 20 ? "dark" : "light"}>20% Completed</p>
        </div>
        <div className="nestedDivBadges">
          <img
            className={`badge-icon ${percentage >= 30 ? "dark" : "light"}`}
            src={badge3}
          />
          <p className={percentage >= 30 ? "dark" : "light"}>30% Completed</p>
        </div>
        <div className="nestedDivBadges">
          <img
            className={`badge-icon ${percentage >= 40 ? "dark" : "light"}`}
            src={badge4}
          />
          <p className={percentage >= 40 ? "dark" : "light"}>40% Completed</p>
        </div>
        <div className="nestedDivBadges">
          <img
            className={`badge-icon ${percentage >= 50 ? "dark" : "light"}`}
            src={badge5}
          />
          <p className={percentage >= 50 ? "dark" : "light"}>50% Completed</p>
        </div>
        <div className="nestedDivBadges">
          <img
            className={`badge-icon ${percentage >= 60 ? "dark" : "light"}`}
            src={badge6}
          />
          <p className={percentage >= 60 ? "dark" : "light"}>60% Completed</p>
        </div>
        <div className="nestedDivBadges">
          <img
            className={`badge-icon ${percentage >= 70 ? "dark" : "light"}`}
            src={badge7}
          />
          <p className={percentage >= 70 ? "dark" : "light"}>70% Completed</p>
        </div>
        <div className="nestedDivBadges">
          <img
            className={`badge-icon ${percentage >= 80 ? "dark" : "light"}`}
            src={badge8}
          />
          <p className={percentage >= 80 ? "dark" : "light"}>80% Completed</p>
        </div>
        <div className="nestedDivBadges">
          <img
            className={`badge-icon ${percentage >= 90 ? "dark" : "light"}`}
            src={badge9}
          />
          <p className={percentage >= 90 ? "dark" : "light"}>90% Completed</p>
        </div>
        <div className="nestedDivBadges">
          <img
            className={`badge-icon ${percentage >= 100 ? "dark" : "light"}`}
            src={badge10}
          />
          <p className={percentage >= 100 ? "dark" : "light"}>100% Completed</p>
        </div>
      </div>
    </>
  );
};

export default ProfileScreen;
