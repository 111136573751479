import React, { useState } from "react";
import "./subcription.css";
import { useDispatchEffect } from "../../utils/hooks";
import { fetchSubscriptions } from "../../redux/sagas/subscription/fetchSaga";
import {
  useData,
  useIsLoading,
} from "../../redux/reducers/SubscriptionReducer";
import LoadingSpinner from "../../components/LoadingSpinner";

import { useNavigate } from "react-router-dom";
import {
  useIsTrialExpired,
  useIsSubscriptionExpired,
} from "../../redux/reducers/AuthReducer";
import Dog from "../../assets/dog.svg";
import Star from "../../assets/star.svg";

const Subcription = () => {
  const navigate = useNavigate();
  const isTrialExpired = useIsTrialExpired();
  const isSubscriptionExpired = useIsSubscriptionExpired();
  let subscriptions = useData();
  const isLoading = useIsLoading();
  // const trial = subscriptions.filter((e) => e.name == "Trial");
  subscriptions = subscriptions.filter((e) => e.name != "Trial");
  useDispatchEffect(fetchSubscriptions, null, subscriptions?.length == 0);

  const [selected, setSelected] = useState(null);
  const [trialSelected, setTrialSelected] = useState(0);
  let monthPrice;
  for (const subscription of subscriptions) {
    if (subscription.price.period === "month") {
      monthPrice = subscription.price.price;
    }
  }

  function hasDecimals(number) {
    return number % 1 !== 0;
  }

  const handleTypeOfSubscription = (item) => {
    if (item.price?.period === "month") {
      return (
        <div className="panel panel-default card-input">
          <div className="panel-head">
            <h1 className="title" style={{ marginTop: 40, marginBottom: 5 }}>
              <div className="price" style={{ fontWeight: 500 }}>
                <sub style={{ fontSize: 22, fontWeight: 400 }}>$</sub>{" "}
                {hasDecimals(item.price?.price)
                  ? item.price?.price.toFixed(2)
                  : item.price?.price}
              </div>
            </h1>
            <p className="subtitle2">
              <b>per month</b>
            </p>
          </div>
        </div>
      );
    }
    if (item.price?.period === "year") {
      return (
        <div className="panel panel-default card-input">
          <div className="panel-head">
            <h1 className="title" style={{ marginTop: 40, marginBottom: 5 }}>
              <div className="price" style={{ fontWeight: 500 }}>
                <sub style={{ fontSize: 22, fontWeight: 400 }}>$</sub>{" "}
                {hasDecimals(item.price?.price)
                  ? item.price?.price.toFixed(2)
                  : item.price?.price}
              </div>
            </h1>
            <p className="subtitle2">
              <b>per year</b>
            </p>

            <p className="subtitle2" style={{ marginTop: 10 }}>
              Save $
              {(monthPrice * 12 - item?.price?.price.toFixed(2)).toFixed(2)}
            </p>
          </div>
        </div>
      );
    }
    if (item.price?.period === "life_time") {
      return (
        <div className="panel panel-default card-input">
          <div className="panel-head">
            <h1 className="title" style={{ marginTop: 40, marginBottom: 5 }}>
              <div className="price" style={{ fontWeight: 500 }}>
                <sub style={{ fontSize: 22, fontWeight: 400 }}>$</sub>{" "}
                {hasDecimals(item.price?.price)
                  ? item.price?.price.toFixed(2)
                  : item.price?.price}
              </div>
            </h1>
            <p className="subtitle2">
              <b>
                Lifetime<br></br> Subscription
              </b>
            </p>
          </div>
        </div>
      );
    }
  };

  // Custom sorting function
  const sortBySubscriptionType = (a, b) => {
    if (a.price.period === "life_time") {
      return -1; // "a" comes before "b"
    } else if (b.price.period === "life_time") {
      return 1; // "b" comes before "a"
    } else if (a.price.period === "year") {
      return -1; // "a" comes before "b"
    } else if (b.price.period === "year") {
      return 1; // "b" comes before "a"
    } else {
      return 0; // maintain the existing order
    }
  };

  // Apply sorting to subscriptions array
  const sortedSubscriptions = subscriptions
    .slice()
    .sort(sortBySubscriptionType);

  return (
    <section className="section has-left-background isOverlay">
      <div className="columns is-centered">
        <div className="column"></div>
        <div className="column">
          <div className="columns is-mobile is-centered">
            <div className="column mt-5 title-container-subs">
              <p className="headingMain">Choose Subscription</p>
              <p className="intro-text">
                The certification process can take anywhere from 18 months to 5
                years. All documentation needs to be kept for another 7 years.
              </p>

              <p className="comingSoon">
                <strong>
                  <em>Coming soon!</em> Graduate plan
                </strong>
                <br></br>
                Access your documents, even after you are done with your
                training.<br></br>
                Included with Lifetime subscription.
              </p>
            </div>
          </div>

          <div className="row subsCard" style={{ flexWrap: "nowrap" }}>
            {isLoading ? (
              <LoadingSpinner style={{ height: "230px", width: "50%" }} />
            ) : (
              sortedSubscriptions.map((item) => {
                return (
                  <div
                    className="col-md-4 col-lg-4 col-sm-4 mx-2"
                    key={item.id}
                  >
                    {item.price?.period === "life_time" && (
                      <div className="current-plan">
                        <img src={Star} alt="Star" />
                        <img src={Dog} alt="dog" />
                        <p
                          className="current-subscription"
                          style={{ color: "#B9547A" }}
                        >
                          Most Popular!
                        </p>
                        <img src={Star} alt="Star" />
                      </div>
                    )}
                    <label>
                      <input
                        type="radio"
                        name="subscription"
                        className="card-input-element"
                        onClick={(_) => {
                          setSelected(item.id);
                        }}
                      />
                      {handleTypeOfSubscription(item)}
                    </label>
                  </div>
                );
              })
            )}
          </div>

          {isTrialExpired || isSubscriptionExpired ? (
            <></>
          ) : (
            <>
              {(selected === 12 || selected === 13) && (
                <div className="columns is-mobile is-centered mt-5">
                  <div>
                    <label className="checkBlock">
                      <input
                        type="checkbox"
                        checked={trialSelected}
                        onChange={(e) =>
                          setTrialSelected(Number(!trialSelected))
                        }
                      />
                      <span>Start the 7 day free trial</span>
                    </label>
                  </div>
                </div>
              )}
            </>
          )}

          <div className="cis-centered mt-5 mb-3">
            <div className="bottom-text-div">
              <a
                className="button is-large continue-button is-rounded signin-button size_btn"
                onClick={(_) =>
                  navigate(
                    `/payment?subscription=${selected}&trial=${trialSelected}`
                  )
                }
                style={
                  selected
                    ? { background: "#B9547A", textDecoration: "none" }
                    : {
                        pointerEvents: "none",
                        cursor: "not-allowed",
                        background: "grey",
                        color: "black",
                        textDecoration: "none",
                      }
                }
              >
                Continue
              </a>
            </div>
          </div>

          {/* <div className="is-centered">
            <div className=" bottom-text-div">
              <button
                style={{ textAlign: "center" }}
                className="cancel-text SubCancelText"
                onClick={(_) => navigate("/")}
              >
                Cancel
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Subcription;
