// Reducer that changes the state based on the action

import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

export const slice = createSlice({
  name: "profile",
  initialState: {
    isLoading: false,
    data: [],
    userProfile: { },
    badges: {},
    supervisors: [],
    success: false,
    updateSuccess: false,
    error: null,
    isImageUplading: false,
  },
  reducers: {
    startFetching: (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
      state.updateSuccess = false;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
      state.isLoading = false;
    },
    setUpdateSuccess: (state, action) => {
      state.updateSuccess = action.payload;
      state.isLoading = false;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    setData: (state, action) => {
      state.data = action.payload;
      state.success = true;
      state.isLoading = false;
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
      state.error = null
      state.isLoading = false;
    },
    setSupervisorData: (state, action) => {
      state.userProfile = action.payload;
      state.error = null
      state.isLoading = false;
    },
    setUserBadges: (state, action) => {
      state.badges = action.payload;
      state.isLoading = false;
    },
    setSupervisors: (state, action) => {
      state.supervisors = action.payload;
      state.isLoading = false;
    },
    setUploadImageLink: (state,action)=>{
      state.uploadImageLink = action.payload;
      state.error=null;
      state.isImageUplading = true;
    },
    setUpdateUploadImageMsg: (state,action)=>{
      state.updateUploadImageMsg = action.payload;
      state.error=null;
      state.isImageUplading = false;
    }
  },
});

export const {
  startFetching,
  setSuccess,
  setUpdateSuccess,
  setError,
  setData,
  setUserProfile,
  setSupervisorData,
  setUserBadges,
  setSupervisors,
  setUploadImageLink,
  setUpdateUploadImageMsg
} = slice.actions;

export const useData = () => useSelector((state) => state.Profile.data);
export const useUserProfile = () => useSelector((state) => state.Profile.userProfile);
export const useBadgeValue = () => useSelector((state) => state.Profile.badges);
export const useIsLoading = () => useSelector((state) => state.Profile.isLoading);
export const useSuccess = () => useSelector((state) => state.Profile.success);
export const useUpdateSuccess = () => useSelector((state) => state.Profile.updateSuccess);
export const useRemovedUser = () => useSelector((state) => (state.Profile.userProfile.supervisor?.removed_user || []));
export const useError = () => useSelector((state) => state.Profile.error);
export const useSupervisors = () => useSelector((state) => state.Profile.supervisors);
export const useImageUploadLink = () => useSelector((state)=>state.Profile.uploadImageLink);
export const useUpdateUploadImage = ()=> useSelector((state)=>state.Profile.updateUploadImageMsg);
export const useImageUploading = ()=> useSelector((state)=>state.Profile.isImageUplading);

export default slice.reducer;
