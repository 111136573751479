import React from "react";
import "./Confirmation.css";
import badge from "../../assets/badge.svg";

const Confirmation = ({ setModalVisible }) => {
  return (
    <div className="confirmationBlock">
      <img src={badge} alt="" />
      <h2>Keep up the good work boo!</h2>
      <p>Track those hours!</p>
      <button
        className="button  is-rounded add-supervisor-btn"
        onClick={() => setModalVisible(false)}
      >
        Track Hours
      </button>
    </div>
  );
};
export default Confirmation;
